

:root {
  --blue: #0176ff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #fcf22b;
  --green: #42ba96;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #aab0b7;
  --gray-dark: #343a40;
  --basic: #fff;
  --basic-inverse: #212529;
  --primary: #0176ff;
  --secondary: #edeef6;
  --tertiary: #aab0b7;
  --success: #42ba96;
  --info: #17a2b8;
  --warning: #fcf22b;
  --danger: #dc3545;
  --light: #f5f6fa;
  --dark: #242939;
  --white: #fff;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1440px;
  --font-family-sans-serif: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block; }

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: #7f808c;
  text-align: left;
  background-color: #fff; 
overflow-x: hidden;}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible; }

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small {
  font-size: 80%; }

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0176ff;
  text-decoration: none;
  background-color: transparent; }
  a:hover {
    color: #0053b4;
    text-decoration: underline; }

a:not([href]) {
  color: inherit;
  text-decoration: none; }
  a:not([href]):hover {
    color: inherit;
    text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle;
  border-style: none; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #aab0b7;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.625rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer; }

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (max-width: 1200px) {
    legend {
      font-size: calc(1.275rem + 0.3vw) ; } }

progress {
  vertical-align: baseline; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none; }

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

[hidden] {
  display: none !important; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.6;
  color: #242939; }

h1, .h1 {
  font-size: 2.125rem; }
  @media (max-width: 1200px) {
    h1, .h1 {
      font-size: calc(1.3375rem + 1.05vw) ; } }

h2, .h2 {
  font-size: 1.625rem; }
  @media (max-width: 1200px) {
    h2, .h2 {
      font-size: calc(1.2875rem + 0.45vw) ; } }

h3, .h3 {
  font-size: 1.5rem; }
  @media (max-width: 1200px) {
    h3, .h3 {
      font-size: calc(1.275rem + 0.3vw) ; } }

h4, .h4 {
  font-size: 1.25rem; }

h5, .h5 {
  font-size: 1.03125rem; }

h6, .h6 {
  font-size: 0.9375rem; }

.lead {
  font-size: 1rem;
  font-weight: 400; }

.display-1 {
  font-size: 2.625rem;
  font-weight: 700;
  line-height: 1.6; }


  @media (max-width: 1200px) {
    .display-1 {
      font-size: calc(1.3875rem + 1.65vw) ; } }

.display-2 {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.6; }
  @media (max-width: 1200px) {
    .display-2 {
      font-size: calc(1.425rem + 2.1vw) ; } }

.display-3 {
  font-size: 2.375rem;
  font-weight: 700;
  line-height: 1.6; }
  @media (max-width: 1200px) {
    .display-3 {
      font-size: calc(1.3625rem + 1.35vw) ; } }

.display-4 {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.6; }
  @media (max-width: 1200px) {
    .display-4 {
      font-size: calc(1.275rem + 0.3vw) ; } }

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(245, 246, 250, 0.85); }

small,
.small {
  font-size: 86.667%;
  font-weight: 400; }

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 90%;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.17188rem; }

.blockquote-footer {
  display: block;
  font-size: 86.667%;
  color: #aab0b7; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #aab0b7; }

code {
  font-size: 87.5%;
  color: #e83e8c;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 87.5%;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700; }

pre {
  display: block;
  font-size: 87.5%;
  color: #212529; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll; }

.container {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1080px; } }
  @media (min-width: 1440px) {
    .container {
      max-width: 1320px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1080px; } }

@media (min-width: 1440px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1320px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-left: -20px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl,
.col-xxl-auto {
  position: relative;
  width: 100%;
  padding-right: 20px;
  padding-left: 20px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; 
  text-align: center; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  order: -1; }

.order-last {
  order: 13; }

.order-0 {
  order: 0; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.order-8 {
  order: 8; }

.order-9 {
  order: 9; }

.order-10 {
  order: 10; }

.order-11 {
  order: 11; }

.order-12 {
  order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    order: -1; }
  .order-sm-last {
    order: 13; }
  .order-sm-0 {
    order: 0; }
  .order-sm-1 {
    order: 1; }
  .order-sm-2 {
    order: 2; }
  .order-sm-3 {
    order: 3; }
  .order-sm-4 {
    order: 4; }
  .order-sm-5 {
    order: 5; }
  .order-sm-6 {
    order: 6; }
  .order-sm-7 {
    order: 7; }
  .order-sm-8 {
    order: 8; }
  .order-sm-9 {
    order: 9; }
  .order-sm-10 {
    order: 10; }
  .order-sm-11 {
    order: 11; }
  .order-sm-12 {
    order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    order: -1; }
  .order-md-last {
    order: 13; }
  .order-md-0 {
    order: 0; }
  .order-md-1 {
    order: 1; }
  .order-md-2 {
    order: 2; }
  .order-md-3 {
    order: 3; }
  .order-md-4 {
    order: 4; }
  .order-md-5 {
    order: 5; }
  .order-md-6 {
    order: 6; }
  .order-md-7 {
    order: 7; }
  .order-md-8 {
    order: 8; }
  .order-md-9 {
    order: 9; }
  .order-md-10 {
    order: 10; }
  .order-md-11 {
    order: 11; }
  .order-md-12 {
    order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    order: -1; }
  .order-lg-last {
    order: 13; }
  .order-lg-0 {
    order: 0; }
  .order-lg-1 {
    order: 1; }
  .order-lg-2 {
    order: 2; }
  .order-lg-3 {
    order: 3; }
  .order-lg-4 {
    order: 4; }
  .order-lg-5 {
    order: 5; }
  .order-lg-6 {
    order: 6; }
  .order-lg-7 {
    order: 7; }
  .order-lg-8 {
    order: 8; }
  .order-lg-9 {
    order: 9; }
  .order-lg-10 {
    order: 10; }
  .order-lg-11 {
    order: 11; }
  .order-lg-12 {
    order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    order: -1; }
  .order-xl-last {
    order: 13; }
  .order-xl-0 {
    order: 0; }
  .order-xl-1 {
    order: 1; }
  .order-xl-2 {
    order: 2; }
  .order-xl-3 {
    order: 3; }
  .order-xl-4 {
    order: 4; }
  .order-xl-5 {
    order: 5; }
  .order-xl-6 {
    order: 6; }
  .order-xl-7 {
    order: 7; }
  .order-xl-8 {
    order: 8; }
  .order-xl-9 {
    order: 9; }
  .order-xl-10 {
    order: 10; }
  .order-xl-11 {
    order: 11; }
  .order-xl-12 {
    order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1440px) {
  .col-xxl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xxl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xxl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xxl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xxl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xxl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xxl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xxl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xxl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xxl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xxl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xxl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xxl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xxl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xxl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xxl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xxl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xxl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xxl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .order-xxl-first {
    order: -1; }
  .order-xxl-last {
    order: 13; }
  .order-xxl-0 {
    order: 0; }
  .order-xxl-1 {
    order: 1; }
  .order-xxl-2 {
    order: 2; }
  .order-xxl-3 {
    order: 3; }
  .order-xxl-4 {
    order: 4; }
  .order-xxl-5 {
    order: 5; }
  .order-xxl-6 {
    order: 6; }
  .order-xxl-7 {
    order: 7; }
  .order-xxl-8 {
    order: 8; }
  .order-xxl-9 {
    order: 9; }
  .order-xxl-10 {
    order: 10; }
  .order-xxl-11 {
    order: 11; }
  .order-xxl-12 {
    order: 12; }
  .offset-xxl-0 {
    margin-left: 0; }
  .offset-xxl-1 {
    margin-left: 8.33333%; }
  .offset-xxl-2 {
    margin-left: 16.66667%; }
  .offset-xxl-3 {
    margin-left: 25%; }
  .offset-xxl-4 {
    margin-left: 33.33333%; }
  .offset-xxl-5 {
    margin-left: 41.66667%; }
  .offset-xxl-6 {
    margin-left: 50%; }
  .offset-xxl-7 {
    margin-left: 58.33333%; }
  .offset-xxl-8 {
    margin-left: 66.66667%; }
  .offset-xxl-9 {
    margin-left: 75%; }
  .offset-xxl-10 {
    margin-left: 83.33333%; }
  .offset-xxl-11 {
    margin-left: 91.66667%; } }

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #7f808c; }
  .table th,
  .table td {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid rgba(245, 246, 250, 0.85); }
  .table thead th {
    vertical-align: bottom;
    font-size: 13px;
    border-bottom: 2px solid rgba(245, 246, 250, 0.85); }
  .table tbody + tbody {
    border-top: 2px solid rgba(245, 246, 250, 0.85); }

.table-sm th,
.table-sm td {
  padding: 0.3rem; }

.table-bordered {
  border: 1px solid rgba(245, 246, 250, 0.85); }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid rgba(245, 246, 250, 0.85); }
  .table-bordered thead th,
  .table-bordered thead td {
    border-bottom-width: 2px; }

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0; }

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.05); }

.table-hover tbody tr:hover {
  color: #7f808c;
  background-color: rgba(0, 0, 0, 0.075); }

.table-basic,
.table-basic > th,
.table-basic > td {
  background-color: white; }

.table-basic th,
.table-basic td,
.table-basic thead th,
.table-basic tbody + tbody {
  border-color: white; }

.table-hover .table-basic:hover {
  background-color: #f2f2f2; }
  .table-hover .table-basic:hover > td,
  .table-hover .table-basic:hover > th {
    background-color: #f2f2f2; }

.table-basic-inverse,
.table-basic-inverse > th,
.table-basic-inverse > td {
  background-color: #c1c2c3; }

.table-basic-inverse th,
.table-basic-inverse td,
.table-basic-inverse thead th,
.table-basic-inverse tbody + tbody {
  border-color: #8c8e90; }

.table-hover .table-basic-inverse:hover {
  background-color: #b4b5b6; }
  .table-hover .table-basic-inverse:hover > td,
  .table-hover .table-basic-inverse:hover > th {
    background-color: #b4b5b6; }

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #b8d9ff; }

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #7bb8ff; }

.table-hover .table-primary:hover {
  background-color: #9fcbff; }
  .table-hover .table-primary:hover > td,
  .table-hover .table-primary:hover > th {
    background-color: #9fcbff; }

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #fafafc; }

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #f5f6fa; }

.table-hover .table-secondary:hover {
  background-color: #eaeaf2; }
  .table-hover .table-secondary:hover > td,
  .table-hover .table-secondary:hover > th {
    background-color: #eaeaf2; }

.table-tertiary,
.table-tertiary > th,
.table-tertiary > td {
  background-color: #e7e9eb; }

.table-tertiary th,
.table-tertiary td,
.table-tertiary thead th,
.table-tertiary tbody + tbody {
  border-color: #d3d6da; }

.table-hover .table-tertiary:hover {
  background-color: #d9dcdf; }
  .table-hover .table-tertiary:hover > td,
  .table-hover .table-tertiary:hover > th {
    background-color: #d9dcdf; }

.table-success,
.table-success > th,
.table-success > td {
  background-color: #caece2; }

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #9ddbc8; }

.table-hover .table-success:hover {
  background-color: #b7e5d8; }
  .table-hover .table-success:hover > td,
  .table-hover .table-success:hover > th {
    background-color: #b7e5d8; }

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb; }

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda; }

.table-hover .table-info:hover {
  background-color: #abdde5; }
  .table-hover .table-info:hover > td,
  .table-hover .table-info:hover > th {
    background-color: #abdde5; }

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #fefbc4; }

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #fdf891; }

.table-hover .table-warning:hover {
  background-color: #fef9ab; }
  .table-hover .table-warning:hover > td,
  .table-hover .table-warning:hover > th {
    background-color: #fef9ab; }

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb; }

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e; }

.table-hover .table-danger:hover {
  background-color: #f1b0b7; }
  .table-hover .table-danger:hover > td,
  .table-hover .table-danger:hover > th {
    background-color: #f1b0b7; }

.table-light,
.table-light > th,
.table-light > td {
  background-color: #fcfcfe; }

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #fafafc; }

.table-hover .table-light:hover {
  background-color: #e9e9f8; }
  .table-hover .table-light:hover > td,
  .table-hover .table-light:hover > th {
    background-color: #e9e9f8; }

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #c2c3c8; }

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #8d9098; }

.table-hover .table-dark:hover {
  background-color: #b5b6bc; }
  .table-hover .table-dark:hover > td,
  .table-hover .table-dark:hover > th {
    background-color: #b5b6bc; }

.table-white,
.table-white > th,
.table-white > td {
  background-color: white; }

.table-white th,
.table-white td,
.table-white thead th,
.table-white tbody + tbody {
  border-color: white; }

.table-hover .table-white:hover {
  background-color: #f2f2f2; }
  .table-hover .table-white:hover > td,
  .table-hover .table-white:hover > th {
    background-color: #f2f2f2; }

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(0, 0, 0, 0.075); }

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075); }
  .table-hover .table-active:hover > td,
  .table-hover .table-active:hover > th {
    background-color: rgba(0, 0, 0, 0.075); }

.table .thead-dark th {
  color: #fff;
  background-color: #343a40;
  border-color: #454d55; }

.table .thead-light th {
  color: #495057;
  background-color: #edeef6;
  border-color: rgba(245, 246, 250, 0.85); }

.table-dark {
  color: #fff;
  background-color: #343a40; }
  .table-dark th,
  .table-dark td,
  .table-dark thead th {
    border-color: #454d55; }
  .table-dark.table-bordered {
    border: 0; }
  .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(255, 255, 255, 0.05); }
  .table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.075); }

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-sm > .table-bordered {
      border: 0; } }



@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-md > .table-bordered {
      border: 0; } }

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-lg > .table-bordered {
      border: 0; } }

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xl > .table-bordered {
      border: 0; } }

@media (max-width: 1439.98px) {
  .table-responsive-xxl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; }
    .table-responsive-xxl > .table-bordered {
      border: 0; } }

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; }
  .table-responsive > .table-bordered {
    border: 0; }

.form-control {
  display: block;
  width: 100%;
  height: calc(1.6em + 1.375rem + 2px);
  padding: 0.6875rem 1.0625rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: #7f808c;
  background-color: #edeef6;
  background-clip: padding-box;
  border: 1px solid #edeef6;
  border-radius: 0.375rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #7f808c; }
  .form-control:focus {
    color: #7f808c;
    background-color: #edeef6;
    border-color: #edeef6;
    outline: 0;
    box-shadow: 0 0 0 rgba(1, 118, 255, 0.15); }
  .form-control::placeholder {
    color: #aab0b7;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #edeef6;
    opacity: 1; }

select.form-control:focus::-ms-value {
  color: #7f808c;
  background-color: #edeef6; }

.form-control-file,
.form-control-range {
  display: block;
  width: 100%; }

.col-form-label {
  padding-top: calc(0.6875rem + 1px);
  padding-bottom: calc(0.6875rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.6; }

.col-form-label-lg {
  padding-top: calc(0.9375rem + 1px);
  padding-bottom: calc(0.9375rem + 1px);
  font-size: 0.9375rem;
  line-height: 1.6; }

.col-form-label-sm {
  padding-top: calc(0.4375rem + 1px);
  padding-bottom: calc(0.4375rem + 1px);
  font-size: 0.82031rem;
  line-height: 1.5; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.6875rem 0;
  margin-bottom: 0;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #7f808c;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  height: calc(1.5em + 0.875rem + 2px);
  padding: 0.4375rem 0.625rem;
  font-size: 0.82031rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.form-control-lg {
  height: calc(1.6em + 1.875rem + 2px);
  padding: 0.9375rem 1.25rem;
  font-size: 14px;
  line-height: 1.6;
  border-radius: 0.375rem;font-family: 'HelveticaNeueLight';}

  .form-control-lg:focus{
    font-family: 'HelveticaNeueLight';font-size: 14px;
  }

select.form-control[size], select.form-control[multiple] {
  height: auto; }

textarea.form-control {
  height: auto; }

.form-group {
  margin-bottom: 1.5rem; }

.form-text {
  display: block;
  margin-top: 0.25rem; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -8px;
  margin-left: -8px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 8px;
    padding-left: 8px; }

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem; }

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem; }
  .form-check-input[disabled] ~ .form-check-label,
  .form-check-input:disabled ~ .form-check-label {
    color: #aab0b7; }

.form-check-label {
  margin-bottom: 0; }

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem; }
  .form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: 0.3125rem;
    margin-left: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 86.667%;
  color: #42ba96; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.82031rem;
  line-height: 1.6;
  color: #fff;
  background-color: rgba(66, 186, 150, 0.9);
  border-radius: 0.375rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #42ba96;
  padding-right: calc(1.6em + 1.375rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2342ba96' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.34375rem) center;
  background-size: calc(0.8em + 0.6875rem) calc(0.8em + 0.6875rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #42ba96;
    box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.6em + 1.375rem);
  background-position: top calc(0.4em + 0.34375rem) right calc(0.4em + 0.34375rem); }

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #42ba96;
  padding-right: calc((1em + 1.375rem) * 3 / 4 + 2.0625rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.0625rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2342ba96' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #edeef6 no-repeat center right 2.0625rem/calc(0.8em + 0.6875rem) calc(0.8em + 0.6875rem); }
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
    border-color: #42ba96;
    box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.25); }

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #42ba96; }

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #42ba96; }
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
    border-color: #42ba96; }

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #66c9ab;
  background-color: #66c9ab; }

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.25); }

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #42ba96; }

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #42ba96; }

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #42ba96;
  box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 86.667%;
  color: #dc3545; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.82031rem;
  line-height: 1.6;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.375rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.6em + 1.375rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: right calc(0.4em + 0.34375rem) center;
  background-size: calc(0.8em + 0.6875rem) calc(0.8em + 0.6875rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.6em + 1.375rem);
  background-position: top calc(0.4em + 0.34375rem) right calc(0.4em + 0.34375rem); }

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 1.375rem) * 3 / 4 + 2.0625rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.0625rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #edeef6 no-repeat center right 2.0625rem/calc(0.8em + 0.6875rem) calc(0.8em + 0.6875rem); }
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25); }

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545; }

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545; }
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
    border-color: #dc3545; }

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d; }

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25); }

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545; }

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.25); }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-group {
      display: flex;
      flex: 0 0 auto;
      flex-flow: row wrap;
      align-items: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .custom-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; }
    .form-inline .custom-control {
      align-items: center;
      justify-content: center; }
    .form-inline .custom-control-label {
      margin-bottom: 0; } }

.btn {
  display: inline-block;
  font-weight: 400;
  color: #7f808c;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.6875rem 1.0625rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }





  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn:hover {
    color: #7f808c;
    text-decoration: none; }
  .btn:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 rgba(1, 118, 255, 0.15); }
  .btn.disabled, .btn:disabled {
    opacity: 0.65; }

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none; }

.btn-basic {
  color: #212529;
  background-color: #fff;
  border-color: #fff; }
  .btn-basic:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #ececec; }
  .btn-basic:focus, .btn-basic.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #ececec;
    box-shadow: 0 0 0 0 rgba(222, 222, 223, 0.5); }
  .btn-basic.disabled, .btn-basic:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-basic:not(:disabled):not(.disabled):active, .btn-basic:not(:disabled):not(.disabled).active,
  .show > .btn-basic.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #e6e6e6; }
    .btn-basic:not(:disabled):not(.disabled):active:focus, .btn-basic:not(:disabled):not(.disabled).active:focus,
    .show > .btn-basic.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(222, 222, 223, 0.5); }

.btn-basic-inverse {
  color: #fff;
  background-color: #212529;
  border-color: #212529; }
  .btn-basic-inverse:hover {
    color: #fff;
    background-color: #101214;
    border-color: #101214; }
  .btn-basic-inverse:focus, .btn-basic-inverse.focus {
    color: #fff;
    background-color: #101214;
    border-color: #101214;
    box-shadow: 0 0 0 0 rgba(66, 70, 73, 0.5); }
  .btn-basic-inverse.disabled, .btn-basic-inverse:disabled {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .btn-basic-inverse:not(:disabled):not(.disabled):active, .btn-basic-inverse:not(:disabled):not(.disabled).active,
  .show > .btn-basic-inverse.dropdown-toggle {
    color: #fff;
    background-color: #0a0c0d;
    border-color: #0a0c0d; }
    .btn-basic-inverse:not(:disabled):not(.disabled):active:focus, .btn-basic-inverse:not(:disabled):not(.disabled).active:focus,
    .show > .btn-basic-inverse.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(66, 70, 73, 0.5); }

.btn-primary {
  color: #fff;
  background-color: #0176ff;
  border-color: #0176ff; font-size: 14px; }
  .btn-primary:hover {
    color: #fff;
    background-color: #0064da;
    border-color: #0064da; }
  .btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: #0064da;
    border-color: #0064da;
    box-shadow: 0 0 0 0 rgba(39, 139, 255, 0.5);font-size: 14px; }
  .btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #0176ff;
    border-color: #0176ff; }
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #005ecd;
    border-color: #005ecd; }
    .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(39, 139, 255, 0.5); }

.btn-secondary {
  color: #212529;
  background-color: #edeef6;
  border-color: #edeef6; }
  .btn-secondary:hover {
    color: #212529;
    background-color: #d3d7e9;
    border-color: #d3d7e9; }
  .btn-secondary:focus, .btn-secondary.focus {
    color: #212529;
    background-color: #d3d7e9;
    border-color: #d3d7e9;
    box-shadow: 0 0 0 0 rgba(206, 208, 215, 0.5); }
  .btn-secondary.disabled, .btn-secondary:disabled {
    color: #212529;
    background-color: #edeef6;
    border-color: #edeef6; }
  .btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    color: #212529;
    background-color: #cbd0e5;
    border-color: #cbd0e5; }
    .btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(206, 208, 215, 0.5); }

.btn-tertiary {
  color: #fff;
  background-color: #aab0b7;
  border-color: #aab0b7; }
  .btn-tertiary:hover {
    color: #fff;
    background-color: #959da5;
    border-color: #959da5; }
  .btn-tertiary:focus, .btn-tertiary.focus {
    color: #fff;
    background-color: #959da5;
    border-color: #959da5;
    box-shadow: 0 0 0 0 rgba(183, 188, 194, 0.5); }
  .btn-tertiary.disabled, .btn-tertiary:disabled {
    color: #fff;
    background-color: #aab0b7;
    border-color: #aab0b7; }
  .btn-tertiary:not(:disabled):not(.disabled):active, .btn-tertiary:not(:disabled):not(.disabled).active,
  .show > .btn-tertiary.dropdown-toggle {
    color: #fff;
    background-color: #8e96a0;
    border-color: #8e96a0; }
    .btn-tertiary:not(:disabled):not(.disabled):active:focus, .btn-tertiary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-tertiary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(183, 188, 194, 0.5); }

.btn-success {
  color: #fff;
  background-color: #42ba96;
  border-color: #42ba96; }
  .btn-success:hover {
    color: #fff;
    background-color: #389e7f;
    border-color: #389e7f; }
  .btn-success:focus, .btn-success.focus {
    color: #fff;
    background-color: #389e7f;
    border-color: #389e7f;
    box-shadow: 0 0 0 0 rgba(94, 196, 166, 0.5); }
  .btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #42ba96;
    border-color: #42ba96; }
  .btn-success:not(:disabled):not(.disabled):active, .btn-success:not(:disabled):not(.disabled).active,
  .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #359478;
    border-color: #359478; }
    .btn-success:not(:disabled):not(.disabled):active:focus, .btn-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(94, 196, 166, 0.5); }

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8; }
  .btn-info:hover {
    color: #fff;
    background-color: #138496;
    border-color: #138496; }
  .btn-info:focus, .btn-info.focus {
    color: #fff;
    background-color: #138496;
    border-color: #138496;
    box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5); }
  .btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-info:not(:disabled):not(.disabled):active, .btn-info:not(:disabled):not(.disabled).active,
  .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #117a8b;
    border-color: #117a8b; }
    .btn-info:not(:disabled):not(.disabled):active:focus, .btn-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(58, 176, 195, 0.5); }

.btn-warning {
  color: #212529;
  background-color: #fcf22b;
  border-color: #fcf22b; }
  .btn-warning:hover {
    color: #212529;
    background-color: #fbf005;
    border-color: #fbf005; }
  .btn-warning:focus, .btn-warning.focus {
    color: #212529;
    background-color: #fbf005;
    border-color: #fbf005;
    box-shadow: 0 0 0 0 rgba(219, 211, 43, 0.5); }
  .btn-warning.disabled, .btn-warning:disabled {
    color: #212529;
    background-color: #fcf22b;
    border-color: #fcf22b; }
  .btn-warning:not(:disabled):not(.disabled):active, .btn-warning:not(:disabled):not(.disabled).active,
  .show > .btn-warning.dropdown-toggle {
    color: #212529;
    background-color: #f1e503;
    border-color: #f1e503; }
    .btn-warning:not(:disabled):not(.disabled):active:focus, .btn-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(219, 211, 43, 0.5); }

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545; }
  .btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #c82333; }
  .btn-danger:focus, .btn-danger.focus {
    color: #fff;
    background-color: #c82333;
    border-color: #c82333;
    box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5); }
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #bd2130; }
    .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(225, 83, 97, 0.5); }

.btn-light {
  color: #212529;
  background-color: #f5f6fa;
  border-color: #f5f6fa; }
  .btn-light:hover {
    color: #212529;
    background-color: #dcdfed;
    border-color: #dcdfed; }
  .btn-light:focus, .btn-light.focus {
    color: #212529;
    background-color: #dcdfed;
    border-color: #dcdfed;
    box-shadow: 0 0 0 0 rgba(213, 215, 219, 0.5); }
  .btn-light.disabled, .btn-light:disabled {
    color: #212529;
    background-color: #f5f6fa;
    border-color: #f5f6fa; }
  .btn-light:not(:disabled):not(.disabled):active, .btn-light:not(:disabled):not(.disabled).active,
  .show > .btn-light.dropdown-toggle {
    color: #212529;
    background-color: #d3d7e9;
    border-color: #d3d7e9; }
    .btn-light:not(:disabled):not(.disabled):active:focus, .btn-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(213, 215, 219, 0.5); }

.btn-dark {
  color: #fff;
  background-color: #242939;
  border-color: #242939; }
  .btn-dark:hover {
    color: #fff;
    background-color: #151822;
    border-color: #151822; }
  .btn-dark:focus, .btn-dark.focus {
    color: #fff;
    background-color: #151822;
    border-color: #151822;
    box-shadow: 0 0 0 0 rgba(69, 73, 87, 0.5); }
  .btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #242939;
    border-color: #242939; }
  .btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active,
  .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #10131a;
    border-color: #10131a; }
    .btn-dark:not(:disabled):not(.disabled):active:focus, .btn-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(69, 73, 87, 0.5); }

.btn-white {
  color: #212529;
  background-color: #fff;
  border-color: #fff; }
  .btn-white:hover {
    color: #212529;
    background-color: #ececec;
    border-color: #ececec; }
  .btn-white:focus, .btn-white.focus {
    color: #212529;
    background-color: #ececec;
    border-color: #ececec;
    box-shadow: 0 0 0 0 rgba(222, 222, 223, 0.5); }
  .btn-white.disabled, .btn-white:disabled {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
  .show > .btn-white.dropdown-toggle {
    color: #212529;
    background-color: #e6e6e6;
    border-color: #e6e6e6; }
    .btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(222, 222, 223, 0.5); }

.btn-outline-basic {
  color: #fff;
  border-color: #fff; }
  .btn-outline-basic:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-basic:focus, .btn-outline-basic.focus {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
  .btn-outline-basic.disabled, .btn-outline-basic:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-basic:not(:disabled):not(.disabled):active, .btn-outline-basic:not(:disabled):not(.disabled).active,
  .show > .btn-outline-basic.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-basic:not(:disabled):not(.disabled):active:focus, .btn-outline-basic:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-basic.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }

.btn-outline-basic-inverse {
  color: #212529;
  border-color: #212529; }
  .btn-outline-basic-inverse:hover {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
  .btn-outline-basic-inverse:focus, .btn-outline-basic-inverse.focus {
    box-shadow: 0 0 0 0 rgba(33, 37, 41, 0.5); }
  .btn-outline-basic-inverse.disabled, .btn-outline-basic-inverse:disabled {
    color: #212529;
    background-color: transparent; }
  .btn-outline-basic-inverse:not(:disabled):not(.disabled):active, .btn-outline-basic-inverse:not(:disabled):not(.disabled).active,
  .show > .btn-outline-basic-inverse.dropdown-toggle {
    color: #fff;
    background-color: #212529;
    border-color: #212529; }
    .btn-outline-basic-inverse:not(:disabled):not(.disabled):active:focus, .btn-outline-basic-inverse:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-basic-inverse.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(33, 37, 41, 0.5); }

.btn-outline-primary {
  color: #0176ff;
  border-color: #0176ff; }
  .btn-outline-primary:hover {
    color: #fff;
    background-color: #0176ff;
    border-color: #0176ff; }
  .btn-outline-primary:focus, .btn-outline-primary.focus {
    box-shadow: 0 0 0 0 rgba(1, 118, 255, 0.5); }
  .btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #0176ff;
    background-color: transparent; }
  .btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #0176ff;
    border-color: #0176ff; }
    .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-primary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(1, 118, 255, 0.5); }

.btn-outline-secondary {
  color: #edeef6;
  border-color: #edeef6; }
  .btn-outline-secondary:hover {
    color: #212529;
    background-color: #edeef6;
    border-color: #edeef6; }
  .btn-outline-secondary:focus, .btn-outline-secondary.focus {
    box-shadow: 0 0 0 0 rgba(237, 238, 246, 0.5); }
  .btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #edeef6;
    background-color: transparent; }
  .btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-secondary.dropdown-toggle {
    color: #212529;
    background-color: #edeef6;
    border-color: #edeef6; }
    .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-secondary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(237, 238, 246, 0.5); }

.btn-outline-tertiary {
  color: #aab0b7;
  border-color: #aab0b7; }
  .btn-outline-tertiary:hover {
    color: #fff;
    background-color: #aab0b7;
    border-color: #aab0b7; }
  .btn-outline-tertiary:focus, .btn-outline-tertiary.focus {
    box-shadow: 0 0 0 0 rgba(170, 176, 183, 0.5); }
  .btn-outline-tertiary.disabled, .btn-outline-tertiary:disabled {
    color: #aab0b7;
    background-color: transparent; }
  .btn-outline-tertiary:not(:disabled):not(.disabled):active, .btn-outline-tertiary:not(:disabled):not(.disabled).active,
  .show > .btn-outline-tertiary.dropdown-toggle {
    color: #fff;
    background-color: #aab0b7;
    border-color: #aab0b7; }
    .btn-outline-tertiary:not(:disabled):not(.disabled):active:focus, .btn-outline-tertiary:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-tertiary.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(170, 176, 183, 0.5); }

.btn-outline-success {
  color: #42ba96;
  border-color: #42ba96; }
  .btn-outline-success:hover {
    color: #fff;
    background-color: #42ba96;
    border-color: #42ba96; }
  .btn-outline-success:focus, .btn-outline-success.focus {
    box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.5); }
  .btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #42ba96;
    background-color: transparent; }
  .btn-outline-success:not(:disabled):not(.disabled):active, .btn-outline-success:not(:disabled):not(.disabled).active,
  .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #42ba96;
    border-color: #42ba96; }
    .btn-outline-success:not(:disabled):not(.disabled):active:focus, .btn-outline-success:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-success.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.5); }

.btn-outline-info {
  color: #17a2b8;
  border-color: #17a2b8; }
  .btn-outline-info:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
  .btn-outline-info:focus, .btn-outline-info.focus {
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }
  .btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #17a2b8;
    background-color: transparent; }
  .btn-outline-info:not(:disabled):not(.disabled):active, .btn-outline-info:not(:disabled):not(.disabled).active,
  .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8; }
    .btn-outline-info:not(:disabled):not(.disabled):active:focus, .btn-outline-info:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-info.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }

.btn-outline-warning {
  color: #fcf22b;
  border-color: #fcf22b; }
  .btn-outline-warning:hover {
    color: #212529;
    background-color: #fcf22b;
    border-color: #fcf22b; }
  .btn-outline-warning:focus, .btn-outline-warning.focus {
    box-shadow: 0 0 0 0 rgba(252, 242, 43, 0.5); }
  .btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #fcf22b;
    background-color: transparent; }
  .btn-outline-warning:not(:disabled):not(.disabled):active, .btn-outline-warning:not(:disabled):not(.disabled).active,
  .show > .btn-outline-warning.dropdown-toggle {
    color: #212529;
    background-color: #fcf22b;
    border-color: #fcf22b; }
    .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .btn-outline-warning:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-warning.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(252, 242, 43, 0.5); }

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545; }
  .btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
  .btn-outline-danger:focus, .btn-outline-danger.focus {
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5); }
  .btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent; }
  .btn-outline-danger:not(:disabled):not(.disabled):active, .btn-outline-danger:not(:disabled):not(.disabled).active,
  .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545; }
    .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .btn-outline-danger:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-danger.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5); }

.btn-outline-light {
  color: #f5f6fa;
  border-color: #f5f6fa; }
  .btn-outline-light:hover {
    color: #212529;
    background-color: #f5f6fa;
    border-color: #f5f6fa; }
  .btn-outline-light:focus, .btn-outline-light.focus {
    box-shadow: 0 0 0 0 rgba(245, 246, 250, 0.5); }
  .btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f5f6fa;
    background-color: transparent; }
  .btn-outline-light:not(:disabled):not(.disabled):active, .btn-outline-light:not(:disabled):not(.disabled).active,
  .show > .btn-outline-light.dropdown-toggle {
    color: #212529;
    background-color: #f5f6fa;
    border-color: #f5f6fa; }
    .btn-outline-light:not(:disabled):not(.disabled):active:focus, .btn-outline-light:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-light.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(245, 246, 250, 0.5); }

.btn-outline-dark {
  color: #242939;
  border-color: #242939; }
  .btn-outline-dark:hover {
    color: #fff;
    background-color: #242939;
    border-color: #242939; }
  .btn-outline-dark:focus, .btn-outline-dark.focus {
    box-shadow: 0 0 0 0 rgba(36, 41, 57, 0.5); }
  .btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #242939;
    background-color: transparent; }
  .btn-outline-dark:not(:disabled):not(.disabled):active, .btn-outline-dark:not(:disabled):not(.disabled).active,
  .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #242939;
    border-color: #242939; }
    .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .btn-outline-dark:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-dark.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(36, 41, 57, 0.5); }

.btn-outline-white {
  color: #fff;
  border-color: #fff; }
  .btn-outline-white:hover {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
  .btn-outline-white:focus, .btn-outline-white.focus {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }
  .btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #fff;
    background-color: transparent; }
  .btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
  .show > .btn-outline-white.dropdown-toggle {
    color: #212529;
    background-color: #fff;
    border-color: #fff; }
    .btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-white.dropdown-toggle:focus {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }

.btn-link {
  font-weight: 400;
  color: #0176ff;
  text-decoration: none; }
  .btn-link:hover {
    color: #0053b4;
    text-decoration: underline; }
  .btn-link:focus, .btn-link.focus {
    text-decoration: underline;
    box-shadow: none; }
  .btn-link:disabled, .btn-link.disabled {
    color: #aab0b7;
    pointer-events: none; }

.btn-lg, .btn-group-lg > .btn {
  padding: 0.9375rem 1.25rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  border-radius: 0.375rem; }

.btn-sm, .btn-group-sm > .btn {
  padding: 0.4375rem 0.625rem;
  font-size: 0.82031rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.btn-block {
  display: block;
  width: 100%; }
  .btn-block + .btn-block {
    margin-top: 0.5rem; }

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; 
    position: absolute;
    top: 15px;
    right: 5px;
  }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.625rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.9375rem;
  color: #7f808c;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 0px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.375rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

@media (min-width: 1440px) {
  .dropdown-menu-xxl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xxl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(245, 246, 250, 0.85); }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  clear: both;
  font-weight: 400;
  color: #7f808c;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; cursor: pointer; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #7f808c;
    text-decoration: none;
    background-color: #f5f6fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0176ff; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #aab0b7;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.625rem 1rem;
  margin-bottom: 0;
  font-size: 0.82031rem;
  color: #aab0b7;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.5rem 1rem;
  color: #7f808c; }

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle; }
  .btn-group > .btn,
  .btn-group-vertical > .btn {
    position: relative;
    flex: 1 1 auto; }
    .btn-group > .btn:hover,
    .btn-group-vertical > .btn:hover {
      z-index: 1; }
    .btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
    .btn-group-vertical > .btn:focus,
    .btn-group-vertical > .btn:active,
    .btn-group-vertical > .btn.active {
      z-index: 1; font-family: 'Roboto', sans-serif; }

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; }
  .btn-toolbar .input-group {
    width: auto; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px; }

.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.dropdown-toggle-split {
  padding-right: 0.79688rem;
  padding-left: 0.79688rem; }
  .dropdown-toggle-split::after,
  .dropup .dropdown-toggle-split::after,
  .dropright .dropdown-toggle-split::after {
    margin-left: 0; }
  .dropleft .dropdown-toggle-split::before {
    margin-right: 0; }

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.46875rem;
  padding-left: 0.46875rem; }

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem; }

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center; }
  .btn-group-vertical > .btn,
  .btn-group-vertical > .btn-group {
    width: 100%; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) {
    margin-top: -1px; }
  .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
  .btn-group-vertical > .btn-group:not(:last-child) > .btn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .btn-group-vertical > .btn:not(:first-child),
  .btn-group-vertical > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.btn-group-toggle > .btn,
.btn-group-toggle > .btn-group > .btn {
  margin-bottom: 0; }
  .btn-group-toggle > .btn input[type="radio"],
  .btn-group-toggle > .btn input[type="checkbox"],
  .btn-group-toggle > .btn-group > .btn input[type="radio"],
  .btn-group-toggle > .btn-group > .btn input[type="checkbox"] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none; }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-control-plaintext,
  .input-group > .custom-select,
  .input-group > .custom-file {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .custom-select,
    .input-group > .form-control + .custom-file,
    .input-group > .form-control-plaintext + .form-control,
    .input-group > .form-control-plaintext + .custom-select,
    .input-group > .form-control-plaintext + .custom-file,
    .input-group > .custom-select + .form-control,
    .input-group > .custom-select + .custom-select,
    .input-group > .custom-select + .custom-file,
    .input-group > .custom-file + .form-control,
    .input-group > .custom-file + .custom-select,
    .input-group > .custom-file + .custom-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .custom-select:focus,
  .input-group > .custom-file .custom-file-input:focus ~ .custom-file-label {
    z-index: 3; }
  .input-group > .custom-file .custom-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .custom-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .custom-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .custom-file {
    display: flex;
    align-items: center; }
    .input-group > .custom-file:not(:last-child) .custom-file-label,
    .input-group > .custom-file:not(:last-child) .custom-file-label::after {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .custom-file:not(:first-child) .custom-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: 10px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.6875rem 1.0625rem;
  margin-bottom: 0;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: #7f808c;
  text-align: center;
  white-space: nowrap;
  background-color: #edeef6;
  border: 1px solid #edeef6;
  border-radius: 0.375rem; }
  .input-group-text input[type="radio"],
  .input-group-text input[type="checkbox"] {
    margin-top: 0; }

.input-group-lg > .form-control:not(textarea),
.input-group-lg > .custom-select {
  height: calc(1.6em + 1.875rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .custom-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.9375rem 1.25rem;
  font-size: 0.9375rem;
  line-height: 1.6;
  border-radius: 0.375rem; }

.input-group-sm > .form-control:not(textarea),
.input-group-sm > .custom-select {
  height: calc(1.5em + 0.875rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .custom-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.4375rem 0.625rem;
  font-size: 0.82031rem;
  line-height: 1.5;
  border-radius: 0.2rem; }

.input-group-lg > .custom-select,
.input-group-sm > .custom-select {
  padding-right: 2.0625rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.6rem; }

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem; }

.custom-control-input {
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1.1rem;
  height: 1.3rem;
  opacity: 0; }
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #0176ff;
    background-color: #0176ff; }
  .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 rgba(1, 118, 255, 0.15); }
  .custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #edeef6; }
  .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
    color: #fff;
    background-color: #b4d6ff;
    border-color: #b4d6ff; }
  .custom-control-input[disabled] ~ .custom-control-label, .custom-control-input:disabled ~ .custom-control-label {
    color: #aab0b7; }
    .custom-control-input[disabled] ~ .custom-control-label::before, .custom-control-input:disabled ~ .custom-control-label::before {
      background-color: #edeef6; }

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top; }
  .custom-control-label::before {
    position: absolute;
    top: 0.2rem;
    left: -1.6rem;
    display: block;
    width: 1.1rem;
    height: 1.1rem;
    pointer-events: none;
    content: "";
    background-color: #edeef6;
    border: #edeef6 solid 1px; }
  .custom-control-label::after {
    position: absolute;
    top: 0.2rem;
    left: -1.6rem;
    display: block;
    width: 1.1rem;
    height: 1.1rem;
    content: "";
    background: no-repeat 50% / 50% 50%; }

.custom-checkbox .custom-control-label::before {
  border-radius: 0.375rem; }

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #0176ff;
  background-color: #0176ff; }

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e"); }

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(1, 118, 255, 0.5); }

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(1, 118, 255, 0.5); }

.custom-radio .custom-control-label::before {
  border-radius: 50%; }

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(1, 118, 255, 0.5); }

.custom-switch {
  padding-left: 2.425rem; }
  .custom-switch .custom-control-label::before {
    left: -2.425rem;
    width: 1.925rem;
    pointer-events: all;
    border-radius: 0.55rem; }
  .custom-switch .custom-control-label::after {
    top: calc(0.2rem + 2px);
    left: calc(-2.425rem + 2px);
    width: calc(1.1rem - 4px);
    height: calc(1.1rem - 4px);
    background-color: #edeef6;
    border-radius: 0.55rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
    @media (prefers-reduced-motion: reduce) {
      .custom-switch .custom-control-label::after {
        transition: none; } }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #edeef6;
    transform: translateX(0.825rem); }
  .custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
    background-color: rgba(1, 118, 255, 0.5); }

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 1.375rem + 2px);
  padding: 0.6875rem 2.0625rem 0.6875rem 1.0625rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: #7f808c;
  vertical-align: middle;
  background: #edeef6 url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1.0625rem center/8px 10px;
  border: 1px solid #edeef6;
  border-radius: 0.375rem;
  appearance: none; }
  .custom-select:focus {
    border-color: #edeef6;
    outline: 0;
    box-shadow: 0 0 0 0 rgba(1, 118, 255, 0.15); }
    .custom-select:focus::-ms-value {
      color: #7f808c;
      background-color: #edeef6; }
  .custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1.0625rem;
    background-image: none; }
  .custom-select:disabled {
    color: #aab0b7;
    background-color: #edeef6; }
  .custom-select::-ms-expand {
    display: none; }
  .custom-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #7f808c; }

.custom-select-sm {
  height: calc(1.5em + 0.875rem + 2px);
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  padding-left: 0.625rem;
  font-size: 0.82031rem; }

.custom-select-lg {
  height: calc(1.6em + 1.875rem + 2px);
  padding-top: 0.9375rem;
  padding-bottom: 0.9375rem;
  padding-left: 1.25rem;
  font-size: 0.9375rem; }

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.6em + 1.375rem + 2px);
  margin-bottom: 0; }

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.6em + 1.375rem + 2px);
  margin: 0;
  opacity: 0; }
  .custom-file-input:focus ~ .custom-file-label {
    border-color: #edeef6;
    box-shadow: 0 0 0 rgba(1, 118, 255, 0.15); }
  .custom-file-input[disabled] ~ .custom-file-label,
  .custom-file-input:disabled ~ .custom-file-label {
    background-color: #edeef6; }
  .custom-file-input:lang(en) ~ .custom-file-label::after {
    content: "Browse"; }
  .custom-file-input ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse); }

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.6em + 1.375rem + 2px);
  padding: 0.6875rem 1.0625rem;
  font-weight: 400;
  line-height: 1.6;
  color: #7f808c;
  background-color: #edeef6;
  border: 1px solid #edeef6;
  border-radius: 0.375rem; }
  .custom-file-label::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.6em + 1.375rem);
    padding: 0.6875rem 1.0625rem;
    line-height: 1.6;
    color: #7f808c;
    content: "Browse";
    background-color: #edeef6;
    border-left: inherit;
    border-radius: 0 0.375rem 0.375rem 0; }

.custom-range {
  width: 100%;
  height: 1rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .custom-range:focus {
    outline: none; }
    .custom-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 rgba(1, 118, 255, 0.15); }
    .custom-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 rgba(1, 118, 255, 0.15); }
    .custom-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 rgba(1, 118, 255, 0.15); }
  .custom-range::-moz-focus-outer {
    border: 0; }
  .custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0176ff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-webkit-slider-thumb {
        transition: none; } }
    .custom-range::-webkit-slider-thumb:active {
      background-color: #b4d6ff; }
  .custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #edeef6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0176ff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-moz-range-thumb {
        transition: none; } }
    .custom-range::-moz-range-thumb:active {
      background-color: #b4d6ff; }
  .custom-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #edeef6;
    border-color: transparent;
    border-radius: 1rem; }
  .custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
    background-color: #0176ff;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .custom-range::-ms-thumb {
        transition: none; } }
    .custom-range::-ms-thumb:active {
      background-color: #b4d6ff; }
  .custom-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .custom-range::-ms-fill-lower {
    background-color: #edeef6;
    border-radius: 1rem; }
  .custom-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #edeef6;
    border-radius: 1rem; }
  .custom-range:disabled::-webkit-slider-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default; }
  .custom-range:disabled::-moz-range-thumb {
    background-color: #adb5bd; }
  .custom-range:disabled::-moz-range-track {
    cursor: default; }
  .custom-range:disabled::-ms-thumb {
    background-color: #adb5bd; }

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .custom-control-label::before,
    .custom-file-label,
    .custom-select {
      transition: none; } }

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; }

.nav-link {
  display: block;
  padding: 0.6875rem 1rem; }
  .nav-link:hover, .nav-link:focus {
    text-decoration: none; }
  .nav-link.disabled {
    color: #aab0b7;
    pointer-events: none;
    cursor: default; }

.nav-tabs {
  border-bottom: 0 solid rgba(245, 246, 250, 0.85); }
  .nav-tabs .nav-item {
    margin-bottom: 0; }
  .nav-tabs .nav-link {
    border: 0 solid transparent;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem; }
    .nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
      border-color: #edeef6 #edeef6 rgba(245, 246, 250, 0.85); }
    .nav-tabs .nav-link.disabled {
      color: #aab0b7;
      background-color: transparent;
      border-color: transparent; }
  .nav-tabs .nav-link.active,
  .nav-tabs .nav-item.show .nav-link {
    color: #212529;
    background-color: #fff;
    border-color: #dee2e6 #dee2e6 #fff; }
  .nav-tabs .dropdown-menu {
    margin-top: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0; }

.nav-pills .nav-link {
  border-radius: 0.375rem; }

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0176ff; }

.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center; }

  .chat-header  ul.nav .nav-item a.nav-link{
    width: 30px;
    height: 30px;
    background: #3E4360;
    padding: 6px 6px;
    border-radius: 100%;
    line-height: 0;
    text-align: center;
  }

  /// Added by AG Start /////
  .chat-header  ul.nav .nav-item span.nav-link{
    width: 30px;
    height: 30px;
    background: #3E4360;
    padding: 8px 6px;
    border-radius: 100%;
    line-height: 0.7;
    text-align: center;
    cursor: pointer;
  }
  .chat-header ul.nav .nav-item span.nav-link.zoomCall {
    background: #0176ff;
  }
  .chat-header ul.nav .nav-item span.nav-link.zoomCall img {
    width: 20px;
    position: relative;
    right: 1px;
}
  /// Added by AG End /////

  .chat-header  ul.nav .nav-item a.nav-link img{
    width: 100%;
    height: 100%;
  }


  .liveChatHeadreTootltip .titleShowHover{
      right: auto;
      left: 0;
  }




.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center; }

.tab-content > .tab-pane {
  display: none; }

.tab-content > .active {
  display: block; }

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem; }
  .navbar .container,
  .navbar .container-fluid, .navbar .container-sm, .navbar .container-md, .navbar .container-lg, .navbar .container-xl, .navbar .container-xxl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between; }

.navbar-brand {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-right: 1rem;
  font-size: 1.17188rem;
  line-height: inherit;
  white-space: nowrap; }
  .navbar-brand:hover, .navbar-brand:focus {
    text-decoration: none; }

.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none; width: 100%; }
  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0; }
  .navbar-nav .dropdown-menu {
    position: static;
    float: none; }

.navbar-text {
  display: inline-block;
  padding-top: 0.6875rem;
  padding-bottom: 0.6875rem; }

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center; }

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.17188rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.375rem; }
  .navbar-toggler:hover, .navbar-toggler:focus {
    text-decoration: none; }

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%; }

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-sm .navbar-nav {
      flex-direction: row; }
      .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: 1.25rem;
        padding-left: 1.25rem; }
    .navbar-expand-sm > .container,
    .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-xl, .navbar-expand-sm > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-sm .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-sm .navbar-toggler {
      display: none; } }

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 768px) {
  .navbar-expand-md {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-md .navbar-nav {
      flex-direction: row; }
      .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-md .navbar-nav .nav-link {
        padding-right: 1.25rem;
        padding-left: 1.25rem; }
    .navbar-expand-md > .container,
    .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-md, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-xl, .navbar-expand-md > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-md .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-md .navbar-toggler {
      display: none; } }

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-lg .navbar-nav {
      flex-direction: row; }
      .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 1.25rem;
        padding-left: 1.25rem; }
    .navbar-expand-lg > .container,
    .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-xl, .navbar-expand-lg > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-lg .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-lg .navbar-toggler {
      display: none; } }

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: 1.25rem;
        padding-left: 1.25rem; }
    .navbar-expand-xl > .container,
    .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-xl, .navbar-expand-xl > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-xl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xl .navbar-toggler {
      display: none; } }

@media (max-width: 1439.98px) {
  .navbar-expand-xxl > .container,
  .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
    padding-right: 0;
    padding-left: 0; } }

@media (min-width: 1440px) {
  .navbar-expand-xxl {
    flex-flow: row nowrap;
    justify-content: flex-start; }
    .navbar-expand-xxl .navbar-nav {
      flex-direction: row; }
      .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute; }
      .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: 1.25rem;
        padding-left: 1.25rem; }
    .navbar-expand-xxl > .container,
    .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-xl, .navbar-expand-xxl > .container-xxl {
      flex-wrap: nowrap; }
    .navbar-expand-xxl .navbar-collapse {
      display: flex !important;
      flex-basis: auto; }
    .navbar-expand-xxl .navbar-toggler {
      display: none; } }

.navbar-expand {
  flex-flow: row nowrap;
  justify-content: flex-start; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
    padding-right: 0;
    padding-left: 0; }
  .navbar-expand .navbar-nav {
    flex-direction: row; }
    .navbar-expand .navbar-nav .dropdown-menu {
      position: absolute; }
    .navbar-expand .navbar-nav .nav-link {
      padding-right: 1.25rem;
      padding-left: 1.25rem; }
  .navbar-expand > .container,
  .navbar-expand > .container-fluid, .navbar-expand > .container-sm, .navbar-expand > .container-md, .navbar-expand > .container-lg, .navbar-expand > .container-xl, .navbar-expand > .container-xxl {
    flex-wrap: nowrap; }
  .navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto; }
  .navbar-expand .navbar-toggler {
    display: none; }

.navbar-light .navbar-brand {
  color: #0176ff; }
  .navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: #0176ff; }

.navbar-light .navbar-nav .nav-link {
  color: #aab0b7; }
  .navbar-light .navbar-nav .nav-link img{width: 20px;}
  .user-profile-nav img{ width: 41px !important; height: 41px; border-radius:100% ; -webkit-border-radius:100% ; -moz-border-radius:100% ; -ms-border-radius:100% ; -o-border-radius:100% ; }
  .user-profile-nav{
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    -ms-border-radius: 100%;
    -o-border-radius: 100%;
    background: white;
}
  .navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #0176ff; }
  .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(170, 176, 183, 0.5); }    

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: #0176ff; }

.navbar-light .navbar-toggler {
  color: #aab0b7;
  border-color: rgba(0, 0, 0, 0.1); }

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23aab0b7' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-light .navbar-text {
  color: #aab0b7; }
  .navbar-light .navbar-text a {
    color: #0176ff; }
    .navbar-light .navbar-text a:hover, .navbar-light .navbar-text a:focus {
      color: #0176ff; }

.navbar-dark .navbar-brand {
  color: white; }
  .navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
    color: white; }

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
    color: white; }
  .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25); }

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: white; }

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.75);
  border-color: rgba(255, 255, 255, 0.1); }

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(255, 255, 255, 0.75)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"); }

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.75); }
  .navbar-dark .navbar-text a {
    color: white; }
    .navbar-dark .navbar-text a:hover, .navbar-dark .navbar-text a:focus {
      color: white; }

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: transparent;
  background-clip: border-box;
  border: 1px solid transparent;
  box-shadow: inherit;
  border-radius: 0.375rem;
  -webkit-border-radius: 0.375rem;
  -moz-border-radius: 0.375rem;
  -ms-border-radius: 0.375rem;
  -o-border-radius: 0.375rem;
}

.visitore-check-new .main-checkbox-all{
  position: absolute;
  top: 37%;
  left: 30px;
  z-index: 999;
  cursor: pointer;
  transform: translate(-30%, -100%);
  -webkit-transform: translate(-30%, -100%);
  -moz-transform: translate(-30%, -100%);
  -ms-transform: translate(-30%, -100%);
  -o-transform: translate(-30%, -100%);
  opacity: 0;
}

.ongoingCheckbx .main-checkbox-all{
  opacity: 1;
}

  .card.active, .card:hover{
    background: #44495C;
  }

  .card.active, .card:hover .visitore-check-new .main-checkbox-all{
    opacity: 1;
  }


  .card.active:hover .card-body .media .avatar-ongoing, .card:hover .card-body .media .avatar-ongoing{
    opacity: 0;
  }

  .psOngoing {
    opacity: 0;
  }

  .card.active, .card:hover .titleShowHover {
    display: block;
  }

 .card-active-listener .titleShowHover {
  left: 0;
  bottom: auto;
  top: 80%;
  cursor: auto;
  z-index: 9999;
}
.card-active-listener .titleShowHover.onguide-view {
  left: auto;
  top: 20%;
  right: 10px;
}

// #ongoing_collapse span.text-reset:last-child  .card-active-listener .titleShowHover, #incoming_collapse span.text-reset:last-child  .card-active-listener .titleShowHover {
//   left: auto;
//   bottom: 0;
//   top: auto;
//   cursor: auto;
//   right: 0;
// }

#ongoing_collapse span.text-reset:last-child  .card-active-listener .titleShowHover, #incoming_collapse span.text-reset:last-child  .card-active-listener .titleShowHover {
  left: 110px;
    bottom: 4px;
    top: auto;
    cursor: auto;
    right: auto;
}


#ongoing_collapse .titleShowHover, #incoming_collapse .titleShowHover{
  max-width: 250px;
  min-width: 120px;
}

// #ongoing_collapse .titleShowHover h6, #incoming_collapse .titleShowHover h6{
//   white-space: nowrap;
// }


#ongoing_collapse span.text-reset:last-child  .card-active-listener .titleShowHover h6{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    clear: both;
    max-width: 150px;
}

  span.text-reset{
    border-bottom: 1px solid rgba(255, 255, 255, 0.08);
    cursor: pointer;
  }

  span.text-reset .card .card-body {
      padding: 0.5rem 1rem 0.5rem 0.5rem;
  }

  #ongoing_collapse{
    max-height: calc(100vh - 150px);
  }   

#incoming_collapse{
  max-height: calc(100vh - 180px);
}


.plan-customers{
  content: attr(paid);
  color: #fff;
  font-size: 11px;
  padding: 2px 8px;
  display: inline-block;
  line-height: 1.4;
  border-radius: 4px;
  text-transform: uppercase;
  position: absolute;
  top: 40px;
  background: #e71e62;
  right: 15px;
}

.paid-customers::after{
  content: attr(paid);
  color: #fff;
  font-size: 11px;
  padding: 2px 8px;
  display: inline-block;
  line-height: 1.4;
  border-radius: 4px;
  text-transform: uppercase;
  position: absolute;
  top: 40px;
  background: #e71e62;
  right: 15px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader-bar {
  display: inline-block;
  margin: 0 8px;
  width: 4px;
  height: 18px;
  background-color: #1B85FC;
  animation: loader-animation 1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.loader-bar:nth-child(1) {
  animation-delay: -0.32s;
}

.loader-bar:nth-child(2) {
  animation-delay: -0.16s;
}

.loader-bar:nth-child(3) {
  animation-delay: 0s;
}

.loader-bar:nth-child(4) {
  animation-delay: 0.16s;
}

@keyframes loader-animation {
  0% {
    transform: scaleY(0.1);
  }
  50% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0.1);
  }
}

.trial-previous-customers::after{
  content: attr(trial);
  color: #fff;
  font-size: 11px;
  padding: 2px 8px;
  display: inline-block;
  line-height: 1.4;
  border-radius: 4px;
  text-transform: uppercase;
  position: absolute;
  top: 40px;
  background: #f7473a;
  right: 15px;
}

.trial-customers{
  content: attr(trial);
  color: #fff;
  font-size: 11px;
  padding: 2px 8px;
  display: inline-block;
  line-height: 1.4;
  border-radius: 4px;
  text-transform: uppercase;
  position: absolute;
  top: 40px;
  background: #f7473a;
  right: 15px;
}

.gold-customers{
  background: #f7473a;
}

.premium-customers{
  background: #e71e62;
}

.silver-customers{
  background: #dd16ff;
}

.card-body .media .social-customers {
  position: absolute;
  top: -6px;
  left: -4px;
  padding: 0;
  display: block;
}

.twitter-customers .twitter-dm, .facebook-customers img {
    width: 24px;
    margin-top: -50px;
}

.twitter-dm { width: 40px; color: #1DA1FB; fill : #1DA1FB }
.install-twitter-dm { width: 90px; color: #1DA1FB; fill : #1DA1FB; margin: 0 auto 20px; }

  .card .card-body{
    padding: 1rem 1rem 1rem 0.5rem;
  }

  .card > hr {
    margin-right: 0;
    margin-left: 0; }
  .card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem; }
  .card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem; }

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem; }

  .card-body.conversationLC {
    border-bottom: 1px solid #3f4156;
    padding-right: 20px;
    border-radius: 0.375rem;
  }

  .card-body.conversationLC:hover{
    background: #44495c;
  }   

  .card-body.conversationLC.selected{
    background: #44495c;
  }

.card-title {
  margin-bottom: 1.5rem; }

.card-subtitle {
  margin-top: -0.75rem;
  margin-bottom: 0; }

.card-text:last-child {
  margin-bottom: 0; }

.card-link:hover {
  text-decoration: none; }

.card-link + .card-link {
  margin-left: 1.25rem; }

.card-header {
  padding: 1.5rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(245, 246, 250, 0.85); }
  .card-header:first-child {
    border-radius: 0.375rem 0.375rem 0 0; }
  .card-header + .list-group .list-group-item:first-child {
    border-top: 0; }

.card-footer {
  padding: 1.5rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(245, 246, 250, 0.85); }
  .card-footer:last-child {
    border-radius: 0 0 0.375rem 0.375rem; }

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -1.5rem;
  margin-left: -0.625rem;
  border-bottom: 0; }

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem; }

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem; }

.card-img,
.card-img-top,
.card-img-bottom {
  flex-shrink: 0;
  width: 100%; }

.card-img,
.card-img-top {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem; }

.card-img,
.card-img-bottom {
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem; }

.card-deck .card {
  margin-bottom: 20px; }


  .card-body h6.text-truncate{
    color: #fff;
    max-width: 100px;
  }

  .card-body p.timevalue{
    font-size: 9px;
  }

  .card-body div.text-truncate{
    color: #d9d9d9;
    font-size: 12px;
    font-family: 'HelveticaNeueLight';
  }

@media (min-width: 576px) {
  .card-deck {
    display: flex;
    flex-flow: row wrap;
    margin-right: -20px;
    margin-left: -20px; }
    .card-deck .card {
      flex: 1 0 0%;
      margin-right: 20px;
      margin-bottom: 0;
      margin-left: 20px; } }

.card-group > .card {
  margin-bottom: 20px; }

@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap; }
    .card-group > .card {
      flex: 1 0 0%;
      margin-bottom: 0; }
      .card-group > .card + .card {
        margin-left: 0;
        border-left: 0; }
      .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-top,
        .card-group > .card:not(:last-child) .card-header {
          border-top-right-radius: 0; }
        .card-group > .card:not(:last-child) .card-img-bottom,
        .card-group > .card:not(:last-child) .card-footer {
          border-bottom-right-radius: 0; }
      .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-top,
        .card-group > .card:not(:first-child) .card-header {
          border-top-left-radius: 0; }
        .card-group > .card:not(:first-child) .card-img-bottom,
        .card-group > .card:not(:first-child) .card-footer {
          border-bottom-left-radius: 0; } }

.card-columns .card {
  margin-bottom: 1.5rem; }

@media (min-width: 576px) {
  .card-columns {
    column-count: 3;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1; }
    .card-columns .card {
      display: inline-block;
      width: 100%; } }

.accordion > .card {
  overflow: hidden; }
  .accordion > .card:not(:last-of-type) {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0; }
  .accordion > .card:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0; }
  .accordion > .card > .card-header {
    border-radius: 0;
    margin-bottom: -1px; }

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #edeef6;
  border-radius: 0.375rem; }

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem; }
  .breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
    padding-right: 0.5rem;
    color: #aab0b7;
    content: "/"; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline; }

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none; }

.breadcrumb-item.active {
  color: #aab0b7; }

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  border-radius: 0.375rem; }

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #0176ff;
  background-color: #fff;
  border: 1px solid #dee2e6; }
  .page-link:hover {
    z-index: 2;
    color: #0053b4;
    text-decoration: none;
    background-color: #edeef6;
    border-color: #dee2e6; }
  .page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 rgba(1, 118, 255, 0.15); }

.page-item:first-child .page-link {
  margin-left: 0;
  border-top-left-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem; }

.page-item:last-child .page-link {
  border-top-right-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem; }

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0176ff;
  border-color: #0176ff; }

.page-item.disabled .page-link {
  color: #aab0b7;
  pointer-events: none;
  cursor: auto;
  background-color: #fff;
  border-color: #dee2e6; }

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.17188rem;
  line-height: 1.5; }

.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.625rem;
  border-bottom-left-radius: 0.625rem; }

.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem; }

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.82031rem;
  line-height: 1.5; }

.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem; }

.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem; }

.badge {
  display: inline-block;
  padding: 0.5625rem 0.75rem;
  font-size: 66.667%;
  font-weight: 500;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.375rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .badge {
      transition: none; } }
  a.badge:hover, a.badge:focus {
    text-decoration: none; }
  .badge:empty {
    display: none; }

.btn .badge {
  position: relative;
  top: -1px; }

.badge-pill {
  padding-right: 0.6875rem;
  padding-left: 0.6875rem;
  border-radius: 10rem; }

.badge-basic {
  color: #212529;
  background-color: #fff; }
  a.badge-basic:hover, a.badge-basic:focus {
    color: #212529;
    background-color: #e6e6e6; }
  a.badge-basic:focus, a.badge-basic.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }

.badge-basic-inverse {
  color: #fff;
  background-color: #212529; }
  a.badge-basic-inverse:hover, a.badge-basic-inverse:focus {
    color: #fff;
    background-color: #0a0c0d; }
  a.badge-basic-inverse:focus, a.badge-basic-inverse.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(33, 37, 41, 0.5); }

.badge-primary {
  color: #fff;
  background-color: #0176ff; }
  a.badge-primary:hover, a.badge-primary:focus {
    color: #fff;
    background-color: #005ecd; }
  a.badge-primary:focus, a.badge-primary.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(1, 118, 255, 0.5); }



.badge-secondary {
  color: #212529;
  background-color: #edeef6; }
  a.badge-secondary:hover, a.badge-secondary:focus {
    color: #212529;
    background-color: #cbd0e5; }
  a.badge-secondary:focus, a.badge-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(237, 238, 246, 0.5); }

.badge-tertiary {
  color: #fff;
  background-color: #aab0b7; }
  a.badge-tertiary:hover, a.badge-tertiary:focus {
    color: #fff;
    background-color: #8e96a0; }
  a.badge-tertiary:focus, a.badge-tertiary.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(170, 176, 183, 0.5); }

.badge-success {
  color: #fff;
  background-color: #42ba96; }
  a.badge-success:hover, a.badge-success:focus {
    color: #fff;
    background-color: #359478; }
  a.badge-success:focus, a.badge-success.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(66, 186, 150, 0.5); }

.badge-info {
  color: #fff;
  background-color: #17a2b8; }
  a.badge-info:hover, a.badge-info:focus {
    color: #fff;
    background-color: #117a8b; }
  a.badge-info:focus, a.badge-info.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(23, 162, 184, 0.5); }

.badge-warning {
  color: #212529;
  background-color: #fcf22b; }
  a.badge-warning:hover, a.badge-warning:focus {
    color: #212529;
    background-color: #f1e503; }
  a.badge-warning:focus, a.badge-warning.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(252, 242, 43, 0.5); }

.badge-danger {
  color: #fff;
  background-color: #dc3545; }
  a.badge-danger:hover, a.badge-danger:focus {
    color: #fff;
    background-color: #bd2130; }
  a.badge-danger:focus, a.badge-danger.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(220, 53, 69, 0.5); }

.badge-light {
  color: #212529;
  background-color: #f5f6fa; }
  a.badge-light:hover, a.badge-light:focus {
    color: #212529;
    background-color: #d3d7e9; }
  a.badge-light:focus, a.badge-light.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(245, 246, 250, 0.5); }

.badge-dark {
  color: #fff;
  background-color: #242939; }
  a.badge-dark:hover, a.badge-dark:focus {
    color: #fff;
    background-color: #10131a; }
  a.badge-dark:focus, a.badge-dark.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(36, 41, 57, 0.5); }

.badge-white {
  color: #212529;
  background-color: #fff; }
  a.badge-white:hover, a.badge-white:focus {
    color: #212529;
    background-color: #e6e6e6; }
  a.badge-white:focus, a.badge-white.focus {
    outline: 0;
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.5); }


    .incomingSection .badge-primary, .ongoingSection .badge-primary{
      background-color: #e87b2e;
    } 




.jumbotron {
  padding: 2rem 1rem;
  margin-bottom: 2rem;
  background-color: #edeef6;
  border-radius: 0.625rem; }
  @media (min-width: 576px) {
    .jumbotron {
      padding: 4rem 2rem; } }

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0; }

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.375rem; }

.alert-heading {
  color: inherit; }

.alert-link {
  font-weight: 700; }

.alert-dismissible {
  padding-right: 3.90625rem; }
  .alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.75rem 1.25rem;
    color: inherit; }

.alert-basic {
  border-color: white;
  color: #212529;
  background-color: white; }
  .alert-basic hr {
    border-top-color: #f2f2f2; }
  .alert-basic .alert-link {
    color: #212529; }

.alert-basic-inverse {
  border-color: #212529;
  color: #fff;
  background-color: #212529; }
  .alert-basic-inverse hr {
    border-top-color: #16181b; }
  .alert-basic-inverse .alert-link {
    color: #fff; }

.alert-primary {
  border-color: #0176ff;
  color: #fff;
  background-color: #0176ff; }
  .alert-primary hr {
    border-top-color: #006ae7; }
  .alert-primary .alert-link {
    color: #fff; }

.alert-secondary {
  border-color: #edeef6;
  color: #212529;
  background-color: #edeef6; }
  .alert-secondary hr {
    border-top-color: #dcdeee; }
  .alert-secondary .alert-link {
    color: #212529; }

.alert-tertiary {
  border-color: #aab0b7;
  color: #fff;
  background-color: #aab0b7; }
  .alert-tertiary hr {
    border-top-color: #9ca3ab; }
  .alert-tertiary .alert-link {
    color: #fff; }

.alert-success {
  border-color: #42ba96;
  color: #fff;
  background-color: #42ba96; }
  .alert-success hr {
    border-top-color: #3ba787; }
  .alert-success .alert-link {
    color: #fff; }

.alert-info {
  border-color: #17a2b8;
  color: #fff;
  background-color: #17a2b8; }
  .alert-info hr {
    border-top-color: #148ea1; }
  .alert-info .alert-link {
    color: #fff; }

.alert-warning {
  border-color: #fcf22b;
  color: #212529;
  background-color: #fcf22b; }
  .alert-warning hr {
    border-top-color: #fcf012; }
  .alert-warning .alert-link {
    color: #212529; }

.alert-danger {
  border-color: #dc3545;
  color: #fff;
  background-color: #dc3545; }
  .alert-danger hr {
    border-top-color: #d32535; }
  .alert-danger .alert-link {
    color: #fff; }

.alert-light {
  border-color: #f5f6fa;
  color: #212529;
  background-color: #f5f6fa; }
  .alert-light hr {
    border-top-color: #e4e7f2; }
  .alert-light .alert-link {
    color: #212529; }

.alert-dark {
  border-color: #242939;
  color: #fff;
  background-color: #242939; }
  .alert-dark hr {
    border-top-color: #1a1e29; }
  .alert-dark .alert-link {
    color: #fff; }

.alert-white {
  border-color: white;
  color: #212529;
  background-color: white; }
  .alert-white hr {
    border-top-color: #f2f2f2; }
  .alert-white .alert-link {
    color: #212529; }

@keyframes progress-bar-stripes {
  from {
    background-position: 0.75rem 0; }
  to {
    background-position: 0 0; } }

.progress {
  display: flex;
  height: 0.75rem;
  overflow: hidden;
  font-size: 0.65625rem;
  background-color: #edeef6;
  border-radius: 0.375rem; }

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0176ff;
  transition: width 0.6s ease; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none; } }

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 0.75rem 0.75rem; }

.progress-bar-animated {
  animation: progress-bar-stripes 1s linear infinite; }
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none; } }

.media {
  display: flex;
  align-items: flex-start; }

.media-body {
  flex: 1; }

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0; }



  .visitore_heding_size{
    width: calc(100% - 80px) !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 14px;
  }
 
  .not-filter{
    height: calc(100vh - 127px) !important;
  }

  .list-group.fix-hight{
    max-height: 290px;
  }

.list-group-item-action {
  width: 100%;
  color: #7f808c;
  text-align: inherit; }
  .list-group-item-action:hover, .list-group-item-action:focus {
    z-index: 1;
    color: #7f808c;
    text-decoration: none;
    background-color: #0176ff; }
  .list-group-item-action:active {
    color: #7f808c;
    background-color: #edeef6; }

.list-group-item {
  position: relative;
  display: block;
  padding: 1.2rem 1.25rem;
  background-color: transparent;
  border: 1px solid rgba(245, 246, 250, 0.85); }
  .list-group-item:first-child {
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem; }
  .list-group-item:last-child {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem; }
  .list-group-item.disabled, .list-group-item:disabled {
    color: #aab0b7;
    pointer-events: none;
    background-color: transparent; }
  .list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #0176ff;
    border-color: rgba(245, 246, 250, 0.85); }
  .list-group-item + .list-group-item {
    border-top-width: 0; }
    .list-group-item + .list-group-item.active {
      margin-top: -1px;
      border-top-width: 1px; }

.list-group-horizontal {
  flex-direction: row; }
  .list-group-horizontal .list-group-item:first-child {
    border-bottom-left-radius: 0.375rem;
    border-top-right-radius: 0; }
  .list-group-horizontal .list-group-item:last-child {
    border-top-right-radius: 0.375rem;
    border-bottom-left-radius: 0; }
  .list-group-horizontal .list-group-item.active {
    margin-top: 0; }
  .list-group-horizontal .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0; }
    .list-group-horizontal .list-group-item + .list-group-item.active {
      margin-left: -1px;
      border-left-width: 1px; }

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row; }
    .list-group-horizontal-sm .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-sm .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-sm .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-sm .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-sm .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row; }
    .list-group-horizontal-md .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-md .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-md .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-md .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-md .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row; }
    .list-group-horizontal-lg .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-lg .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-lg .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-lg .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-lg .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row; }
    .list-group-horizontal-xl .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xl .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

@media (min-width: 1440px) {
  .list-group-horizontal-xxl {
    flex-direction: row; }
    .list-group-horizontal-xxl .list-group-item:first-child {
      border-bottom-left-radius: 0.375rem;
      border-top-right-radius: 0; }
    .list-group-horizontal-xxl .list-group-item:last-child {
      border-top-right-radius: 0.375rem;
      border-bottom-left-radius: 0; }
    .list-group-horizontal-xxl .list-group-item.active {
      margin-top: 0; }
    .list-group-horizontal-xxl .list-group-item + .list-group-item {
      border-top-width: 1px;
      border-left-width: 0; }
      .list-group-horizontal-xxl .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px; } }

.list-group-flush .list-group-item {
  border-right-width: 0;
  border-left-width: 0;
  border-radius: 0; }
  .list-group-flush .list-group-item:first-child {
    border-top-width: 0; }

.list-group-flush:last-child .list-group-item:last-child {
  border-bottom-width: 0; }

.list-group-item-basic {
  color: #858585;
  background-color: white; }
  .list-group-item-basic.list-group-item-action:hover, .list-group-item-basic.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-basic.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.list-group-item-basic-inverse {
  color: #111315;
  background-color: #c1c2c3; }
  .list-group-item-basic-inverse.list-group-item-action:hover, .list-group-item-basic-inverse.list-group-item-action:focus {
    color: #111315;
    background-color: #b4b5b6; }
  .list-group-item-basic-inverse.list-group-item-action.active {
    color: #fff;
    background-color: #111315;
    border-color: #111315; }

.list-group-item-primary {
  color: #013d85;
  background-color: #b8d9ff; }
  .list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
    color: #013d85;
    background-color: #9fcbff; }
  .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #013d85;
    border-color: #013d85; }

.list-group-item-secondary {
  color: #7b7c80;
  background-color: #fafafc; }
  .list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
    color: #7b7c80;
    background-color: #eaeaf2; }
  .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #7b7c80;
    border-color: #7b7c80; }

.list-group-item-tertiary {
  color: #585c5f;
  background-color: #e7e9eb; }
  .list-group-item-tertiary.list-group-item-action:hover, .list-group-item-tertiary.list-group-item-action:focus {
    color: #585c5f;
    background-color: #d9dcdf; }
  .list-group-item-tertiary.list-group-item-action.active {
    color: #fff;
    background-color: #585c5f;
    border-color: #585c5f; }

.list-group-item-success {
  color: #22614e;
  background-color: #caece2; }
  .list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
    color: #22614e;
    background-color: #b7e5d8; }
  .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #22614e;
    border-color: #22614e; }

.list-group-item-info {
  color: #0c5460;
  background-color: #bee5eb; }
  .list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
    color: #0c5460;
    background-color: #abdde5; }
  .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #0c5460;
    border-color: #0c5460; }

.list-group-item-warning {
  color: #837e16;
  background-color: #fefbc4; }
  .list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
    color: #837e16;
    background-color: #fef9ab; }
  .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #837e16;
    border-color: #837e16; }

.list-group-item-danger {
  color: #721c24;
  background-color: #f5c6cb; }
  .list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
    color: #721c24;
    background-color: #f1b0b7; }
  .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #721c24;
    border-color: #721c24; }

.list-group-item-light {
  color: #7f8082;
  background-color: #fcfcfe; }
  .list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
    color: #7f8082;
    background-color: #e9e9f8; }
  .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #7f8082;
    border-color: #7f8082; }

.list-group-item-dark {
  color: #13151e;
  background-color: #c2c3c8; }
  .list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
    color: #13151e;
    background-color: #b5b6bc; }
  .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #13151e;
    border-color: #13151e; }

.list-group-item-white {
  color: #858585;
  background-color: white; }
  .list-group-item-white.list-group-item-action:hover, .list-group-item-white.list-group-item-action:focus {
    color: #858585;
    background-color: #f2f2f2; }
  .list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585; }

.close {
  float: right;
  font-size: 1.40625rem;
  font-weight: 400;
  line-height: 1;
  color: #212529;
  text-shadow: 0;
  opacity: .5; }
  @media (max-width: 1200px) {
    .close {
      font-size: calc(1.26563rem + 0.1875vw) ; } }
  .close:hover {
    color: #212529;
    text-decoration: none; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .75; }

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none; }

a.close.disabled {
  pointer-events: none; }

.toast {
  max-width: 350px;
  overflow: hidden;
  font-size: 0.875rem;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  opacity: 0;
  border-radius: 0.25rem; }
  .toast:not(:last-child) {
    margin-bottom: 0.75rem; }
  .toast.showing {
    opacity: 1; }
  .toast.show {
    display: block;
    opacity: 1; }
  .toast.hide {
    display: none; }

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.75rem;
  color: #aab0b7;
  background-color: #fff;
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05); }

.toast-body {
  padding: 0.75rem; }

.modal-open {
  overflow: hidden; }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0; }

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none; }
  .modal.fade .modal-dialog {
    transition: transform 0.3s ease-out;
    transform: translate(0, -50px); }
    @media (prefers-reduced-motion: reduce) {
      .modal.fade .modal-dialog {
        transition: none; } }
  .modal.show .modal-dialog {
    transform: none; }
  .modal.modal-static .modal-dialog {
    transform: scale(1.02); }

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem); }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden; }
  .modal-dialog-scrollable .modal-header,
  .modal-dialog-scrollable .modal-footer {
    flex-shrink: 0; }
  .modal-dialog-scrollable .modal-body {
    overflow-y: auto; }

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem); }
  .modal-dialog-centered::before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""; }
  .modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%; }
    .modal-dialog-centered.modal-dialog-scrollable .modal-content {
      max-height: none; }
    .modal-dialog-centered.modal-dialog-scrollable::before {
      content: none; }

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(245, 246, 250, 0.85);
  border-radius: 0.625rem;
  outline: 0; }

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000; }
  .modal-backdrop.fade {
    opacity: 0; }
  .modal-backdrop.show {
    opacity: 0.5; }

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1.5rem 1.5rem;
  border-bottom: 1px solid rgba(245, 246, 250, 0.85);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .modal-header .close {
    padding: 1.5rem 1.5rem;
    margin: -1.5rem -1.5rem -1.5rem auto; }

.modal-title {
  margin-bottom: 0;
  line-height: 1.6; }

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem; }

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid rgba(245, 246, 250, 0.85);
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px); }
  .modal-footer > * {
    margin: 0.25rem; }

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll; }

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto; }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem); }
    .modal-dialog-scrollable .modal-content {
      max-height: calc(100vh - 3.5rem); }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem); }
    .modal-dialog-centered::before {
      height: calc(100vh - 3.5rem); }
  .modal-sm {
    max-width: 300px; } }

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px; } }

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px; } }

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.82031rem;
  word-wrap: break-word;
  opacity: 0; }
  .tooltip.show {
    opacity: 0.9; }
  .tooltip .arrow {
    position: absolute;
    display: block;
    width: 0.8rem;
    height: 0.4rem; }
    .tooltip .arrow::before {
      position: absolute;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0; }
  .bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
    bottom: 0; }
    .bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
      top: 0;
      border-width: 0.4rem 0.4rem 0;
      border-top-color: #000; }

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem; }
  .bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
    left: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
      right: 0;
      border-width: 0.4rem 0.4rem 0.4rem 0;
      border-right-color: #000; }

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0; }
  .bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
    top: 0; }
    .bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
      bottom: 0;
      border-width: 0 0.4rem 0.4rem;
      border-bottom-color: #000; }

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem; }
  .bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
    right: 0;
    width: 0.4rem;
    height: 0.8rem; }
    .bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
      left: 0;
      border-width: 0.4rem 0 0.4rem 0.4rem;
      border-left-color: #000; }

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.375rem; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: block;
  max-width: 276px;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-weight: 400;
  line-height: 1.6;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.82031rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(245, 246, 250, 0.85);
  border-radius: 0.625rem; }
  .popover .arrow {
    position: absolute;
    display: block;
    width: 10px;
    height: 10px;
    margin: 0 0.625rem; }
    .popover .arrow::before, .popover .arrow::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid; }

.bs-popover-top, .bs-popover-auto[x-placement^="top"] {
  margin-bottom: 10px; }
  .bs-popover-top > .arrow, .bs-popover-auto[x-placement^="top"] > .arrow {
    bottom: -11px; }
    .bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before {
      bottom: 0;
      border-width: 10px 5px 0;
      border-top-color: rgba(245, 246, 250, 0.9); }
    .bs-popover-top > .arrow::after, .bs-popover-auto[x-placement^="top"] > .arrow::after {
      bottom: 1px;
      border-width: 10px 5px 0;
      border-top-color: #fff; }

.bs-popover-right, .bs-popover-auto[x-placement^="right"] {
  margin-left: 10px; }
  .bs-popover-right > .arrow, .bs-popover-auto[x-placement^="right"] > .arrow {
    left: -11px;
    width: 10px;
    height: 10px;
    margin: 0.625rem 0; }
    .bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before {
      left: 0;
      border-width: 5px 10px 5px 0;
      border-right-color: rgba(245, 246, 250, 0.9); }
    .bs-popover-right > .arrow::after, .bs-popover-auto[x-placement^="right"] > .arrow::after {
      left: 1px;
      border-width: 5px 10px 5px 0;
      border-right-color: #fff; }

.bs-popover-bottom, .bs-popover-auto[x-placement^="bottom"] {
  margin-top: 10px; }
  .bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^="bottom"] > .arrow {
    top: -11px; }
    .bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before {
      top: 0;
      border-width: 0 5px 10px 5px;
      border-bottom-color: rgba(245, 246, 250, 0.9); }
    .bs-popover-bottom > .arrow::after, .bs-popover-auto[x-placement^="bottom"] > .arrow::after {
      top: 1px;
      border-width: 0 5px 10px 5px;
      border-bottom-color: #fff; }
  .bs-popover-bottom .popover-header::before, .bs-popover-auto[x-placement^="bottom"] .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 10px;
    margin-left: -5px;
    content: "";
    border-bottom: 1px solid #fff; }

.bs-popover-left, .bs-popover-auto[x-placement^="left"] {
  margin-right: 10px; }
  .bs-popover-left > .arrow, .bs-popover-auto[x-placement^="left"] > .arrow {
    right: -11px;
    width: 10px;
    height: 10px;
    margin: 0.625rem 0; }
    .bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before {
      right: 0;
      border-width: 5px 0 5px 10px;
      border-left-color: rgba(245, 246, 250, 0.9); }
    .bs-popover-left > .arrow::after, .bs-popover-auto[x-placement^="left"] > .arrow::after {
      right: 1px;
      border-width: 5px 0 5px 10px;
      border-left-color: #fff; }

.popover-header {
  padding: 0.75rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.9375rem;
  color: #242939;
  background-color: #fff;
  border-bottom: 1px solid #f2f2f2;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px); }
  .popover-header:empty {
    display: none; }

.popover-body {
  padding: 0.75rem 0.75rem;
  color: #7f808c; }

.carousel {
  position: relative; }

.carousel.pointer-event {
  touch-action: pan-y; }

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden; }
  .carousel-inner::after {
    display: block;
    clear: both;
    content: ""; }

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-item {
      transition: none; } }

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block; }

.carousel-item-next:not(.carousel-item-left),
.active.carousel-item-right {
  transform: translateX(100%); }

.carousel-item-prev:not(.carousel-item-right),
.active.carousel-item-left {
  transform: translateX(-100%); }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  z-index: 1;
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left,
    .carousel-fade .active.carousel-item-right {
      transition: none; } }

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  color: #fff;
  text-align: center;
  opacity: 0.5;
  transition: opacity 0.15s ease; }
  @media (prefers-reduced-motion: reduce) {
    .carousel-control-prev,
    .carousel-control-next {
      transition: none; } }
  .carousel-control-prev:hover, .carousel-control-prev:focus,
  .carousel-control-next:hover,
  .carousel-control-next:focus {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: 0.9; }

.carousel-control-prev {
  left: 0; }

.carousel-control-next {
  right: 0; }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: no-repeat 50% / 100% 100%; }

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3e%3c/svg%3e"); }

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3e%3c/svg%3e"); }

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none; }
  .carousel-indicators li {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity 0.6s ease; }
    @media (prefers-reduced-motion: reduce) {
      .carousel-indicators li {
        transition: none; } }
  .carousel-indicators .active {
    opacity: 1; }

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center; }

@keyframes spinner-border {
  to {
    transform: rotate(360deg); } }

.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border .75s linear infinite; }

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em; }

@keyframes spinner-grow {
  0% {
    transform: scale(0); }
  50% {
    opacity: 1; } }

.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: spinner-grow .75s linear infinite; }

.spinner-grow-sm {
  width: 1rem;
  height: 1rem; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-basic {
  background-color: #fff !important; }

a.bg-basic:hover, a.bg-basic:focus,
button.bg-basic:hover,
button.bg-basic:focus {
  background-color: #e6e6e6 !important; }

.bg-basic-inverse {
  background-color: #212529 !important; }

a.bg-basic-inverse:hover, a.bg-basic-inverse:focus,
button.bg-basic-inverse:hover,
button.bg-basic-inverse:focus {
  background-color: #0a0c0d !important; }

.bg-primary {
  background-color: #0176ff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #005ecd !important; }

.bg-secondary {
  background-color: #edeef6 !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #cbd0e5 !important; }

.bg-tertiary {
  background-color: #aab0b7 !important; }

a.bg-tertiary:hover, a.bg-tertiary:focus,
button.bg-tertiary:hover,
button.bg-tertiary:focus {
  background-color: #8e96a0 !important; }

.bg-success {
  background-color: #42ba96 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #359478 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #fcf22b !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #f1e503 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f5f6fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #d3d7e9 !important; }

.bg-dark {
  background-color: #242939 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #10131a !important; }

.bg-white {
  background-color: #fff !important; }

a.bg-white:hover, a.bg-white:focus,
button.bg-white:hover,
button.bg-white:focus {
  background-color: #e6e6e6 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid rgba(245, 246, 250, 0.85) !important; }

.border-top {
  border-top: 1px solid rgba(245, 246, 250, 0.85) !important; }

.border-right {
  border-right: 1px solid rgba(245, 246, 250, 0.85) !important; }

.border-bottom {
  border-bottom: 1px solid rgba(245, 246, 250, 0.85) !important; }

.border-left {
  border-left: 1px solid rgba(245, 246, 250, 0.85) !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-basic {
  border-color: #fff !important; }

.border-basic-inverse {
  border-color: #212529 !important; }

.border-primary {
  border-color: #0176ff !important; }

.border-secondary {
  border-color: #edeef6 !important; }

.border-tertiary {
  border-color: #aab0b7 !important; }

.border-success {
  border-color: #42ba96 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #fcf22b !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f5f6fa !important; }

.border-dark {
  border-color: #242939 !important; }

.border-white {
  border-color: #fff !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.375rem !important; }

.rounded-top {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important; }

.rounded-right {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important; }

.rounded-left {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important; }

.rounded-lg {
  border-radius: 0.625rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; } }

@media (min-width: 1440px) {
  .d-xxl-none {
    display: none !important; }
  .d-xxl-inline {
    display: inline !important; }
  .d-xxl-inline-block {
    display: inline-block !important; }
  .d-xxl-block {
    display: block !important; }
  .d-xxl-table {
    display: table !important; }
  .d-xxl-table-row {
    display: table-row !important; }
  .d-xxl-table-cell {
    display: table-cell !important; }
  .d-xxl-flex {
    display: flex !important; }
  .d-xxl-inline-flex {
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; } }

@media (min-width: 1440px) {
  .flex-xxl-row {
    flex-direction: row !important; }
  .flex-xxl-column {
    flex-direction: column !important; }
  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xxl-wrap {
    flex-wrap: wrap !important; }
  .flex-xxl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .flex-xxl-fill {
    flex: 1 1 auto !important; }
  .flex-xxl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xxl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important; }
  .justify-content-xxl-start {
    justify-content: flex-start !important; }
  .justify-content-xxl-end {
    justify-content: flex-end !important; }
  .justify-content-xxl-center {
    justify-content: center !important; }
  .justify-content-xxl-between {
    justify-content: space-between !important; }
  .justify-content-xxl-around {
    justify-content: space-around !important; }
  .align-items-xxl-start {
    align-items: flex-start !important; }
  .align-items-xxl-end {
    align-items: flex-end !important; }
  .align-items-xxl-center {
    align-items: center !important; }
  .align-items-xxl-baseline {
    align-items: baseline !important; }
  .align-items-xxl-stretch {
    align-items: stretch !important; }
  .align-content-xxl-start {
    align-content: flex-start !important; }
  .align-content-xxl-end {
    align-content: flex-end !important; }
  .align-content-xxl-center {
    align-content: center !important; }
  .align-content-xxl-between {
    align-content: space-between !important; }
  .align-content-xxl-around {
    align-content: space-around !important; }
  .align-content-xxl-stretch {
    align-content: stretch !important; }
  .align-self-xxl-auto {
    align-self: auto !important; }
  .align-self-xxl-start {
    align-self: flex-start !important; }
  .align-self-xxl-end {
    align-self: flex-end !important; }
  .align-self-xxl-center {
    align-self: center !important; }
  .align-self-xxl-baseline {
    align-self: baseline !important; }
  .align-self-xxl-stretch {
    align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

@media (min-width: 1440px) {
  .float-xxl-left {
    float: left !important; }
  .float-xxl-right {
    float: right !important; }
  .float-xxl-none {
    float: none !important; } }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.035) !important; }

.shadow {
  box-shadow: 0 1.5rem 4rem rgba(0, 0, 0, 0.05) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.125rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.125rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.125rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.125rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.125rem !important; }

.m-2 {
  margin: 0.25rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.25rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.25rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.25rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.25rem !important; }

.m-3 {
  margin: 0.5rem !important; }

.mt-3,
.my-3 {
  margin-top: 0.5rem !important; }

.mr-3,
.mx-3 {
  margin-right: 0.5rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 0.5rem !important; }

.ml-3,
.mx-3 {
  margin-left: 0.5rem !important; }

.m-4 {
  margin: 0.75rem !important; }

.mt-4,
.my-4 {
  margin-top: 0.75rem !important; }

.mr-4,
.mx-4 {
  margin-right: 0.75rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 0.75rem !important; }

.ml-4,
.mx-4 {
  margin-left: 0.75rem !important; }

.m-5 {
  margin: 1rem !important; }

.mt-5,
.my-5 {
  margin-top: 1rem !important; }

.mr-5,
.mx-5 {
  margin-right: 1rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 1rem !important; }

.ml-5,
.mx-5 {
  margin-left: 1rem !important; }

.m-6 {
  margin: 1.25rem !important; }

.mt-6,
.my-6 {
  margin-top: 1.25rem !important; }

.mr-6,
.mx-6 {
  margin-right: 1.25rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 1.25rem !important; }

.ml-6,
.mx-6 {
  margin-left: 1.25rem !important; }

.m-7 {
  margin: 1.5rem !important; }

.mt-7,
.my-7 {
  margin-top: 1.5rem !important; }

.mr-7,
.mx-7 {
  margin-right: 1.5rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 1.5rem !important; }

.ml-7,
.mx-7 {
  margin-left: 1.5rem !important; }

.m-8 {
  margin: 2rem !important; }

.mt-8,
.my-8 {
  margin-top: 2rem !important; }

.mr-8,
.mx-8 {
  margin-right: 2rem !important; }

.mb-8,
.my-8 {
  margin-bottom: 2rem !important; }

.ml-8,
.mx-8 {
  margin-left: 2rem !important; }

.m-9 {
  margin: 2.5rem !important; }

.mt-9,
.my-9 {
  margin-top: 2.5rem !important; }

.mr-9,
.mx-9 {
  margin-right: 2.5rem !important; }

.mb-9,
.my-9 {
  margin-bottom: 2.5rem !important; }

.ml-9,
.mx-9 {
  margin-left: 2.5rem !important; }

.m-10 {
  margin: 3.5rem !important; }

.mt-10,
.my-10 {
  margin-top: 3.5rem !important; }

.mr-10,
.mx-10 {
  margin-right: 3.5rem !important; }

.mb-10,
.my-10 {
  margin-bottom: 3.5rem !important; }

.ml-10,
.mx-10 {
  margin-left: 3.5rem !important; }

.m-11 {
  margin: 5.5rem !important; }

.mt-11,
.my-11 {
  margin-top: 5.5rem !important; }

.mr-11,
.mx-11 {
  margin-right: 5.5rem !important; }

.mb-11,
.my-11 {
  margin-bottom: 5.5rem !important; }

.ml-11,
.mx-11 {
  margin-left: 5.5rem !important; }

.m-12 {
  margin: 8rem !important; }

.mt-12,
.my-12 {
  margin-top: 8rem !important; }

.mr-12,
.mx-12 {
  margin-right: 8rem !important; }

.mb-12,
.my-12 {
  margin-bottom: 8rem !important; }

.ml-12,
.mx-12 {
  margin-left: 8rem !important; }

.m-13 {
  margin: 10rem !important; }

.mt-13,
.my-13 {
  margin-top: 10rem !important; }

.mr-13,
.mx-13 {
  margin-right: 10rem !important; }

.mb-13,
.my-13 {
  margin-bottom: 10rem !important; }

.ml-13,
.mx-13 {
  margin-left: 10rem !important; }

.m-14 {
  margin: 12rem !important; }

.mt-14,
.my-14 {
  margin-top: 12rem !important; }

.mr-14,
.mx-14 {
  margin-right: 12rem !important; }

.mb-14,
.my-14 {
  margin-bottom: 12rem !important; }

.ml-14,
.mx-14 {
  margin-left: 12rem !important; }

.m-15 {
  margin: 15rem !important; }

.mt-15,
.my-15 {
  margin-top: 15rem !important; }

.mr-15,
.mx-15 {
  margin-right: 15rem !important; }

.mb-15,
.my-15 {
  margin-bottom: 15rem !important; }

.ml-15,
.mx-15 {
  margin-left: 15rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.125rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.125rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.125rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.125rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.125rem !important; }

.p-2 {
  padding: 0.25rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.25rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.25rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.25rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.25rem !important; }

.p-3 {
  padding: 0.5rem !important; }

.pt-3,
.py-3 {
  padding-top: 0.5rem !important; }

.pr-3,
.px-3 {
  padding-right: 0.5rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 0.5rem !important; }

.pl-3,
.px-3 {
  padding-left: 0.5rem !important; }

.p-4 {
  padding: 0.75rem !important; }

.pt-4,
.py-4 {
  padding-top: 0.75rem !important; }

.pr-4,
.px-4 {
  padding-right: 0.75rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 0.75rem !important; }

.pl-4,
.px-4 {
  padding-left: 0.75rem !important; }

.p-5 {
  padding: 1rem !important; }

.pt-5,
.py-5 {
  padding-top: 1rem !important; }

.pr-5,
.px-5 {
  padding-right: 1rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 1rem !important; }

.pl-5,
.px-5 {
  padding-left: 1rem !important; }

.p-6 {
  padding: 1.25rem !important; }

.pt-6,
.py-6 {
  padding-top: 1.25rem !important; }

.pr-6,
.px-6 {
  padding-right: 1.25rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 1.25rem !important; }

.pl-6,
.px-6 {
  padding-left: 1.25rem !important; }

.p-7 {
  padding: 1.5rem !important; }

.pt-7,
.py-7 {
  padding-top: 1.5rem !important; }

.pr-7,
.px-7 {
  padding-right: 1.5rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 1.5rem !important; }

.pl-7,
.px-7 {
  padding-left: 1.5rem !important; }

.p-8 {
  padding: 2rem !important; }

.pt-8,
.py-8 {
  padding-top: 2rem !important; }

.pr-8,
.px-8 {
  padding-right: 2rem !important; }

.pb-8,
.py-8 {
  padding-bottom: 2rem !important; }

.pl-8,
.px-8 {
  padding-left: 2rem !important; }

.p-9 {
  padding: 2.5rem !important; }

.pt-9,
.py-9 {
  padding-top: 2.5rem !important; }

.pr-9,
.px-9 {
  padding-right: 2.5rem !important; }

.pb-9,
.py-9 {
  padding-bottom: 2.5rem !important; }

.pl-9,
.px-9 {
  padding-left: 2.5rem !important; }

.p-10 {
  padding: 3.5rem !important; }

.pt-10,
.py-10 {
  padding-top: 3.5rem !important; }

.pr-10,
.px-10 {
  padding-right: 3.5rem !important; }

.pb-10,
.py-10 {
  padding-bottom: 3.5rem !important; }

.pl-10,
.px-10 {
  padding-left: 3.5rem !important; }

.p-11 {
  padding: 5.5rem !important; }

.pt-11,
.py-11 {
  padding-top: 5.5rem !important; }

.pr-11,
.px-11 {
  padding-right: 5.5rem !important; }

.pb-11,
.py-11 {
  padding-bottom: 5.5rem !important; }

.pl-11,
.px-11 {
  padding-left: 5.5rem !important; }

.p-12 {
  padding: 8rem !important; }

.pt-12,
.py-12 {
  padding-top: 8rem !important; }

.pr-12,
.px-12 {
  padding-right: 8rem !important; }

.pb-12,
.py-12 {
  padding-bottom: 8rem !important; }

.pl-12,
.px-12 {
  padding-left: 8rem !important; }

.p-13 {
  padding: 10rem !important; }

.pt-13,
.py-13 {
  padding-top: 10rem !important; }

.pr-13,
.px-13 {
  padding-right: 10rem !important; }

.pb-13,
.py-13 {
  padding-bottom: 10rem !important; }

.pl-13,
.px-13 {
  padding-left: 10rem !important; }

.p-14 {
  padding: 12rem !important; }

.pt-14,
.py-14 {
  padding-top: 12rem !important; }

.pr-14,
.px-14 {
  padding-right: 12rem !important; }

.pb-14,
.py-14 {
  padding-bottom: 12rem !important; }

.pl-14,
.px-14 {
  padding-left: 12rem !important; }

.p-15 {
  padding: 15rem !important; }

.pt-15,
.py-15 {
  padding-top: 15rem !important; }

.pr-15,
.px-15 {
  padding-right: 15rem !important; }

.pb-15,
.py-15 {
  padding-bottom: 15rem !important; }

.pl-15,
.px-15 {
  padding-left: 15rem !important; }

.m-n1 {
  margin: -0.125rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.125rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.125rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.125rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.125rem !important; }

.m-n2 {
  margin: -0.25rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.25rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.25rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.25rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.25rem !important; }

.m-n3 {
  margin: -0.5rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -0.5rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -0.5rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -0.5rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -0.5rem !important; }

.m-n4 {
  margin: -0.75rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -0.75rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -0.75rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -0.75rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -0.75rem !important; }

.m-n5 {
  margin: -1rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -1rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -1rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -1rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -1rem !important; }

.m-n6 {
  margin: -1.25rem !important; }

.mt-n6,
.my-n6 {
  margin-top: -1.25rem !important; }

.mr-n6,
.mx-n6 {
  margin-right: -1.25rem !important; }

.mb-n6,
.my-n6 {
  margin-bottom: -1.25rem !important; }

.ml-n6,
.mx-n6 {
  margin-left: -1.25rem !important; }

.m-n7 {
  margin: -1.5rem !important; }

.mt-n7,
.my-n7 {
  margin-top: -1.5rem !important; }

.mr-n7,
.mx-n7 {
  margin-right: -1.5rem !important; }

.mb-n7,
.my-n7 {
  margin-bottom: -1.5rem !important; }

.ml-n7,
.mx-n7 {
  margin-left: -1.5rem !important; }

.m-n8 {
  margin: -2rem !important; }

.mt-n8,
.my-n8 {
  margin-top: -2rem !important; }

.mr-n8,
.mx-n8 {
  margin-right: -2rem !important; }

.mb-n8,
.my-n8 {
  margin-bottom: -2rem !important; }

.ml-n8,
.mx-n8 {
  margin-left: -2rem !important; }

.m-n9 {
  margin: -2.5rem !important; }

.mt-n9,
.my-n9 {
  margin-top: -2.5rem !important; }

.mr-n9,
.mx-n9 {
  margin-right: -2.5rem !important; }

.mb-n9,
.my-n9 {
  margin-bottom: -2.5rem !important; }

.ml-n9,
.mx-n9 {
  margin-left: -2.5rem !important; }

.m-n10 {
  margin: -3.5rem !important; }

.mt-n10,
.my-n10 {
  margin-top: -3.5rem !important; }

.mr-n10,
.mx-n10 {
  margin-right: -3.5rem !important; }

.mb-n10,
.my-n10 {
  margin-bottom: -3.5rem !important; }

.ml-n10,
.mx-n10 {
  margin-left: -3.5rem !important; }

.m-n11 {
  margin: -5.5rem !important; }

.mt-n11,
.my-n11 {
  margin-top: -5.5rem !important; }

.mr-n11,
.mx-n11 {
  margin-right: -5.5rem !important; }

.mb-n11,
.my-n11 {
  margin-bottom: -5.5rem !important; }

.ml-n11,
.mx-n11 {
  margin-left: -5.5rem !important; }

.m-n12 {
  margin: -8rem !important; }

.mt-n12,
.my-n12 {
  margin-top: -8rem !important; }

.mr-n12,
.mx-n12 {
  margin-right: -8rem !important; }

.mb-n12,
.my-n12 {
  margin-bottom: -8rem !important; }

.ml-n12,
.mx-n12 {
  margin-left: -8rem !important; }

.m-n13 {
  margin: -10rem !important; }

.mt-n13,
.my-n13 {
  margin-top: -10rem !important; }

.mr-n13,
.mx-n13 {
  margin-right: -10rem !important; }

.mb-n13,
.my-n13 {
  margin-bottom: -10rem !important; }

.ml-n13,
.mx-n13 {
  margin-left: -10rem !important; }

.m-n14 {
  margin: -12rem !important; }

.mt-n14,
.my-n14 {
  margin-top: -12rem !important; }

.mr-n14,
.mx-n14 {
  margin-right: -12rem !important; }

.mb-n14,
.my-n14 {
  margin-bottom: -12rem !important; }

.ml-n14,
.mx-n14 {
  margin-left: -12rem !important; }

.m-n15 {
  margin: -15rem !important; }

.mt-n15,
.my-n15 {
  margin-top: -15rem !important; }

.mr-n15,
.mx-n15 {
  margin-right: -15rem !important; }

.mb-n15,
.my-n15 {
  margin-bottom: -15rem !important; }

.ml-n15,
.mx-n15 {
  margin-left: -15rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.125rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.125rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.125rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.125rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.125rem !important; }
  .m-sm-2 {
    margin: 0.25rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.25rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.25rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.25rem !important; }
  .m-sm-3 {
    margin: 0.5rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 0.5rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 0.5rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 0.5rem !important; }
  .m-sm-4 {
    margin: 0.75rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 0.75rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 0.75rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 0.75rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 0.75rem !important; }
  .m-sm-5 {
    margin: 1rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 1rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 1rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 1rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 1rem !important; }
  .m-sm-6 {
    margin: 1.25rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 1.25rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 1.25rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 1.25rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 1.25rem !important; }
  .m-sm-7 {
    margin: 1.5rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 1.5rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 1.5rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 1.5rem !important; }
  .m-sm-8 {
    margin: 2rem !important; }
  .mt-sm-8,
  .my-sm-8 {
    margin-top: 2rem !important; }
  .mr-sm-8,
  .mx-sm-8 {
    margin-right: 2rem !important; }
  .mb-sm-8,
  .my-sm-8 {
    margin-bottom: 2rem !important; }
  .ml-sm-8,
  .mx-sm-8 {
    margin-left: 2rem !important; }
  .m-sm-9 {
    margin: 2.5rem !important; }
  .mt-sm-9,
  .my-sm-9 {
    margin-top: 2.5rem !important; }
  .mr-sm-9,
  .mx-sm-9 {
    margin-right: 2.5rem !important; }
  .mb-sm-9,
  .my-sm-9 {
    margin-bottom: 2.5rem !important; }
  .ml-sm-9,
  .mx-sm-9 {
    margin-left: 2.5rem !important; }
  .m-sm-10 {
    margin: 3.5rem !important; }
  .mt-sm-10,
  .my-sm-10 {
    margin-top: 3.5rem !important; }
  .mr-sm-10,
  .mx-sm-10 {
    margin-right: 3.5rem !important; }
  .mb-sm-10,
  .my-sm-10 {
    margin-bottom: 3.5rem !important; }
  .ml-sm-10,
  .mx-sm-10 {
    margin-left: 3.5rem !important; }
  .m-sm-11 {
    margin: 5.5rem !important; }
  .mt-sm-11,
  .my-sm-11 {
    margin-top: 5.5rem !important; }
  .mr-sm-11,
  .mx-sm-11 {
    margin-right: 5.5rem !important; }
  .mb-sm-11,
  .my-sm-11 {
    margin-bottom: 5.5rem !important; }
  .ml-sm-11,
  .mx-sm-11 {
    margin-left: 5.5rem !important; }
  .m-sm-12 {
    margin: 8rem !important; }
  .mt-sm-12,
  .my-sm-12 {
    margin-top: 8rem !important; }
  .mr-sm-12,
  .mx-sm-12 {
    margin-right: 8rem !important; }
  .mb-sm-12,
  .my-sm-12 {
    margin-bottom: 8rem !important; }
  .ml-sm-12,
  .mx-sm-12 {
    margin-left: 8rem !important; }
  .m-sm-13 {
    margin: 10rem !important; }
  .mt-sm-13,
  .my-sm-13 {
    margin-top: 10rem !important; }
  .mr-sm-13,
  .mx-sm-13 {
    margin-right: 10rem !important; }
  .mb-sm-13,
  .my-sm-13 {
    margin-bottom: 10rem !important; }
  .ml-sm-13,
  .mx-sm-13 {
    margin-left: 10rem !important; }
  .m-sm-14 {
    margin: 12rem !important; }
  .mt-sm-14,
  .my-sm-14 {
    margin-top: 12rem !important; }
  .mr-sm-14,
  .mx-sm-14 {
    margin-right: 12rem !important; }
  .mb-sm-14,
  .my-sm-14 {
    margin-bottom: 12rem !important; }
  .ml-sm-14,
  .mx-sm-14 {
    margin-left: 12rem !important; }
  .m-sm-15 {
    margin: 15rem !important; }
  .mt-sm-15,
  .my-sm-15 {
    margin-top: 15rem !important; }
  .mr-sm-15,
  .mx-sm-15 {
    margin-right: 15rem !important; }
  .mb-sm-15,
  .my-sm-15 {
    margin-bottom: 15rem !important; }
  .ml-sm-15,
  .mx-sm-15 {
    margin-left: 15rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.125rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.125rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.125rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.125rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.125rem !important; }
  .p-sm-2 {
    padding: 0.25rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.25rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.25rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.25rem !important; }
  .p-sm-3 {
    padding: 0.5rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 0.5rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 0.5rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 0.5rem !important; }
  .p-sm-4 {
    padding: 0.75rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 0.75rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 0.75rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 0.75rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 0.75rem !important; }
  .p-sm-5 {
    padding: 1rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 1rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 1rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 1rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 1rem !important; }
  .p-sm-6 {
    padding: 1.25rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 1.25rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 1.25rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 1.25rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 1.25rem !important; }
  .p-sm-7 {
    padding: 1.5rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 1.5rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 1.5rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 1.5rem !important; }
  .p-sm-8 {
    padding: 2rem !important; }
  .pt-sm-8,
  .py-sm-8 {
    padding-top: 2rem !important; }
  .pr-sm-8,
  .px-sm-8 {
    padding-right: 2rem !important; }
  .pb-sm-8,
  .py-sm-8 {
    padding-bottom: 2rem !important; }
  .pl-sm-8,
  .px-sm-8 {
    padding-left: 2rem !important; }
  .p-sm-9 {
    padding: 2.5rem !important; }
  .pt-sm-9,
  .py-sm-9 {
    padding-top: 2.5rem !important; }
  .pr-sm-9,
  .px-sm-9 {
    padding-right: 2.5rem !important; }
  .pb-sm-9,
  .py-sm-9 {
    padding-bottom: 2.5rem !important; }
  .pl-sm-9,
  .px-sm-9 {
    padding-left: 2.5rem !important; }
  .p-sm-10 {
    padding: 3.5rem !important; }
  .pt-sm-10,
  .py-sm-10 {
    padding-top: 3.5rem !important; }
  .pr-sm-10,
  .px-sm-10 {
    padding-right: 3.5rem !important; }
  .pb-sm-10,
  .py-sm-10 {
    padding-bottom: 3.5rem !important; }
  .pl-sm-10,
  .px-sm-10 {
    padding-left: 3.5rem !important; }
  .p-sm-11 {
    padding: 5.5rem !important; }
  .pt-sm-11,
  .py-sm-11 {
    padding-top: 5.5rem !important; }
  .pr-sm-11,
  .px-sm-11 {
    padding-right: 5.5rem !important; }
  .pb-sm-11,
  .py-sm-11 {
    padding-bottom: 5.5rem !important; }
  .pl-sm-11,
  .px-sm-11 {
    padding-left: 5.5rem !important; }
  .p-sm-12 {
    padding: 8rem !important; }
  .pt-sm-12,
  .py-sm-12 {
    padding-top: 8rem !important; }
  .pr-sm-12,
  .px-sm-12 {
    padding-right: 8rem !important; }
  .pb-sm-12,
  .py-sm-12 {
    padding-bottom: 8rem !important; }
  .pl-sm-12,
  .px-sm-12 {
    padding-left: 8rem !important; }
  .p-sm-13 {
    padding: 10rem !important; }
  .pt-sm-13,
  .py-sm-13 {
    padding-top: 10rem !important; }
  .pr-sm-13,
  .px-sm-13 {
    padding-right: 10rem !important; }
  .pb-sm-13,
  .py-sm-13 {
    padding-bottom: 10rem !important; }
  .pl-sm-13,
  .px-sm-13 {
    padding-left: 10rem !important; }
  .p-sm-14 {
    padding: 12rem !important; }
  .pt-sm-14,
  .py-sm-14 {
    padding-top: 12rem !important; }
  .pr-sm-14,
  .px-sm-14 {
    padding-right: 12rem !important; }
  .pb-sm-14,
  .py-sm-14 {
    padding-bottom: 12rem !important; }
  .pl-sm-14,
  .px-sm-14 {
    padding-left: 12rem !important; }
  .p-sm-15 {
    padding: 15rem !important; }
  .pt-sm-15,
  .py-sm-15 {
    padding-top: 15rem !important; }
  .pr-sm-15,
  .px-sm-15 {
    padding-right: 15rem !important; }
  .pb-sm-15,
  .py-sm-15 {
    padding-bottom: 15rem !important; }
  .pl-sm-15,
  .px-sm-15 {
    padding-left: 15rem !important; }
  .m-sm-n1 {
    margin: -0.125rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.125rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.125rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.125rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.125rem !important; }
  .m-sm-n2 {
    margin: -0.25rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.25rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.25rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.25rem !important; }
  .m-sm-n3 {
    margin: -0.5rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -0.5rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -0.5rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -0.5rem !important; }
  .m-sm-n4 {
    margin: -0.75rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -0.75rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -0.75rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -0.75rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -0.75rem !important; }
  .m-sm-n5 {
    margin: -1rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -1rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -1rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -1rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -1rem !important; }
  .m-sm-n6 {
    margin: -1.25rem !important; }
  .mt-sm-n6,
  .my-sm-n6 {
    margin-top: -1.25rem !important; }
  .mr-sm-n6,
  .mx-sm-n6 {
    margin-right: -1.25rem !important; }
  .mb-sm-n6,
  .my-sm-n6 {
    margin-bottom: -1.25rem !important; }
  .ml-sm-n6,
  .mx-sm-n6 {
    margin-left: -1.25rem !important; }
  .m-sm-n7 {
    margin: -1.5rem !important; }
  .mt-sm-n7,
  .my-sm-n7 {
    margin-top: -1.5rem !important; }
  .mr-sm-n7,
  .mx-sm-n7 {
    margin-right: -1.5rem !important; }
  .mb-sm-n7,
  .my-sm-n7 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n7,
  .mx-sm-n7 {
    margin-left: -1.5rem !important; }
  .m-sm-n8 {
    margin: -2rem !important; }
  .mt-sm-n8,
  .my-sm-n8 {
    margin-top: -2rem !important; }
  .mr-sm-n8,
  .mx-sm-n8 {
    margin-right: -2rem !important; }
  .mb-sm-n8,
  .my-sm-n8 {
    margin-bottom: -2rem !important; }
  .ml-sm-n8,
  .mx-sm-n8 {
    margin-left: -2rem !important; }
  .m-sm-n9 {
    margin: -2.5rem !important; }
  .mt-sm-n9,
  .my-sm-n9 {
    margin-top: -2.5rem !important; }
  .mr-sm-n9,
  .mx-sm-n9 {
    margin-right: -2.5rem !important; }
  .mb-sm-n9,
  .my-sm-n9 {
    margin-bottom: -2.5rem !important; }
  .ml-sm-n9,
  .mx-sm-n9 {
    margin-left: -2.5rem !important; }
  .m-sm-n10 {
    margin: -3.5rem !important; }
  .mt-sm-n10,
  .my-sm-n10 {
    margin-top: -3.5rem !important; }
  .mr-sm-n10,
  .mx-sm-n10 {
    margin-right: -3.5rem !important; }
  .mb-sm-n10,
  .my-sm-n10 {
    margin-bottom: -3.5rem !important; }
  .ml-sm-n10,
  .mx-sm-n10 {
    margin-left: -3.5rem !important; }
  .m-sm-n11 {
    margin: -5.5rem !important; }
  .mt-sm-n11,
  .my-sm-n11 {
    margin-top: -5.5rem !important; }
  .mr-sm-n11,
  .mx-sm-n11 {
    margin-right: -5.5rem !important; }
  .mb-sm-n11,
  .my-sm-n11 {
    margin-bottom: -5.5rem !important; }
  .ml-sm-n11,
  .mx-sm-n11 {
    margin-left: -5.5rem !important; }
  .m-sm-n12 {
    margin: -8rem !important; }
  .mt-sm-n12,
  .my-sm-n12 {
    margin-top: -8rem !important; }
  .mr-sm-n12,
  .mx-sm-n12 {
    margin-right: -8rem !important; }
  .mb-sm-n12,
  .my-sm-n12 {
    margin-bottom: -8rem !important; }
  .ml-sm-n12,
  .mx-sm-n12 {
    margin-left: -8rem !important; }
  .m-sm-n13 {
    margin: -10rem !important; }
  .mt-sm-n13,
  .my-sm-n13 {
    margin-top: -10rem !important; }
  .mr-sm-n13,
  .mx-sm-n13 {
    margin-right: -10rem !important; }
  .mb-sm-n13,
  .my-sm-n13 {
    margin-bottom: -10rem !important; }
  .ml-sm-n13,
  .mx-sm-n13 {
    margin-left: -10rem !important; }
  .m-sm-n14 {
    margin: -12rem !important; }
  .mt-sm-n14,
  .my-sm-n14 {
    margin-top: -12rem !important; }
  .mr-sm-n14,
  .mx-sm-n14 {
    margin-right: -12rem !important; }
  .mb-sm-n14,
  .my-sm-n14 {
    margin-bottom: -12rem !important; }
  .ml-sm-n14,
  .mx-sm-n14 {
    margin-left: -12rem !important; }
  .m-sm-n15 {
    margin: -15rem !important; }
  .mt-sm-n15,
  .my-sm-n15 {
    margin-top: -15rem !important; }
  .mr-sm-n15,
  .mx-sm-n15 {
    margin-right: -15rem !important; }
  .mb-sm-n15,
  .my-sm-n15 {
    margin-bottom: -15rem !important; }
  .ml-sm-n15,
  .mx-sm-n15 {
    margin-left: -15rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.125rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.125rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.125rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.125rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.125rem !important; }
  .m-md-2 {
    margin: 0.25rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.25rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.25rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.25rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.25rem !important; }
  .m-md-3 {
    margin: 0.5rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 0.5rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 0.5rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 0.5rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 0.5rem !important; }
  .m-md-4 {
    margin: 0.75rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 0.75rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 0.75rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 0.75rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 0.75rem !important; }
  .m-md-5 {
    margin: 1rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 1rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 1rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 1rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 1rem !important; }
  .m-md-6 {
    margin: 1.25rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 1.25rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 1.25rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 1.25rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 1.25rem !important; }
  .m-md-7 {
    margin: 1.5rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 1.5rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 1.5rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 1.5rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 1.5rem !important; }
  .m-md-8 {
    margin: 2rem !important; }
  .mt-md-8,
  .my-md-8 {
    margin-top: 2rem !important; }
  .mr-md-8,
  .mx-md-8 {
    margin-right: 2rem !important; }
  .mb-md-8,
  .my-md-8 {
    margin-bottom: 2rem !important; }
  .ml-md-8,
  .mx-md-8 {
    margin-left: 2rem !important; }
  .m-md-9 {
    margin: 2.5rem !important; }
  .mt-md-9,
  .my-md-9 {
    margin-top: 2.5rem !important; }
  .mr-md-9,
  .mx-md-9 {
    margin-right: 2.5rem !important; }
  .mb-md-9,
  .my-md-9 {
    margin-bottom: 2.5rem !important; }
  .ml-md-9,
  .mx-md-9 {
    margin-left: 2.5rem !important; }
  .m-md-10 {
    margin: 3.5rem !important; }
  .mt-md-10,
  .my-md-10 {
    margin-top: 3.5rem !important; }
  .mr-md-10,
  .mx-md-10 {
    margin-right: 3.5rem !important; }
  .mb-md-10,
  .my-md-10 {
    margin-bottom: 3.5rem !important; }
  .ml-md-10,
  .mx-md-10 {
    margin-left: 3.5rem !important; }
  .m-md-11 {
    margin: 5.5rem !important; }
  .mt-md-11,
  .my-md-11 {
    margin-top: 5.5rem !important; }
  .mr-md-11,
  .mx-md-11 {
    margin-right: 5.5rem !important; }
  .mb-md-11,
  .my-md-11 {
    margin-bottom: 5.5rem !important; }
  .ml-md-11,
  .mx-md-11 {
    margin-left: 5.5rem !important; }
  .m-md-12 {
    margin: 8rem !important; }
  .mt-md-12,
  .my-md-12 {
    margin-top: 8rem !important; }
  .mr-md-12,
  .mx-md-12 {
    margin-right: 8rem !important; }
  .mb-md-12,
  .my-md-12 {
    margin-bottom: 8rem !important; }
  .ml-md-12,
  .mx-md-12 {
    margin-left: 8rem !important; }
  .m-md-13 {
    margin: 10rem !important; }
  .mt-md-13,
  .my-md-13 {
    margin-top: 10rem !important; }
  .mr-md-13,
  .mx-md-13 {
    margin-right: 10rem !important; }
  .mb-md-13,
  .my-md-13 {
    margin-bottom: 10rem !important; }
  .ml-md-13,
  .mx-md-13 {
    margin-left: 10rem !important; }
  .m-md-14 {
    margin: 12rem !important; }
  .mt-md-14,
  .my-md-14 {
    margin-top: 12rem !important; }
  .mr-md-14,
  .mx-md-14 {
    margin-right: 12rem !important; }
  .mb-md-14,
  .my-md-14 {
    margin-bottom: 12rem !important; }
  .ml-md-14,
  .mx-md-14 {
    margin-left: 12rem !important; }
  .m-md-15 {
    margin: 15rem !important; }
  .mt-md-15,
  .my-md-15 {
    margin-top: 15rem !important; }
  .mr-md-15,
  .mx-md-15 {
    margin-right: 15rem !important; }
  .mb-md-15,
  .my-md-15 {
    margin-bottom: 15rem !important; }
  .ml-md-15,
  .mx-md-15 {
    margin-left: 15rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.125rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.125rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.125rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.125rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.125rem !important; }
  .p-md-2 {
    padding: 0.25rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.25rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.25rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.25rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.25rem !important; }
  .p-md-3 {
    padding: 0.5rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 0.5rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 0.5rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 0.5rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 0.5rem !important; }
  .p-md-4 {
    padding: 0.75rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 0.75rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 0.75rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 0.75rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 0.75rem !important; }
  .p-md-5 {
    padding: 1rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 1rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 1rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 1rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 1rem !important; }
  .p-md-6 {
    padding: 1.25rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 1.25rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 1.25rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 1.25rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 1.25rem !important; }
  .p-md-7 {
    padding: 1.5rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 1.5rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 1.5rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 1.5rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 1.5rem !important; }
  .p-md-8 {
    padding: 2rem !important; }
  .pt-md-8,
  .py-md-8 {
    padding-top: 2rem !important; }
  .pr-md-8,
  .px-md-8 {
    padding-right: 2rem !important; }
  .pb-md-8,
  .py-md-8 {
    padding-bottom: 2rem !important; }
  .pl-md-8,
  .px-md-8 {
    padding-left: 2rem !important; }
  .p-md-9 {
    padding: 2.5rem !important; }
  .pt-md-9,
  .py-md-9 {
    padding-top: 2.5rem !important; }
  .pr-md-9,
  .px-md-9 {
    padding-right: 2.5rem !important; }
  .pb-md-9,
  .py-md-9 {
    padding-bottom: 2.5rem !important; }
  .pl-md-9,
  .px-md-9 {
    padding-left: 2.5rem !important; }
  .p-md-10 {
    padding: 3.5rem !important; }
  .pt-md-10,
  .py-md-10 {
    padding-top: 3.5rem !important; }
  .pr-md-10,
  .px-md-10 {
    padding-right: 3.5rem !important; }
  .pb-md-10,
  .py-md-10 {
    padding-bottom: 3.5rem !important; }
  .pl-md-10,
  .px-md-10 {
    padding-left: 3.5rem !important; }
  .p-md-11 {
    padding: 5.5rem !important; }
  .pt-md-11,
  .py-md-11 {
    padding-top: 5.5rem !important; }
  .pr-md-11,
  .px-md-11 {
    padding-right: 5.5rem !important; }
  .pb-md-11,
  .py-md-11 {
    padding-bottom: 5.5rem !important; }
  .pl-md-11,
  .px-md-11 {
    padding-left: 5.5rem !important; }
  .p-md-12 {
    padding: 8rem !important; }
  .pt-md-12,
  .py-md-12 {
    padding-top: 8rem !important; }
  .pr-md-12,
  .px-md-12 {
    padding-right: 8rem !important; }
  .pb-md-12,
  .py-md-12 {
    padding-bottom: 8rem !important; }
  .pl-md-12,
  .px-md-12 {
    padding-left: 8rem !important; }
  .p-md-13 {
    padding: 10rem !important; }
  .pt-md-13,
  .py-md-13 {
    padding-top: 10rem !important; }
  .pr-md-13,
  .px-md-13 {
    padding-right: 10rem !important; }
  .pb-md-13,
  .py-md-13 {
    padding-bottom: 10rem !important; }
  .pl-md-13,
  .px-md-13 {
    padding-left: 10rem !important; }
  .p-md-14 {
    padding: 12rem !important; }
  .pt-md-14,
  .py-md-14 {
    padding-top: 12rem !important; }
  .pr-md-14,
  .px-md-14 {
    padding-right: 12rem !important; }
  .pb-md-14,
  .py-md-14 {
    padding-bottom: 12rem !important; }
  .pl-md-14,
  .px-md-14 {
    padding-left: 12rem !important; }
  .p-md-15 {
    padding: 15rem !important; }
  .pt-md-15,
  .py-md-15 {
    padding-top: 15rem !important; }
  .pr-md-15,
  .px-md-15 {
    padding-right: 15rem !important; }
  .pb-md-15,
  .py-md-15 {
    padding-bottom: 15rem !important; }
  .pl-md-15,
  .px-md-15 {
    padding-left: 15rem !important; }
  .m-md-n1 {
    margin: -0.125rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.125rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.125rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.125rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.125rem !important; }
  .m-md-n2 {
    margin: -0.25rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.25rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.25rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.25rem !important; }
  .m-md-n3 {
    margin: -0.5rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -0.5rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -0.5rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -0.5rem !important; }
  .m-md-n4 {
    margin: -0.75rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -0.75rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -0.75rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -0.75rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -0.75rem !important; }
  .m-md-n5 {
    margin: -1rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -1rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -1rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -1rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -1rem !important; }
  .m-md-n6 {
    margin: -1.25rem !important; }
  .mt-md-n6,
  .my-md-n6 {
    margin-top: -1.25rem !important; }
  .mr-md-n6,
  .mx-md-n6 {
    margin-right: -1.25rem !important; }
  .mb-md-n6,
  .my-md-n6 {
    margin-bottom: -1.25rem !important; }
  .ml-md-n6,
  .mx-md-n6 {
    margin-left: -1.25rem !important; }
  .m-md-n7 {
    margin: -1.5rem !important; }
  .mt-md-n7,
  .my-md-n7 {
    margin-top: -1.5rem !important; }
  .mr-md-n7,
  .mx-md-n7 {
    margin-right: -1.5rem !important; }
  .mb-md-n7,
  .my-md-n7 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n7,
  .mx-md-n7 {
    margin-left: -1.5rem !important; }
  .m-md-n8 {
    margin: -2rem !important; }
  .mt-md-n8,
  .my-md-n8 {
    margin-top: -2rem !important; }
  .mr-md-n8,
  .mx-md-n8 {
    margin-right: -2rem !important; }
  .mb-md-n8,
  .my-md-n8 {
    margin-bottom: -2rem !important; }
  .ml-md-n8,
  .mx-md-n8 {
    margin-left: -2rem !important; }
  .m-md-n9 {
    margin: -2.5rem !important; }
  .mt-md-n9,
  .my-md-n9 {
    margin-top: -2.5rem !important; }
  .mr-md-n9,
  .mx-md-n9 {
    margin-right: -2.5rem !important; }
  .mb-md-n9,
  .my-md-n9 {
    margin-bottom: -2.5rem !important; }
  .ml-md-n9,
  .mx-md-n9 {
    margin-left: -2.5rem !important; }
  .m-md-n10 {
    margin: -3.5rem !important; }
  .mt-md-n10,
  .my-md-n10 {
    margin-top: -3.5rem !important; }
  .mr-md-n10,
  .mx-md-n10 {
    margin-right: -3.5rem !important; }
  .mb-md-n10,
  .my-md-n10 {
    margin-bottom: -3.5rem !important; }
  .ml-md-n10,
  .mx-md-n10 {
    margin-left: -3.5rem !important; }
  .m-md-n11 {
    margin: -5.5rem !important; }
  .mt-md-n11,
  .my-md-n11 {
    margin-top: -5.5rem !important; }
  .mr-md-n11,
  .mx-md-n11 {
    margin-right: -5.5rem !important; }
  .mb-md-n11,
  .my-md-n11 {
    margin-bottom: -5.5rem !important; }
  .ml-md-n11,
  .mx-md-n11 {
    margin-left: -5.5rem !important; }
  .m-md-n12 {
    margin: -8rem !important; }
  .mt-md-n12,
  .my-md-n12 {
    margin-top: -8rem !important; }
  .mr-md-n12,
  .mx-md-n12 {
    margin-right: -8rem !important; }
  .mb-md-n12,
  .my-md-n12 {
    margin-bottom: -8rem !important; }
  .ml-md-n12,
  .mx-md-n12 {
    margin-left: -8rem !important; }
  .m-md-n13 {
    margin: -10rem !important; }
  .mt-md-n13,
  .my-md-n13 {
    margin-top: -10rem !important; }
  .mr-md-n13,
  .mx-md-n13 {
    margin-right: -10rem !important; }
  .mb-md-n13,
  .my-md-n13 {
    margin-bottom: -10rem !important; }
  .ml-md-n13,
  .mx-md-n13 {
    margin-left: -10rem !important; }
  .m-md-n14 {
    margin: -12rem !important; }
  .mt-md-n14,
  .my-md-n14 {
    margin-top: -12rem !important; }
  .mr-md-n14,
  .mx-md-n14 {
    margin-right: -12rem !important; }
  .mb-md-n14,
  .my-md-n14 {
    margin-bottom: -12rem !important; }
  .ml-md-n14,
  .mx-md-n14 {
    margin-left: -12rem !important; }
  .m-md-n15 {
    margin: -15rem !important; }
  .mt-md-n15,
  .my-md-n15 {
    margin-top: -15rem !important; }
  .mr-md-n15,
  .mx-md-n15 {
    margin-right: -15rem !important; }
  .mb-md-n15,
  .my-md-n15 {
    margin-bottom: -15rem !important; }
  .ml-md-n15,
  .mx-md-n15 {
    margin-left: -15rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.125rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.125rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.125rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.125rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.125rem !important; }
  .m-lg-2 {
    margin: 0.25rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.25rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.25rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.25rem !important; }
  .m-lg-3 {
    margin: 0.5rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 0.5rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 0.5rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 0.5rem !important; }
  .m-lg-4 {
    margin: 0.75rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 0.75rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 0.75rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 0.75rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 0.75rem !important; }
  .m-lg-5 {
    margin: 1rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 1rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 1rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 1rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 1rem !important; }
  .m-lg-6 {
    margin: 1.25rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 1.25rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 1.25rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 1.25rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 1.25rem !important; }
  .m-lg-7 {
    margin: 1.5rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 1.5rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 1.5rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 1.5rem !important; }
  .m-lg-8 {
    margin: 2rem !important; }
  .mt-lg-8,
  .my-lg-8 {
    margin-top: 2rem !important; }
  .mr-lg-8,
  .mx-lg-8 {
    margin-right: 2rem !important; }
  .mb-lg-8,
  .my-lg-8 {
    margin-bottom: 2rem !important; }
  .ml-lg-8,
  .mx-lg-8 {
    margin-left: 2rem !important; }
  .m-lg-9 {
    margin: 2.5rem !important; }
  .mt-lg-9,
  .my-lg-9 {
    margin-top: 2.5rem !important; }
  .mr-lg-9,
  .mx-lg-9 {
    margin-right: 2.5rem !important; }
  .mb-lg-9,
  .my-lg-9 {
    margin-bottom: 2.5rem !important; }
  .ml-lg-9,
  .mx-lg-9 {
    margin-left: 2.5rem !important; }
  .m-lg-10 {
    margin: 3.5rem !important; }
  .mt-lg-10,
  .my-lg-10 {
    margin-top: 3.5rem !important; }
  .mr-lg-10,
  .mx-lg-10 {
    margin-right: 3.5rem !important; }
  .mb-lg-10,
  .my-lg-10 {
    margin-bottom: 3.5rem !important; }
  .ml-lg-10,
  .mx-lg-10 {
    margin-left: 3.5rem !important; }
  .m-lg-11 {
    margin: 5.5rem !important; }
  .mt-lg-11,
  .my-lg-11 {
    margin-top: 5.5rem !important; }
  .mr-lg-11,
  .mx-lg-11 {
    margin-right: 5.5rem !important; }
  .mb-lg-11,
  .my-lg-11 {
    margin-bottom: 5.5rem !important; }
  .ml-lg-11,
  .mx-lg-11 {
    margin-left: 5.5rem !important; }
  .m-lg-12 {
    margin: 8rem !important; }
  .mt-lg-12,
  .my-lg-12 {
    margin-top: 8rem !important; }
  .mr-lg-12,
  .mx-lg-12 {
    margin-right: 8rem !important; }
  .mb-lg-12,
  .my-lg-12 {
    margin-bottom: 8rem !important; }
  .ml-lg-12,
  .mx-lg-12 {
    margin-left: 8rem !important; }
  .m-lg-13 {
    margin: 10rem !important; }
  .mt-lg-13,
  .my-lg-13 {
    margin-top: 10rem !important; }
  .mr-lg-13,
  .mx-lg-13 {
    margin-right: 10rem !important; }
  .mb-lg-13,
  .my-lg-13 {
    margin-bottom: 10rem !important; }
  .ml-lg-13,
  .mx-lg-13 {
    margin-left: 10rem !important; }
  .m-lg-14 {
    margin: 12rem !important; }
  .mt-lg-14,
  .my-lg-14 {
    margin-top: 12rem !important; }
  .mr-lg-14,
  .mx-lg-14 {
    margin-right: 12rem !important; }
  .mb-lg-14,
  .my-lg-14 {
    margin-bottom: 12rem !important; }
  .ml-lg-14,
  .mx-lg-14 {
    margin-left: 12rem !important; }
  .m-lg-15 {
    margin: 15rem !important; }
  .mt-lg-15,
  .my-lg-15 {
    margin-top: 15rem !important; }
  .mr-lg-15,
  .mx-lg-15 {
    margin-right: 15rem !important; }
  .mb-lg-15,
  .my-lg-15 {
    margin-bottom: 15rem !important; }
  .ml-lg-15,
  .mx-lg-15 {
    margin-left: 15rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.125rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.125rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.125rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.125rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.125rem !important; }
  .p-lg-2 {
    padding: 0.25rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.25rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.25rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.25rem !important; }
  .p-lg-3 {
    padding: 0.5rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 0.5rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 0.5rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 0.5rem !important; }
  .p-lg-4 {
    padding: 0.75rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 0.75rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 0.75rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 0.75rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 0.75rem !important; }
  .p-lg-5 {
    padding: 1rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 1rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 1rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 1rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 1rem !important; }
  .p-lg-6 {
    padding: 1.25rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 1.25rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 1.25rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 1.25rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 1.25rem !important; }
  .p-lg-7 {
    padding: 1.5rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 1.5rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 1.5rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 1.5rem !important; }
  .p-lg-8 {
    padding: 2rem !important; }
  .pt-lg-8,
  .py-lg-8 {
    padding-top: 2rem !important; }
  .pr-lg-8,
  .px-lg-8 {
    padding-right: 2rem !important; }
  .pb-lg-8,
  .py-lg-8 {
    padding-bottom: 2rem !important; }
  .pl-lg-8,
  .px-lg-8 {
    padding-left: 2rem !important; }
  .p-lg-9 {
    padding: 2.5rem !important; }
  .pt-lg-9,
  .py-lg-9 {
    padding-top: 2.5rem !important; }
  .pr-lg-9,
  .px-lg-9 {
    padding-right: 2.5rem !important; }
  .pb-lg-9,
  .py-lg-9 {
    padding-bottom: 2.5rem !important; }
  .pl-lg-9,
  .px-lg-9 {
    padding-left: 2.5rem !important; }
  .p-lg-10 {
    padding: 3.5rem !important; }
  .pt-lg-10,
  .py-lg-10 {
    padding-top: 3.5rem !important; }
  .pr-lg-10,
  .px-lg-10 {
    padding-right: 3.5rem !important; }
  .pb-lg-10,
  .py-lg-10 {
    padding-bottom: 3.5rem !important; }
  .pl-lg-10,
  .px-lg-10 {
    padding-left: 3.5rem !important; }
  .p-lg-11 {
    padding: 5.5rem !important; }
  .pt-lg-11,
  .py-lg-11 {
    padding-top: 5.5rem !important; }
  .pr-lg-11,
  .px-lg-11 {
    padding-right: 5.5rem !important; }
  .pb-lg-11,
  .py-lg-11 {
    padding-bottom: 5.5rem !important; }
  .pl-lg-11,
  .px-lg-11 {
    padding-left: 5.5rem !important; }
  .p-lg-12 {
    padding: 8rem !important; }
  .pt-lg-12,
  .py-lg-12 {
    padding-top: 8rem !important; }
  .pr-lg-12,
  .px-lg-12 {
    padding-right: 8rem !important; }
  .pb-lg-12,
  .py-lg-12 {
    padding-bottom: 8rem !important; }
  .pl-lg-12,
  .px-lg-12 {
    padding-left: 8rem !important; }
  .p-lg-13 {
    padding: 10rem !important; }
  .pt-lg-13,
  .py-lg-13 {
    padding-top: 10rem !important; }
  .pr-lg-13,
  .px-lg-13 {
    padding-right: 10rem !important; }
  .pb-lg-13,
  .py-lg-13 {
    padding-bottom: 10rem !important; }
  .pl-lg-13,
  .px-lg-13 {
    padding-left: 10rem !important; }
  .p-lg-14 {
    padding: 12rem !important; }
  .pt-lg-14,
  .py-lg-14 {
    padding-top: 12rem !important; }
  .pr-lg-14,
  .px-lg-14 {
    padding-right: 12rem !important; }
  .pb-lg-14,
  .py-lg-14 {
    padding-bottom: 12rem !important; }
  .pl-lg-14,
  .px-lg-14 {
    padding-left: 12rem !important; }
  .p-lg-15 {
    padding: 15rem !important; }
  .pt-lg-15,
  .py-lg-15 {
    padding-top: 15rem !important; }
  .pr-lg-15,
  .px-lg-15 {
    padding-right: 15rem !important; }
  .pb-lg-15,
  .py-lg-15 {
    padding-bottom: 15rem !important; }
  .pl-lg-15,
  .px-lg-15 {
    padding-left: 15rem !important; }
  .m-lg-n1 {
    margin: -0.125rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.125rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.125rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.125rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.125rem !important; }
  .m-lg-n2 {
    margin: -0.25rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.25rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.25rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.25rem !important; }
  .m-lg-n3 {
    margin: -0.5rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -0.5rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -0.5rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -0.5rem !important; }
  .m-lg-n4 {
    margin: -0.75rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -0.75rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -0.75rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -0.75rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -0.75rem !important; }
  .m-lg-n5 {
    margin: -1rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -1rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -1rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -1rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -1rem !important; }
  .m-lg-n6 {
    margin: -1.25rem !important; }
  .mt-lg-n6,
  .my-lg-n6 {
    margin-top: -1.25rem !important; }
  .mr-lg-n6,
  .mx-lg-n6 {
    margin-right: -1.25rem !important; }
  .mb-lg-n6,
  .my-lg-n6 {
    margin-bottom: -1.25rem !important; }
  .ml-lg-n6,
  .mx-lg-n6 {
    margin-left: -1.25rem !important; }
  .m-lg-n7 {
    margin: -1.5rem !important; }
  .mt-lg-n7,
  .my-lg-n7 {
    margin-top: -1.5rem !important; }
  .mr-lg-n7,
  .mx-lg-n7 {
    margin-right: -1.5rem !important; }
  .mb-lg-n7,
  .my-lg-n7 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n7,
  .mx-lg-n7 {
    margin-left: -1.5rem !important; }
  .m-lg-n8 {
    margin: -2rem !important; }
  .mt-lg-n8,
  .my-lg-n8 {
    margin-top: -2rem !important; }
  .mr-lg-n8,
  .mx-lg-n8 {
    margin-right: -2rem !important; }
  .mb-lg-n8,
  .my-lg-n8 {
    margin-bottom: -2rem !important; }
  .ml-lg-n8,
  .mx-lg-n8 {
    margin-left: -2rem !important; }
  .m-lg-n9 {
    margin: -2.5rem !important; }
  .mt-lg-n9,
  .my-lg-n9 {
    margin-top: -2.5rem !important; }
  .mr-lg-n9,
  .mx-lg-n9 {
    margin-right: -2.5rem !important; }
  .mb-lg-n9,
  .my-lg-n9 {
    margin-bottom: -2.5rem !important; }
  .ml-lg-n9,
  .mx-lg-n9 {
    margin-left: -2.5rem !important; }
  .m-lg-n10 {
    margin: -3.5rem !important; }
  .mt-lg-n10,
  .my-lg-n10 {
    margin-top: -3.5rem !important; }
  .mr-lg-n10,
  .mx-lg-n10 {
    margin-right: -3.5rem !important; }
  .mb-lg-n10,
  .my-lg-n10 {
    margin-bottom: -3.5rem !important; }
  .ml-lg-n10,
  .mx-lg-n10 {
    margin-left: -3.5rem !important; }
  .m-lg-n11 {
    margin: -5.5rem !important; }
  .mt-lg-n11,
  .my-lg-n11 {
    margin-top: -5.5rem !important; }
  .mr-lg-n11,
  .mx-lg-n11 {
    margin-right: -5.5rem !important; }
  .mb-lg-n11,
  .my-lg-n11 {
    margin-bottom: -5.5rem !important; }
  .ml-lg-n11,
  .mx-lg-n11 {
    margin-left: -5.5rem !important; }
  .m-lg-n12 {
    margin: -8rem !important; }
  .mt-lg-n12,
  .my-lg-n12 {
    margin-top: -8rem !important; }
  .mr-lg-n12,
  .mx-lg-n12 {
    margin-right: -8rem !important; }
  .mb-lg-n12,
  .my-lg-n12 {
    margin-bottom: -8rem !important; }
  .ml-lg-n12,
  .mx-lg-n12 {
    margin-left: -8rem !important; }
  .m-lg-n13 {
    margin: -10rem !important; }
  .mt-lg-n13,
  .my-lg-n13 {
    margin-top: -10rem !important; }
  .mr-lg-n13,
  .mx-lg-n13 {
    margin-right: -10rem !important; }
  .mb-lg-n13,
  .my-lg-n13 {
    margin-bottom: -10rem !important; }
  .ml-lg-n13,
  .mx-lg-n13 {
    margin-left: -10rem !important; }
  .m-lg-n14 {
    margin: -12rem !important; }
  .mt-lg-n14,
  .my-lg-n14 {
    margin-top: -12rem !important; }
  .mr-lg-n14,
  .mx-lg-n14 {
    margin-right: -12rem !important; }
  .mb-lg-n14,
  .my-lg-n14 {
    margin-bottom: -12rem !important; }
  .ml-lg-n14,
  .mx-lg-n14 {
    margin-left: -12rem !important; }
  .m-lg-n15 {
    margin: -15rem !important; }
  .mt-lg-n15,
  .my-lg-n15 {
    margin-top: -15rem !important; }
  .mr-lg-n15,
  .mx-lg-n15 {
    margin-right: -15rem !important; }
  .mb-lg-n15,
  .my-lg-n15 {
    margin-bottom: -15rem !important; }
  .ml-lg-n15,
  .mx-lg-n15 {
    margin-left: -15rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.125rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.125rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.125rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.125rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.125rem !important; }
  .m-xl-2 {
    margin: 0.25rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.25rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.25rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.25rem !important; }
  .m-xl-3 {
    margin: 0.5rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 0.5rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 0.5rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 0.5rem !important; }
  .m-xl-4 {
    margin: 0.75rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 0.75rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 0.75rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 0.75rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 0.75rem !important; }
  .m-xl-5 {
    margin: 1rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 1rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 1rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 1rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 1rem !important; }
  .m-xl-6 {
    margin: 1.25rem !important; }
  .mt-xl-6,
  .my-xl-6 {
    margin-top: 1.25rem !important; }
  .mr-xl-6,
  .mx-xl-6 {
    margin-right: 1.25rem !important; }
  .mb-xl-6,
  .my-xl-6 {
    margin-bottom: 1.25rem !important; }
  .ml-xl-6,
  .mx-xl-6 {
    margin-left: 1.25rem !important; }
  .m-xl-7 {
    margin: 1.5rem !important; }
  .mt-xl-7,
  .my-xl-7 {
    margin-top: 1.5rem !important; }
  .mr-xl-7,
  .mx-xl-7 {
    margin-right: 1.5rem !important; }
  .mb-xl-7,
  .my-xl-7 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-7,
  .mx-xl-7 {
    margin-left: 1.5rem !important; }
  .m-xl-8 {
    margin: 2rem !important; }
  .mt-xl-8,
  .my-xl-8 {
    margin-top: 2rem !important; }
  .mr-xl-8,
  .mx-xl-8 {
    margin-right: 2rem !important; }
  .mb-xl-8,
  .my-xl-8 {
    margin-bottom: 2rem !important; }
  .ml-xl-8,
  .mx-xl-8 {
    margin-left: 2rem !important; }
  .m-xl-9 {
    margin: 2.5rem !important; }
  .mt-xl-9,
  .my-xl-9 {
    margin-top: 2.5rem !important; }
  .mr-xl-9,
  .mx-xl-9 {
    margin-right: 2.5rem !important; }
  .mb-xl-9,
  .my-xl-9 {
    margin-bottom: 2.5rem !important; }
  .ml-xl-9,
  .mx-xl-9 {
    margin-left: 2.5rem !important; }
  .m-xl-10 {
    margin: 3.5rem !important; }
  .mt-xl-10,
  .my-xl-10 {
    margin-top: 3.5rem !important; }
  .mr-xl-10,
  .mx-xl-10 {
    margin-right: 3.5rem !important; }
  .mb-xl-10,
  .my-xl-10 {
    margin-bottom: 3.5rem !important; }
  .ml-xl-10,
  .mx-xl-10 {
    margin-left: 3.5rem !important; }
  .m-xl-11 {
    margin: 5.5rem !important; }
  .mt-xl-11,
  .my-xl-11 {
    margin-top: 5.5rem !important; }
  .mr-xl-11,
  .mx-xl-11 {
    margin-right: 5.5rem !important; }
  .mb-xl-11,
  .my-xl-11 {
    margin-bottom: 5.5rem !important; }
  .ml-xl-11,
  .mx-xl-11 {
    margin-left: 5.5rem !important; }
  .m-xl-12 {
    margin: 8rem !important; }
  .mt-xl-12,
  .my-xl-12 {
    margin-top: 8rem !important; }
  .mr-xl-12,
  .mx-xl-12 {
    margin-right: 8rem !important; }
  .mb-xl-12,
  .my-xl-12 {
    margin-bottom: 8rem !important; }
  .ml-xl-12,
  .mx-xl-12 {
    margin-left: 8rem !important; }
  .m-xl-13 {
    margin: 10rem !important; }
  .mt-xl-13,
  .my-xl-13 {
    margin-top: 10rem !important; }
  .mr-xl-13,
  .mx-xl-13 {
    margin-right: 10rem !important; }
  .mb-xl-13,
  .my-xl-13 {
    margin-bottom: 10rem !important; }
  .ml-xl-13,
  .mx-xl-13 {
    margin-left: 10rem !important; }
  .m-xl-14 {
    margin: 12rem !important; }
  .mt-xl-14,
  .my-xl-14 {
    margin-top: 12rem !important; }
  .mr-xl-14,
  .mx-xl-14 {
    margin-right: 12rem !important; }
  .mb-xl-14,
  .my-xl-14 {
    margin-bottom: 12rem !important; }
  .ml-xl-14,
  .mx-xl-14 {
    margin-left: 12rem !important; }
  .m-xl-15 {
    margin: 15rem !important; }
  .mt-xl-15,
  .my-xl-15 {
    margin-top: 15rem !important; }
  .mr-xl-15,
  .mx-xl-15 {
    margin-right: 15rem !important; }
  .mb-xl-15,
  .my-xl-15 {
    margin-bottom: 15rem !important; }
  .ml-xl-15,
  .mx-xl-15 {
    margin-left: 15rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.125rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.125rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.125rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.125rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.125rem !important; }
  .p-xl-2 {
    padding: 0.25rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.25rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.25rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.25rem !important; }
  .p-xl-3 {
    padding: 0.5rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 0.5rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 0.5rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 0.5rem !important; }
  .p-xl-4 {
    padding: 0.75rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 0.75rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 0.75rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 0.75rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 0.75rem !important; }
  .p-xl-5 {
    padding: 1rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 1rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 1rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 1rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 1rem !important; }
  .p-xl-6 {
    padding: 1.25rem !important; }
  .pt-xl-6,
  .py-xl-6 {
    padding-top: 1.25rem !important; }
  .pr-xl-6,
  .px-xl-6 {
    padding-right: 1.25rem !important; }
  .pb-xl-6,
  .py-xl-6 {
    padding-bottom: 1.25rem !important; }
  .pl-xl-6,
  .px-xl-6 {
    padding-left: 1.25rem !important; }
  .p-xl-7 {
    padding: 1.5rem !important; }
  .pt-xl-7,
  .py-xl-7 {
    padding-top: 1.5rem !important; }
  .pr-xl-7,
  .px-xl-7 {
    padding-right: 1.5rem !important; }
  .pb-xl-7,
  .py-xl-7 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-7,
  .px-xl-7 {
    padding-left: 1.5rem !important; }
  .p-xl-8 {
    padding: 2rem !important; }
  .pt-xl-8,
  .py-xl-8 {
    padding-top: 2rem !important; }
  .pr-xl-8,
  .px-xl-8 {
    padding-right: 2rem !important; }
  .pb-xl-8,
  .py-xl-8 {
    padding-bottom: 2rem !important; }
  .pl-xl-8,
  .px-xl-8 {
    padding-left: 2rem !important; }
  .p-xl-9 {
    padding: 2.5rem !important; }
  .pt-xl-9,
  .py-xl-9 {
    padding-top: 2.5rem !important; }
  .pr-xl-9,
  .px-xl-9 {
    padding-right: 2.5rem !important; }
  .pb-xl-9,
  .py-xl-9 {
    padding-bottom: 2.5rem !important; }
  .pl-xl-9,
  .px-xl-9 {
    padding-left: 2.5rem !important; }
  .p-xl-10 {
    padding: 3.5rem !important; }
  .pt-xl-10,
  .py-xl-10 {
    padding-top: 3.5rem !important; }
  .pr-xl-10,
  .px-xl-10 {
    padding-right: 3.5rem !important; }
  .pb-xl-10,
  .py-xl-10 {
    padding-bottom: 3.5rem !important; }
  .pl-xl-10,
  .px-xl-10 {
    padding-left: 3.5rem !important; }
  .p-xl-11 {
    padding: 5.5rem !important; }
  .pt-xl-11,
  .py-xl-11 {
    padding-top: 5.5rem !important; }
  .pr-xl-11,
  .px-xl-11 {
    padding-right: 5.5rem !important; }
  .pb-xl-11,
  .py-xl-11 {
    padding-bottom: 5.5rem !important; }
  .pl-xl-11,
  .px-xl-11 {
    padding-left: 5.5rem !important; }
  .p-xl-12 {
    padding: 8rem !important; }
  .pt-xl-12,
  .py-xl-12 {
    padding-top: 8rem !important; }
  .pr-xl-12,
  .px-xl-12 {
    padding-right: 8rem !important; }
  .pb-xl-12,
  .py-xl-12 {
    padding-bottom: 8rem !important; }
  .pl-xl-12,
  .px-xl-12 {
    padding-left: 8rem !important; }
  .p-xl-13 {
    padding: 10rem !important; }
  .pt-xl-13,
  .py-xl-13 {
    padding-top: 10rem !important; }
  .pr-xl-13,
  .px-xl-13 {
    padding-right: 10rem !important; }
  .pb-xl-13,
  .py-xl-13 {
    padding-bottom: 10rem !important; }
  .pl-xl-13,
  .px-xl-13 {
    padding-left: 10rem !important; }
  .p-xl-14 {
    padding: 12rem !important; }
  .pt-xl-14,
  .py-xl-14 {
    padding-top: 12rem !important; }
  .pr-xl-14,
  .px-xl-14 {
    padding-right: 12rem !important; }
  .pb-xl-14,
  .py-xl-14 {
    padding-bottom: 12rem !important; }
  .pl-xl-14,
  .px-xl-14 {
    padding-left: 12rem !important; }
  .p-xl-15 {
    padding: 15rem !important; }
  .pt-xl-15,
  .py-xl-15 {
    padding-top: 15rem !important; }
  .pr-xl-15,
  .px-xl-15 {
    padding-right: 15rem !important; }
  .pb-xl-15,
  .py-xl-15 {
    padding-bottom: 15rem !important; }
  .pl-xl-15,
  .px-xl-15 {
    padding-left: 15rem !important; }
  .m-xl-n1 {
    margin: -0.125rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.125rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.125rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.125rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.125rem !important; }
  .m-xl-n2 {
    margin: -0.25rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.25rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.25rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.25rem !important; }
  .m-xl-n3 {
    margin: -0.5rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -0.5rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -0.5rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -0.5rem !important; }
  .m-xl-n4 {
    margin: -0.75rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -0.75rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -0.75rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -0.75rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -0.75rem !important; }
  .m-xl-n5 {
    margin: -1rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -1rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -1rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -1rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -1rem !important; }
  .m-xl-n6 {
    margin: -1.25rem !important; }
  .mt-xl-n6,
  .my-xl-n6 {
    margin-top: -1.25rem !important; }
  .mr-xl-n6,
  .mx-xl-n6 {
    margin-right: -1.25rem !important; }
  .mb-xl-n6,
  .my-xl-n6 {
    margin-bottom: -1.25rem !important; }
  .ml-xl-n6,
  .mx-xl-n6 {
    margin-left: -1.25rem !important; }
  .m-xl-n7 {
    margin: -1.5rem !important; }
  .mt-xl-n7,
  .my-xl-n7 {
    margin-top: -1.5rem !important; }
  .mr-xl-n7,
  .mx-xl-n7 {
    margin-right: -1.5rem !important; }
  .mb-xl-n7,
  .my-xl-n7 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n7,
  .mx-xl-n7 {
    margin-left: -1.5rem !important; }
  .m-xl-n8 {
    margin: -2rem !important; }
  .mt-xl-n8,
  .my-xl-n8 {
    margin-top: -2rem !important; }
  .mr-xl-n8,
  .mx-xl-n8 {
    margin-right: -2rem !important; }
  .mb-xl-n8,
  .my-xl-n8 {
    margin-bottom: -2rem !important; }
  .ml-xl-n8,
  .mx-xl-n8 {
    margin-left: -2rem !important; }
  .m-xl-n9 {
    margin: -2.5rem !important; }
  .mt-xl-n9,
  .my-xl-n9 {
    margin-top: -2.5rem !important; }
  .mr-xl-n9,
  .mx-xl-n9 {
    margin-right: -2.5rem !important; }
  .mb-xl-n9,
  .my-xl-n9 {
    margin-bottom: -2.5rem !important; }
  .ml-xl-n9,
  .mx-xl-n9 {
    margin-left: -2.5rem !important; }
  .m-xl-n10 {
    margin: -3.5rem !important; }
  .mt-xl-n10,
  .my-xl-n10 {
    margin-top: -3.5rem !important; }
  .mr-xl-n10,
  .mx-xl-n10 {
    margin-right: -3.5rem !important; }
  .mb-xl-n10,
  .my-xl-n10 {
    margin-bottom: -3.5rem !important; }
  .ml-xl-n10,
  .mx-xl-n10 {
    margin-left: -3.5rem !important; }
  .m-xl-n11 {
    margin: -5.5rem !important; }
  .mt-xl-n11,
  .my-xl-n11 {
    margin-top: -5.5rem !important; }
  .mr-xl-n11,
  .mx-xl-n11 {
    margin-right: -5.5rem !important; }
  .mb-xl-n11,
  .my-xl-n11 {
    margin-bottom: -5.5rem !important; }
  .ml-xl-n11,
  .mx-xl-n11 {
    margin-left: -5.5rem !important; }
  .m-xl-n12 {
    margin: -8rem !important; }
  .mt-xl-n12,
  .my-xl-n12 {
    margin-top: -8rem !important; }
  .mr-xl-n12,
  .mx-xl-n12 {
    margin-right: -8rem !important; }
  .mb-xl-n12,
  .my-xl-n12 {
    margin-bottom: -8rem !important; }
  .ml-xl-n12,
  .mx-xl-n12 {
    margin-left: -8rem !important; }
  .m-xl-n13 {
    margin: -10rem !important; }
  .mt-xl-n13,
  .my-xl-n13 {
    margin-top: -10rem !important; }
  .mr-xl-n13,
  .mx-xl-n13 {
    margin-right: -10rem !important; }
  .mb-xl-n13,
  .my-xl-n13 {
    margin-bottom: -10rem !important; }
  .ml-xl-n13,
  .mx-xl-n13 {
    margin-left: -10rem !important; }
  .m-xl-n14 {
    margin: -12rem !important; }
  .mt-xl-n14,
  .my-xl-n14 {
    margin-top: -12rem !important; }
  .mr-xl-n14,
  .mx-xl-n14 {
    margin-right: -12rem !important; }
  .mb-xl-n14,
  .my-xl-n14 {
    margin-bottom: -12rem !important; }
  .ml-xl-n14,
  .mx-xl-n14 {
    margin-left: -12rem !important; }
  .m-xl-n15 {
    margin: -15rem !important; }
  .mt-xl-n15,
  .my-xl-n15 {
    margin-top: -15rem !important; }
  .mr-xl-n15,
  .mx-xl-n15 {
    margin-right: -15rem !important; }
  .mb-xl-n15,
  .my-xl-n15 {
    margin-bottom: -15rem !important; }
  .ml-xl-n15,
  .mx-xl-n15 {
    margin-left: -15rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

@media (min-width: 1440px) {
  .m-xxl-0 {
    margin: 0 !important; }
  .mt-xxl-0,
  .my-xxl-0 {
    margin-top: 0 !important; }
  .mr-xxl-0,
  .mx-xxl-0 {
    margin-right: 0 !important; }
  .mb-xxl-0,
  .my-xxl-0 {
    margin-bottom: 0 !important; }
  .ml-xxl-0,
  .mx-xxl-0 {
    margin-left: 0 !important; }
  .m-xxl-1 {
    margin: 0.125rem !important; }
  .mt-xxl-1,
  .my-xxl-1 {
    margin-top: 0.125rem !important; }
  .mr-xxl-1,
  .mx-xxl-1 {
    margin-right: 0.125rem !important; }
  .mb-xxl-1,
  .my-xxl-1 {
    margin-bottom: 0.125rem !important; }
  .ml-xxl-1,
  .mx-xxl-1 {
    margin-left: 0.125rem !important; }
  .m-xxl-2 {
    margin: 0.25rem !important; }
  .mt-xxl-2,
  .my-xxl-2 {
    margin-top: 0.25rem !important; }
  .mr-xxl-2,
  .mx-xxl-2 {
    margin-right: 0.25rem !important; }
  .mb-xxl-2,
  .my-xxl-2 {
    margin-bottom: 0.25rem !important; }
  .ml-xxl-2,
  .mx-xxl-2 {
    margin-left: 0.25rem !important; }
  .m-xxl-3 {
    margin: 0.5rem !important; }
  .mt-xxl-3,
  .my-xxl-3 {
    margin-top: 0.5rem !important; }
  .mr-xxl-3,
  .mx-xxl-3 {
    margin-right: 0.5rem !important; }
  .mb-xxl-3,
  .my-xxl-3 {
    margin-bottom: 0.5rem !important; }
  .ml-xxl-3,
  .mx-xxl-3 {
    margin-left: 0.5rem !important; }
  .m-xxl-4 {
    margin: 0.75rem !important; }
  .mt-xxl-4,
  .my-xxl-4 {
    margin-top: 0.75rem !important; }
  .mr-xxl-4,
  .mx-xxl-4 {
    margin-right: 0.75rem !important; }
  .mb-xxl-4,
  .my-xxl-4 {
    margin-bottom: 0.75rem !important; }
  .ml-xxl-4,
  .mx-xxl-4 {
    margin-left: 0.75rem !important; }
  .m-xxl-5 {
    margin: 1rem !important; }
  .mt-xxl-5,
  .my-xxl-5 {
    margin-top: 1rem !important; }
  .mr-xxl-5,
  .mx-xxl-5 {
    margin-right: 1rem !important; }
  .mb-xxl-5,
  .my-xxl-5 {
    margin-bottom: 1rem !important; }
  .ml-xxl-5,
  .mx-xxl-5 {
    margin-left: 1rem !important; }
  .m-xxl-6 {
    margin: 1.25rem !important; }
  .mt-xxl-6,
  .my-xxl-6 {
    margin-top: 1.25rem !important; }
  .mr-xxl-6,
  .mx-xxl-6 {
    margin-right: 1.25rem !important; }
  .mb-xxl-6,
  .my-xxl-6 {
    margin-bottom: 1.25rem !important; }
  .ml-xxl-6,
  .mx-xxl-6 {
    margin-left: 1.25rem !important; }
  .m-xxl-7 {
    margin: 1.5rem !important; }
  .mt-xxl-7,
  .my-xxl-7 {
    margin-top: 1.5rem !important; }
  .mr-xxl-7,
  .mx-xxl-7 {
    margin-right: 1.5rem !important; }
  .mb-xxl-7,
  .my-xxl-7 {
    margin-bottom: 1.5rem !important; }
  .ml-xxl-7,
  .mx-xxl-7 {
    margin-left: 1.5rem !important; }
  .m-xxl-8 {
    margin: 2rem !important; }
  .mt-xxl-8,
  .my-xxl-8 {
    margin-top: 2rem !important; }
  .mr-xxl-8,
  .mx-xxl-8 {
    margin-right: 2rem !important; }
  .mb-xxl-8,
  .my-xxl-8 {
    margin-bottom: 2rem !important; }
  .ml-xxl-8,
  .mx-xxl-8 {
    margin-left: 2rem !important; }
  .m-xxl-9 {
    margin: 2.5rem !important; }
  .mt-xxl-9,
  .my-xxl-9 {
    margin-top: 2.5rem !important; }
  .mr-xxl-9,
  .mx-xxl-9 {
    margin-right: 2.5rem !important; }
  .mb-xxl-9,
  .my-xxl-9 {
    margin-bottom: 2.5rem !important; }
  .ml-xxl-9,
  .mx-xxl-9 {
    margin-left: 2.5rem !important; }
  .m-xxl-10 {
    margin: 3.5rem !important; }
  .mt-xxl-10,
  .my-xxl-10 {
    margin-top: 3.5rem !important; }
  .mr-xxl-10,
  .mx-xxl-10 {
    margin-right: 3.5rem !important; }
  .mb-xxl-10,
  .my-xxl-10 {
    margin-bottom: 3.5rem !important; }
  .ml-xxl-10,
  .mx-xxl-10 {
    margin-left: 3.5rem !important; }
  .m-xxl-11 {
    margin: 5.5rem !important; }
  .mt-xxl-11,
  .my-xxl-11 {
    margin-top: 5.5rem !important; }
  .mr-xxl-11,
  .mx-xxl-11 {
    margin-right: 5.5rem !important; }
  .mb-xxl-11,
  .my-xxl-11 {
    margin-bottom: 5.5rem !important; }
  .ml-xxl-11,
  .mx-xxl-11 {
    margin-left: 5.5rem !important; }
  .m-xxl-12 {
    margin: 8rem !important; }
  .mt-xxl-12,
  .my-xxl-12 {
    margin-top: 8rem !important; }
  .mr-xxl-12,
  .mx-xxl-12 {
    margin-right: 8rem !important; }
  .mb-xxl-12,
  .my-xxl-12 {
    margin-bottom: 8rem !important; }
  .ml-xxl-12,
  .mx-xxl-12 {
    margin-left: 8rem !important; }
  .m-xxl-13 {
    margin: 10rem !important; }
  .mt-xxl-13,
  .my-xxl-13 {
    margin-top: 10rem !important; }
  .mr-xxl-13,
  .mx-xxl-13 {
    margin-right: 10rem !important; }
  .mb-xxl-13,
  .my-xxl-13 {
    margin-bottom: 10rem !important; }
  .ml-xxl-13,
  .mx-xxl-13 {
    margin-left: 10rem !important; }
  .m-xxl-14 {
    margin: 12rem !important; }
  .mt-xxl-14,
  .my-xxl-14 {
    margin-top: 12rem !important; }
  .mr-xxl-14,
  .mx-xxl-14 {
    margin-right: 12rem !important; }
  .mb-xxl-14,
  .my-xxl-14 {
    margin-bottom: 12rem !important; }
  .ml-xxl-14,
  .mx-xxl-14 {
    margin-left: 12rem !important; }
  .m-xxl-15 {
    margin: 15rem !important; }
  .mt-xxl-15,
  .my-xxl-15 {
    margin-top: 15rem !important; }
  .mr-xxl-15,
  .mx-xxl-15 {
    margin-right: 15rem !important; }
  .mb-xxl-15,
  .my-xxl-15 {
    margin-bottom: 15rem !important; }
  .ml-xxl-15,
  .mx-xxl-15 {
    margin-left: 15rem !important; }
  .p-xxl-0 {
    padding: 0 !important; }
  .pt-xxl-0,
  .py-xxl-0 {
    padding-top: 0 !important; }
  .pr-xxl-0,
  .px-xxl-0 {
    padding-right: 0 !important; }
  .pb-xxl-0,
  .py-xxl-0 {
    padding-bottom: 0 !important; }
  .pl-xxl-0,
  .px-xxl-0 {
    padding-left: 0 !important; }
  .p-xxl-1 {
    padding: 0.125rem !important; }
  .pt-xxl-1,
  .py-xxl-1 {
    padding-top: 0.125rem !important; }
  .pr-xxl-1,
  .px-xxl-1 {
    padding-right: 0.125rem !important; }
  .pb-xxl-1,
  .py-xxl-1 {
    padding-bottom: 0.125rem !important; }
  .pl-xxl-1,
  .px-xxl-1 {
    padding-left: 0.125rem !important; }
  .p-xxl-2 {
    padding: 0.25rem !important; }
  .pt-xxl-2,
  .py-xxl-2 {
    padding-top: 0.25rem !important; }
  .pr-xxl-2,
  .px-xxl-2 {
    padding-right: 0.25rem !important; }
  .pb-xxl-2,
  .py-xxl-2 {
    padding-bottom: 0.25rem !important; }
  .pl-xxl-2,
  .px-xxl-2 {
    padding-left: 0.25rem !important; }
  .p-xxl-3 {
    padding: 0.5rem !important; }
  .pt-xxl-3,
  .py-xxl-3 {
    padding-top: 0.5rem !important; }
  .pr-xxl-3,
  .px-xxl-3 {
    padding-right: 0.5rem !important; }
  .pb-xxl-3,
  .py-xxl-3 {
    padding-bottom: 0.5rem !important; }
  .pl-xxl-3,
  .px-xxl-3 {
    padding-left: 0.5rem !important; }
  .p-xxl-4 {
    padding: 0.75rem !important; }
  .pt-xxl-4,
  .py-xxl-4 {
    padding-top: 0.75rem !important; }
  .pr-xxl-4,
  .px-xxl-4 {
    padding-right: 0.75rem !important; }
  .pb-xxl-4,
  .py-xxl-4 {
    padding-bottom: 0.75rem !important; }
  .pl-xxl-4,
  .px-xxl-4 {
    padding-left: 0.75rem !important; }
  .p-xxl-5 {
    padding: 1rem !important; }
  .pt-xxl-5,
  .py-xxl-5 {
    padding-top: 1rem !important; }
  .pr-xxl-5,
  .px-xxl-5 {
    padding-right: 1rem !important; }
  .pb-xxl-5,
  .py-xxl-5 {
    padding-bottom: 1rem !important; }
  .pl-xxl-5,
  .px-xxl-5 {
    padding-left: 1rem !important; }
  .p-xxl-6 {
    padding: 1.25rem !important; }
  .pt-xxl-6,
  .py-xxl-6 {
    padding-top: 1.25rem !important; }
  .pr-xxl-6,
  .px-xxl-6 {
    padding-right: 1.25rem !important; }
  .pb-xxl-6,
  .py-xxl-6 {
    padding-bottom: 1.25rem !important; }
  .pl-xxl-6,
  .px-xxl-6 {
    padding-left: 1.25rem !important; }
  .p-xxl-7 {
    padding: 1.5rem !important; }
  .pt-xxl-7,
  .py-xxl-7 {
    padding-top: 1.5rem !important; }
  .pr-xxl-7,
  .px-xxl-7 {
    padding-right: 1.5rem !important; }
  .pb-xxl-7,
  .py-xxl-7 {
    padding-bottom: 1.5rem !important; }
  .pl-xxl-7,
  .px-xxl-7 {
    padding-left: 1.5rem !important; }
  .p-xxl-8 {
    padding: 2rem !important; }
  .pt-xxl-8,
  .py-xxl-8 {
    padding-top: 2rem !important; }
  .pr-xxl-8,
  .px-xxl-8 {
    padding-right: 2rem !important; }
  .pb-xxl-8,
  .py-xxl-8 {
    padding-bottom: 2rem !important; }
  .pl-xxl-8,
  .px-xxl-8 {
    padding-left: 2rem !important; }
  .p-xxl-9 {
    padding: 2.5rem !important; }
  .pt-xxl-9,
  .py-xxl-9 {
    padding-top: 2.5rem !important; }
  .pr-xxl-9,
  .px-xxl-9 {
    padding-right: 2.5rem !important; }
  .pb-xxl-9,
  .py-xxl-9 {
    padding-bottom: 2.5rem !important; }
  .pl-xxl-9,
  .px-xxl-9 {
    padding-left: 2.5rem !important; }
  .p-xxl-10 {
    padding: 3.5rem !important; }
  .pt-xxl-10,
  .py-xxl-10 {
    padding-top: 3.5rem !important; }
  .pr-xxl-10,
  .px-xxl-10 {
    padding-right: 3.5rem !important; }
  .pb-xxl-10,
  .py-xxl-10 {
    padding-bottom: 3.5rem !important; }
  .pl-xxl-10,
  .px-xxl-10 {
    padding-left: 3.5rem !important; }
  .p-xxl-11 {
    padding: 5.5rem !important; }
  .pt-xxl-11,
  .py-xxl-11 {
    padding-top: 5.5rem !important; }
  .pr-xxl-11,
  .px-xxl-11 {
    padding-right: 5.5rem !important; }
  .pb-xxl-11,
  .py-xxl-11 {
    padding-bottom: 5.5rem !important; }
  .pl-xxl-11,
  .px-xxl-11 {
    padding-left: 5.5rem !important; }
  .p-xxl-12 {
    padding: 8rem !important; }
  .pt-xxl-12,
  .py-xxl-12 {
    padding-top: 8rem !important; }
  .pr-xxl-12,
  .px-xxl-12 {
    padding-right: 8rem !important; }
  .pb-xxl-12,
  .py-xxl-12 {
    padding-bottom: 8rem !important; }
  .pl-xxl-12,
  .px-xxl-12 {
    padding-left: 8rem !important; }
  .p-xxl-13 {
    padding: 10rem !important; }
  .pt-xxl-13,
  .py-xxl-13 {
    padding-top: 10rem !important; }
  .pr-xxl-13,
  .px-xxl-13 {
    padding-right: 10rem !important; }
  .pb-xxl-13,
  .py-xxl-13 {
    padding-bottom: 10rem !important; }
  .pl-xxl-13,
  .px-xxl-13 {
    padding-left: 10rem !important; }
  .p-xxl-14 {
    padding: 12rem !important; }
  .pt-xxl-14,
  .py-xxl-14 {
    padding-top: 12rem !important; }
  .pr-xxl-14,
  .px-xxl-14 {
    padding-right: 12rem !important; }
  .pb-xxl-14,
  .py-xxl-14 {
    padding-bottom: 12rem !important; }
  .pl-xxl-14,
  .px-xxl-14 {
    padding-left: 12rem !important; }
  .p-xxl-15 {
    padding: 15rem !important; }
  .pt-xxl-15,
  .py-xxl-15 {
    padding-top: 15rem !important; }
  .pr-xxl-15,
  .px-xxl-15 {
    padding-right: 15rem !important; }
  .pb-xxl-15,
  .py-xxl-15 {
    padding-bottom: 15rem !important; }
  .pl-xxl-15,
  .px-xxl-15 {
    padding-left: 15rem !important; }
  .m-xxl-n1 {
    margin: -0.125rem !important; }
  .mt-xxl-n1,
  .my-xxl-n1 {
    margin-top: -0.125rem !important; }
  .mr-xxl-n1,
  .mx-xxl-n1 {
    margin-right: -0.125rem !important; }
  .mb-xxl-n1,
  .my-xxl-n1 {
    margin-bottom: -0.125rem !important; }
  .ml-xxl-n1,
  .mx-xxl-n1 {
    margin-left: -0.125rem !important; }
  .m-xxl-n2 {
    margin: -0.25rem !important; }
  .mt-xxl-n2,
  .my-xxl-n2 {
    margin-top: -0.25rem !important; }
  .mr-xxl-n2,
  .mx-xxl-n2 {
    margin-right: -0.25rem !important; }
  .mb-xxl-n2,
  .my-xxl-n2 {
    margin-bottom: -0.25rem !important; }
  .ml-xxl-n2,
  .mx-xxl-n2 {
    margin-left: -0.25rem !important; }
  .m-xxl-n3 {
    margin: -0.5rem !important; }
  .mt-xxl-n3,
  .my-xxl-n3 {
    margin-top: -0.5rem !important; }
  .mr-xxl-n3,
  .mx-xxl-n3 {
    margin-right: -0.5rem !important; }
  .mb-xxl-n3,
  .my-xxl-n3 {
    margin-bottom: -0.5rem !important; }
  .ml-xxl-n3,
  .mx-xxl-n3 {
    margin-left: -0.5rem !important; }
  .m-xxl-n4 {
    margin: -0.75rem !important; }
  .mt-xxl-n4,
  .my-xxl-n4 {
    margin-top: -0.75rem !important; }
  .mr-xxl-n4,
  .mx-xxl-n4 {
    margin-right: -0.75rem !important; }
  .mb-xxl-n4,
  .my-xxl-n4 {
    margin-bottom: -0.75rem !important; }
  .ml-xxl-n4,
  .mx-xxl-n4 {
    margin-left: -0.75rem !important; }
  .m-xxl-n5 {
    margin: -1rem !important; }
  .mt-xxl-n5,
  .my-xxl-n5 {
    margin-top: -1rem !important; }
  .mr-xxl-n5,
  .mx-xxl-n5 {
    margin-right: -1rem !important; }
  .mb-xxl-n5,
  .my-xxl-n5 {
    margin-bottom: -1rem !important; }
  .ml-xxl-n5,
  .mx-xxl-n5 {
    margin-left: -1rem !important; }
  .m-xxl-n6 {
    margin: -1.25rem !important; }
  .mt-xxl-n6,
  .my-xxl-n6 {
    margin-top: -1.25rem !important; }
  .mr-xxl-n6,
  .mx-xxl-n6 {
    margin-right: -1.25rem !important; }
  .mb-xxl-n6,
  .my-xxl-n6 {
    margin-bottom: -1.25rem !important; }
  .ml-xxl-n6,
  .mx-xxl-n6 {
    margin-left: -1.25rem !important; }
  .m-xxl-n7 {
    margin: -1.5rem !important; }
  .mt-xxl-n7,
  .my-xxl-n7 {
    margin-top: -1.5rem !important; }
  .mr-xxl-n7,
  .mx-xxl-n7 {
    margin-right: -1.5rem !important; }
  .mb-xxl-n7,
  .my-xxl-n7 {
    margin-bottom: -1.5rem !important; }
  .ml-xxl-n7,
  .mx-xxl-n7 {
    margin-left: -1.5rem !important; }
  .m-xxl-n8 {
    margin: -2rem !important; }
  .mt-xxl-n8,
  .my-xxl-n8 {
    margin-top: -2rem !important; }
  .mr-xxl-n8,
  .mx-xxl-n8 {
    margin-right: -2rem !important; }
  .mb-xxl-n8,
  .my-xxl-n8 {
    margin-bottom: -2rem !important; }
  .ml-xxl-n8,
  .mx-xxl-n8 {
    margin-left: -2rem !important; }
  .m-xxl-n9 {
    margin: -2.5rem !important; }
  .mt-xxl-n9,
  .my-xxl-n9 {
    margin-top: -2.5rem !important; }
  .mr-xxl-n9,
  .mx-xxl-n9 {
    margin-right: -2.5rem !important; }
  .mb-xxl-n9,
  .my-xxl-n9 {
    margin-bottom: -2.5rem !important; }
  .ml-xxl-n9,
  .mx-xxl-n9 {
    margin-left: -2.5rem !important; }
  .m-xxl-n10 {
    margin: -3.5rem !important; }
  .mt-xxl-n10,
  .my-xxl-n10 {
    margin-top: -3.5rem !important; }
  .mr-xxl-n10,
  .mx-xxl-n10 {
    margin-right: -3.5rem !important; }
  .mb-xxl-n10,
  .my-xxl-n10 {
    margin-bottom: -3.5rem !important; }
  .ml-xxl-n10,
  .mx-xxl-n10 {
    margin-left: -3.5rem !important; }
  .m-xxl-n11 {
    margin: -5.5rem !important; }
  .mt-xxl-n11,
  .my-xxl-n11 {
    margin-top: -5.5rem !important; }
  .mr-xxl-n11,
  .mx-xxl-n11 {
    margin-right: -5.5rem !important; }
  .mb-xxl-n11,
  .my-xxl-n11 {
    margin-bottom: -5.5rem !important; }
  .ml-xxl-n11,
  .mx-xxl-n11 {
    margin-left: -5.5rem !important; }
  .m-xxl-n12 {
    margin: -8rem !important; }
  .mt-xxl-n12,
  .my-xxl-n12 {
    margin-top: -8rem !important; }
  .mr-xxl-n12,
  .mx-xxl-n12 {
    margin-right: -8rem !important; }
  .mb-xxl-n12,
  .my-xxl-n12 {
    margin-bottom: -8rem !important; }
  .ml-xxl-n12,
  .mx-xxl-n12 {
    margin-left: -8rem !important; }
  .m-xxl-n13 {
    margin: -10rem !important; }
  .mt-xxl-n13,
  .my-xxl-n13 {
    margin-top: -10rem !important; }
  .mr-xxl-n13,
  .mx-xxl-n13 {
    margin-right: -10rem !important; }
  .mb-xxl-n13,
  .my-xxl-n13 {
    margin-bottom: -10rem !important; }
  .ml-xxl-n13,
  .mx-xxl-n13 {
    margin-left: -10rem !important; }
  .m-xxl-n14 {
    margin: -12rem !important; }
  .mt-xxl-n14,
  .my-xxl-n14 {
    margin-top: -12rem !important; }
  .mr-xxl-n14,
  .mx-xxl-n14 {
    margin-right: -12rem !important; }
  .mb-xxl-n14,
  .my-xxl-n14 {
    margin-bottom: -12rem !important; }
  .ml-xxl-n14,
  .mx-xxl-n14 {
    margin-left: -12rem !important; }
  .m-xxl-n15 {
    margin: -15rem !important; }
  .mt-xxl-n15,
  .my-xxl-n15 {
    margin-top: -15rem !important; }
  .mr-xxl-n15,
  .mx-xxl-n15 {
    margin-right: -15rem !important; }
  .mb-xxl-n15,
  .my-xxl-n15 {
    margin-bottom: -15rem !important; }
  .ml-xxl-n15,
  .mx-xxl-n15 {
    margin-left: -15rem !important; }
  .m-xxl-auto {
    margin: auto !important; }
  .mt-xxl-auto,
  .my-xxl-auto {
    margin-top: auto !important; }
  .mr-xxl-auto,
  .mx-xxl-auto {
    margin-right: auto !important; }
  .mb-xxl-auto,
  .my-xxl-auto {
    margin-bottom: auto !important; }
  .ml-xxl-auto,
  .mx-xxl-auto {
    margin-left: auto !important; } }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media (min-width: 1440px) {
  .text-xxl-left {
    text-align: left !important; }
  .text-xxl-right {
    text-align: right !important; }
  .text-xxl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-basic {
  color: #fff !important; }

a.text-basic:hover, a.text-basic:focus {
  color: #d9d9d9 !important; }

.text-basic-inverse {
  color: #212529 !important; }

a.text-basic-inverse:hover, a.text-basic-inverse:focus {
  color: black !important; }

.text-primary {
  color: #0176ff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0053b4 !important; }

.text-secondary {
  color: #edeef6 !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #bac0dc !important; }

.text-tertiary {
  color: #aab0b7 !important; }

a.text-tertiary:hover, a.text-tertiary:focus {
  color: #818a94 !important; }

.text-success {
  color: #42ba96 !important; }

a.text-success:hover, a.text-success:focus {
  color: #2e8268 !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #fcf22b !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #d7cd03 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f5f6fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #c2c8e1 !important; }

.text-dark {
  color: #242939 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #06070a !important; }

.text-white {
  color: #fff !important; }

a.text-white:hover, a.text-white:focus {
  color: #d9d9d9 !important; }

.text-body {
  color: #7f808c !important; }

.text-muted {
  color: #aab0b7 !important; }

.text-heading-screen1{
    color: #25265E !important;
}

.text-screen1{
  color: #474747 !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }
  
  .bg-screen-chat{
    background: #FBFAFF;
  }

span.text-reset {
    color: inherit !important;
    margin-top: 5px !important;
    margin-bottom: 5px !important;
    width: calc(100% - 5px);
}    

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important; }
  a:not(.btn) {
    text-decoration: underline; }
  abbr[title]::after {
    content: " (" attr(title) ")"; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid; }
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; }
  @page {
    size: a3; }
  body {
    min-width: 992px !important; }
  .container {
    min-width: 992px !important; }
  .navbar {
    display: none; }
  .badge {
    border: 1px solid #000; }
  .table {
    border-collapse: collapse !important; }
    .table td,
    .table th {
      background-color: #fff !important; }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important; }
  .table-dark {
    color: inherit; }
    .table-dark th,
    .table-dark td,
    .table-dark thead th,
    .table-dark tbody + tbody {
      border-color: rgba(245, 246, 250, 0.85); }
  .table .thead-dark th {
    color: inherit;
    border-color: rgba(245, 246, 250, 0.85); } }

body {
  letter-spacing: 0.01875rem; }

p:last-child {
  margin-bottom: 0; }

pre[class*="language-"], code[class*="language-"] {
  text-shadow: none; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;
  overflow-x: hidden; }

.hide-scrollbar::-webkit-scrollbar {
  display: none; }

[data-horizontal-scroll] {
  overflow-x: scroll; }

.stretched-label {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0; }

.z-index-max {
  z-index: 1040; }

.card-columns {
  column-count: 1; }
  @media (min-width: 576px) and (max-width: 767.98px) {
    .card-columns {
      column-count: 1; } }
  @media (min-width: 768px) and (max-width: 991.98px) {
    .card-columns {
      column-count: 1; } }
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .card-columns {
      column-count: 1; } }
  @media (min-width: 1200px) and (max-width: 1439.98px) {
    .card-columns {
      column-count: 2; } }
  @media (min-width: 1440px) {
    .card-columns {
      column-count: 2; } }

html, body, .layout {
  height: 100%; }

.layout {
  display: flex;
  flex-direction: column; }

.navigation {
  border-top: 1px solid rgba(245, 246, 250, 0.85);
  // padding-left: 20px;
  // padding-right: 20px;
  padding-left: 0px;
  padding-right: 0px;
  order: 2;
  min-height: 61px; }

.sidebar,
.navigation,
.chat-content,
.chat-sidebar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: auto;
  overflow-x: hidden; }

.sidebar::-webkit-scrollbar,
.navigation::-webkit-scrollbar,
.chat-content::-webkit-scrollbar,
.chat-sidebar::-webkit-scrollbar {
  display: none; }

.main,
.chat-sidebar {
  height: 100vh;
  height: 100%; }

.main,
.navigation,
.chat-sidebar {
  background: #fff; }

.sidebar {
  background: #2C3049;
  flex-grow: 2;
  order: 1; }
  .plan-upgrade .sidebar {z-index: 999;}
  

  .sidebar.IOLP{
    position: absolute;
    width: 100%;
    z-index: 9111;
    box-shadow: 0px 0px 114px 32px #000;
    height: 100%;
  }

.main {
  overflow: hidden;
  order: 3;
  flex: 1; }
  .main .chat-body {
    display: flex;
    flex-grow: 1;
    flex-flow: column;
    min-width: 0;
    width: 100%; }
  .main .chat-content {
    flex: 2; }

.chat {
  display: flex;
  height: 100%;
  background: #FBFAFF; }

.chat-sidebar {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 100%;
  z-index: 3;
  width: 100%;
  visibility: hidden;
  transition: visibility .4s ease, -webkit-transform .4s ease, transform .4s ease; }
  .chat-sidebar.chat-sidebar-visible {
    visibility: visible;
    transform: translateX(-100%); }
  .plan-upgrade .navigation {z-index: 9911;}
   

@media (min-width: 1200px) {
  .layout {
    flex-direction: row; }
  .sidebar, .navigation, .active-users {
    height: 100vh; }
  .navigation {
    width: 60px;
    border-top: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    order: 0;
  background: #1C223A; }

  .navigation-visible-overflow{
    overflow: visible;
  }
  .sidebar {
    width: 320px;
    flex-grow: 0; }
  .main,
  .chat-sidebar {
    height: 100vh; } }

@media (min-width: 1200px) {
  .chat-sidebar {
    position: static;
    border-left: 1px solid rgba(226, 226, 226, 0.85);
    visibility: hidden;
    width: 0;
    margin-right: -1px; }
    .chat-sidebar.chat-sidebar-visible {
      transform: translateX(0);
      visibility: visible;
      min-width: 350px; } }

@media (max-width: 1199.98px) {
  .main {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 1020;
    visibility: hidden;
    transform: translateX(100%);
    transition: visibility .4s ease, -webkit-transform .4s ease, transform .4s ease;
    background: #fff; }
    .main.main-visible {
      visibility: visible;
      transform: translateX(0); } }
/*
@font-face {
  font-family: 'Roboto';
  src: url("../../assets/fonts/roboto/roboto.italic-webfont.woff2") format("woff2"), url("../../assets/fonts/roboto/roboto.italic-webfont.woff") format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: 'Roboto';
  src: url("../../assets/fonts/roboto/roboto.bold-webfont.woff2") format("woff2"), url("../../assets/fonts/roboto/roboto.bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../../assets/fonts/roboto/roboto.regular-webfont.woff2") format("woff2"), url("../../assets/fonts/roboto/roboto.regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../../assets/fonts/roboto/roboto.medium-webfont.woff2") format("woff2"), url("../../assets/fonts/roboto/roboto.medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal; }
  */

a {
  transition: all .25s ease-in-out; }
  a:hover, a:focus {
    text-decoration: underline;
    text-decoration: none;
    outline: 0; }
    a:hover.text-muted, a:focus.text-muted {
      color: #737d88 !important; }
  a.text-muted.active {
    color: #737d88 !important; }

h1 > a.text-reset:hover, .h1 > a.text-reset:hover,
h2 > a.text-reset:hover, .h2 > a.text-reset:hover,
h3 > a.text-reset:hover, .h3 > a.text-reset:hover,
h4 > a.text-reset:hover, .h4 > a.text-reset:hover,
h5 > a.text-reset:hover, .h5 > a.text-reset:hover,
h6 > a.text-reset:hover, .h6 > a.text-reset:hover {
  color: #0176ff !important; }

h1, .h1 {
  margin-bottom: 0.5rem; }

h2, .h2 {
  margin-bottom: 0.5rem; }

h3, .h3 {
  margin-bottom: 0.5rem; }

h4, .h4 {
  margin-bottom: 0.375rem;
  letter-spacing: 0.0375rem; }

h5, .h5 {
  margin-bottom: 0.25rem;
  letter-spacing: 0.0375rem; }

h6, .h6 {
  margin-bottom: 0.125rem; }

.font-light {
  font-weight: 300 !important; }

.font-normal {
  font-weight: 400 !important; }

.font-medium {
  font-weight: 500 !important; }

.font-bold {
  font-weight: 700 !important; }

.extra-small {
  font-size: 70%; }

.typing-dots span {
  opacity: 0; }
  .typing-dots span:nth-child(1) {
    animation: 1s type-animation infinite .33333s; }
  .typing-dots span:nth-child(2) {
    animation: 1s type-animation infinite .66666s; }
  .typing-dots span:nth-child(3) {
    animation: 1s type-animation infinite .99999s; }

@keyframes type-animation {
  50% {
    opacity: 1; } }

.avatar {
  background: #f5f6fa;
  display: inline-block;
  position: relative;
  line-height: 0; }

  .chat-body .avatar{
    background: #0077ff;
    color: #fff;user-select: text;cursor: auto;
  }

  .chat-body .avatar:hover{
    text-decoration: none;
  }



  .avatar,
  .avatar > img {
    border-radius: 50%; }
  .avatar > span > img {
      border-radius: 50%; }
  .avatar > i,
  .avatar > span {
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center; user-select: text; }

.avatar,
.avatar > img {
  height: 2.8rem;
  min-height: 2.8rem;
  width: 2.8rem;
  min-width: 2.8rem; }
  .avatar > i,
  .avatar > span,
  .avatar > img > i,
  .avatar > img > span {
    font-size: 0.9375rem; }

.avatar-xs,
.avatar-xs > img {
  height: 1.25rem;
  min-height: 1.25rem;
  width: 1.25rem;
  min-width: 1.25rem; }
  .avatar-xs > i,
  .avatar-xs > span,
  .avatar-xs > img > i,
  .avatar-xs > img > span {
    font-size: 0.5rem; }

.avatar-sm,
.avatar-sm > img {
  height: 2.75rem;
  min-height: 2.75rem;
  width: 2.75rem;
  min-width: 2.75rem; }
  .avatar-sm > i,
  .avatar-sm > span,
  .avatar-sm > img > i,
  .avatar-sm > img > span {
    font-size: 0.75rem; }

.avatar-lg,
.avatar-lg > img {
  height: 4rem;
  min-height: 4rem;
  width: 4rem;
  min-width: 4rem; }
  .avatar-lg > i,
  .avatar-lg > span,
  .avatar-lg > img > i,
  .avatar-lg > img > span {
    font-size: 1.125rem; }

.avatar-sm,
.avatar-sm > span > img {
  height: 2.75rem;
  min-height: 2.75rem;
  width: 2.75rem;
  min-width: 2.75rem; }


.avatar-xl,
.avatar-xl > img {
  height: 5.75rem;
  min-height: 5.75rem;
  width: 5.75rem;
  min-width: 5.75rem; }
  .avatar-xl > i,
  .avatar-xl > span,
  .avatar-xl > img > i,
  .avatar-xl > img > span {
    font-size: 1.25rem; }

.avatar.avatar-online::before, .avatar.avatar-offline::before, .avatar.avatar-away::before {
  position: absolute;
  border: 1px solid #fff;
  display: block;
  bottom: -3px;
  right: 0px;
  content: "";
  border-radius: 50%;
  transform: translate(-10%, 0%);
  height: 30%;
  min-height: 30%;
  width: 30%;
  min-width: 30%; }

.avatar.avatar-online::before {
  background: #42ba96; }

.avatar.avatar-offline::before {
  background: #edeef6; }

.card-body .media .avatar-online::before {
  background : #5fc002 !important;
  border: 1px solid #fff !important;
}

.card-body .media .avatar-offline::before {
  border: 1px solid #fff !important;
}

.card-body .media .avatar-away::before {
  border: 1px solid #FDD770 !important;
  background: #FDD770 !important; 
}


// .flag-icon-jp{
//   border: 1px solid rgba(51, 51, 51, 0.18) !important;
//   background-size: 18px 19px !important;
// }  
  
.visitorcolor-bg-1{background-color: #0088ff;}
.visitorcolor-bg-2{background-color: #E91E63;}
.visitorcolor-bg-3{background-color: #009688;}
.visitorcolor-bg-4{background-color: #FF9800;}
.visitorcolor-bg-5{background-color: #607D8B;}
.visitorcolor-bg-6{background-color: #00BCD4;}
.visitorcolor-bg-7{background-color: #9E9E9E;}
.visitorcolor-bg-8{background-color: #5e9c27;}
.visitorcolor-bg-9{background-color: #d523ff;}
.visitorcolor-bg-10{background-color: #7d8711;}
.visitorcolor-bg-11{background-color: #2f16ff;}
.visitorcolor-bg-12{background-color: #8271ff;}
.visitorcolor-bg-13{background-color: #1b223a;}
.visitorcolor-bg-14{background-color: #b56e00;}
.visitorcolor-bg-15{background-color: #00bfff;}
.visitorcolor-bg-16{background-color: #00c59a;}
.visitorcolor-bg-17{background-color: goldenrod;}
.visitorcolor-bg-18{background-color: #656565;}
.visitorcolor-bg-19{background-color: #5f8aef;}
.visitorcolor-bg-20{background-color: #b63cff;}
.visitorcolor-bg-21{background-color: #bd6a2c;}
.visitorcolor-bg-22{background-color: #66cdaa;}
.visitorcolor-bg-23{background-color: #0d95a5;}
.visitorcolor-bg-24{background-color: #0040ff;}
.visitorcolor-bg-25{background-color: #8bbd2c;}
.fb-messanger { 
  background-image: url('../../assets/images/livechat/fb1.svg');
  background-size: 100% 100%;
}

.fb-insta { 
  background-image: url('../../assets/images/livechat/56px-Instagram_icon.png');
  background-size: 100% 100%;
}

.twitter { 
  background-image: url('../../assets/images/livechat/twitter.svg');
  background-size: 100% 100%;
}

.whatsapp { 
  background-image: url('../../assets/images/livechat/whats_app.svg');
  background-size: 100% 100%;
}

.telegram { 
  background-image: url('https://upload.wikimedia.org/wikipedia/commons/8/82/Telegram_logo.svg');
  background-size: 100% 100%;
}

.card-active-listener .media .avatar span{
  color: #fff;
}

.btn {
  letter-spacing: 0.02813rem; }

.btn-ico {
  position: relative;
  padding: 0; }
  .btn-ico > i,
  .btn-ico > svg,
  .btn-ico > span {
    position: absolute;
    transform: translate(-50%, -50%);
    font-size: 1.1875rem;
    left: 50%;
    top: 50%; }
  .btn-ico > svg {
    height: 1.3125rem;
    min-height: 1.3125rem;
    width: 1.3125rem;
    min-width: 1.3125rem; }

.btn-ico {
  height: calc(1.6em + 1.375rem + 2px);
  width: calc(1.6em + 1.375rem + 2px); }
  .btn-ico.btn-sm, .btn-group-sm > .btn-ico.btn {
    height: calc(1.5em + 0.875rem + 2px);
    width: calc(1.5em + 0.875rem + 2px); }
    .btn-ico.btn-sm i, .btn-group-sm > .btn-ico.btn i, .btn-ico.btn-sm span, .btn-group-sm > .btn-ico.btn span {
      font-size: 1.0625rem; }
    .btn-ico.btn-sm svg, .btn-group-sm > .btn-ico.btn svg {
      height: 20px;
      width: 20px; }
  .btn-ico.btn-lg, .btn-group-lg > .btn-ico.btn {
    height: 3.6rem;
    width: 3.6rem; }
  .input-group-append > .btn-ico,
  .input-group-prepend > .btn-ico {
    height: auto !important; }

.btn-basic.btn-minimal {
  color: rgba(33, 37, 41, 0.35);
  fill: rgba(33, 37, 41, 0.35); }
  .btn-basic.btn-minimal:hover, .btn-basic.btn-minimal:focus, .btn-basic.btn-minimal.focus, .btn-basic.btn-minimal:not(:disabled):not(.disabled):active, .btn-basic.btn-minimal:not(:disabled):not(.disabled).active {
    border-color: #fff;
    background: #fff;
    color: rgba(33, 37, 41, 0.5);
    fill: rgba(33, 37, 41, 0.5); }

.btn-basic-inverse.btn-minimal {
  color: rgba(255, 255, 255, 0.35);
  fill: rgba(255, 255, 255, 0.35); }
  .btn-basic-inverse.btn-minimal:hover, .btn-basic-inverse.btn-minimal:focus, .btn-basic-inverse.btn-minimal.focus, .btn-basic-inverse.btn-minimal:not(:disabled):not(.disabled):active, .btn-basic-inverse.btn-minimal:not(:disabled):not(.disabled).active {
    border-color: #212529;
    background: #212529;
    color: rgba(255, 255, 255, 0.5);
    fill: rgba(255, 255, 255, 0.5); }

.btn-primary.btn-minimal {
  color: rgba(255, 255, 255, 0.35);
  fill: rgba(255, 255, 255, 0.35); }
  .btn-primary.btn-minimal:hover, .btn-primary.btn-minimal:focus, .btn-primary.btn-minimal.focus, .btn-primary.btn-minimal:not(:disabled):not(.disabled):active, .btn-primary.btn-minimal:not(:disabled):not(.disabled).active {
    border-color: #0176ff;
    background: #0176ff;
    color: rgba(255, 255, 255, 0.5);
    fill: rgba(255, 255, 255, 0.5); }

.btn-secondary.btn-minimal {
  color: rgba(33, 37, 41, 0.35);
  fill: rgba(33, 37, 41, 0.35); }
  .btn-secondary.btn-minimal:hover, .btn-secondary.btn-minimal:focus, .btn-secondary.btn-minimal.focus, .btn-secondary.btn-minimal:not(:disabled):not(.disabled):active, .btn-secondary.btn-minimal:not(:disabled):not(.disabled).active {
    border-color: #edeef6;
    background: #edeef6;
    color: rgba(33, 37, 41, 0.5);
    fill: rgba(33, 37, 41, 0.5); }

.btn-tertiary.btn-minimal {
  color: rgba(255, 255, 255, 0.35);
  fill: rgba(255, 255, 255, 0.35); }
  .btn-tertiary.btn-minimal:hover, .btn-tertiary.btn-minimal:focus, .btn-tertiary.btn-minimal.focus, .btn-tertiary.btn-minimal:not(:disabled):not(.disabled):active, .btn-tertiary.btn-minimal:not(:disabled):not(.disabled).active {
    border-color: #aab0b7;
    background: #aab0b7;
    color: rgba(255, 255, 255, 0.5);
    fill: rgba(255, 255, 255, 0.5); }

.btn-success.btn-minimal {
  color: rgba(255, 255, 255, 0.35);
  fill: rgba(255, 255, 255, 0.35); }
  .btn-success.btn-minimal:hover, .btn-success.btn-minimal:focus, .btn-success.btn-minimal.focus, .btn-success.btn-minimal:not(:disabled):not(.disabled):active, .btn-success.btn-minimal:not(:disabled):not(.disabled).active {
    border-color: #42ba96;
    background: #42ba96;
    color: rgba(255, 255, 255, 0.5);
    fill: rgba(255, 255, 255, 0.5); }

.btn-info.btn-minimal {
  color: rgba(255, 255, 255, 0.35);
  fill: rgba(255, 255, 255, 0.35); }
  .btn-info.btn-minimal:hover, .btn-info.btn-minimal:focus, .btn-info.btn-minimal.focus, .btn-info.btn-minimal:not(:disabled):not(.disabled):active, .btn-info.btn-minimal:not(:disabled):not(.disabled).active {
    border-color: #17a2b8;
    background: #17a2b8;
    color: rgba(255, 255, 255, 0.5);
    fill: rgba(255, 255, 255, 0.5); }

.btn-warning.btn-minimal {
  color: rgba(33, 37, 41, 0.35);
  fill: rgba(33, 37, 41, 0.35); }
  .btn-warning.btn-minimal:hover, .btn-warning.btn-minimal:focus, .btn-warning.btn-minimal.focus, .btn-warning.btn-minimal:not(:disabled):not(.disabled):active, .btn-warning.btn-minimal:not(:disabled):not(.disabled).active {
    border-color: #fcf22b;
    background: #fcf22b;
    color: rgba(33, 37, 41, 0.5);
    fill: rgba(33, 37, 41, 0.5); }

.btn-danger.btn-minimal {
  color: rgba(255, 255, 255, 0.35);
  fill: rgba(255, 255, 255, 0.35); }
  .btn-danger.btn-minimal:hover, .btn-danger.btn-minimal:focus, .btn-danger.btn-minimal.focus, .btn-danger.btn-minimal:not(:disabled):not(.disabled):active, .btn-danger.btn-minimal:not(:disabled):not(.disabled).active {
    border-color: #dc3545;
    background: #dc3545;
    color: rgba(255, 255, 255, 0.5);
    fill: rgba(255, 255, 255, 0.5); }

.btn-light.btn-minimal {
  color: rgba(33, 37, 41, 0.35);
  fill: rgba(33, 37, 41, 0.35); }
  .btn-light.btn-minimal:hover, .btn-light.btn-minimal:focus, .btn-light.btn-minimal.focus, .btn-light.btn-minimal:not(:disabled):not(.disabled):active, .btn-light.btn-minimal:not(:disabled):not(.disabled).active {
    border-color: #f5f6fa;
    background: #f5f6fa;
    color: rgba(33, 37, 41, 0.5);
    fill: rgba(33, 37, 41, 0.5); }

.btn-dark.btn-minimal {
  color: rgba(255, 255, 255, 0.35);
  fill: rgba(255, 255, 255, 0.35); }
  .btn-dark.btn-minimal:hover, .btn-dark.btn-minimal:focus, .btn-dark.btn-minimal.focus, .btn-dark.btn-minimal:not(:disabled):not(.disabled):active, .btn-dark.btn-minimal:not(:disabled):not(.disabled).active {
    border-color: #242939;
    background: #242939;
    color: rgba(255, 255, 255, 0.5);
    fill: rgba(255, 255, 255, 0.5); }

.btn-white.btn-minimal {
  color: rgba(33, 37, 41, 0.35);
  fill: rgba(33, 37, 41, 0.35); }
  .btn-white.btn-minimal:hover, .btn-white.btn-minimal:focus, .btn-white.btn-minimal.focus, .btn-white.btn-minimal:not(:disabled):not(.disabled):active, .btn-white.btn-minimal:not(:disabled):not(.disabled).active {
    border-color: #fff;
    background: #fff;
    color: rgba(33, 37, 41, 0.5);
    fill: rgba(33, 37, 41, 0.5); }

.input-group-append {
  margin-left: -2px; }

.input-group-prepend {
  margin-right: -2px; }
  .input-group-prepend + .form-control {
    padding-left: 1px; }

.custom-switch .custom-control-label::after {
  background-color: #d3d7e9; }

.badge-circle {
  position: relative;
  border-radius: 50%;
  padding: 0;
  vertical-align: middle;
  height: 1.2rem;
  width: 1.2rem;
  min-width: 1.2rem; }
  .badge-circle > i,
  .badge-circle > span {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 54%; }

.badge-top-left,
.badge-top-right,
.badge-top-center,
.badge-bottom-left,
.badge-bottom-right,
.badge-bottom-center {
  position: absolute;
  z-index: 1; }

.badge-top-left {
  top: 0;
  left: 0;
  transform: translate(-25%, -25%); }

.badge-top-right {
  top: 0;
  right: 0;
  transform: translate(25%, -25%); }

.badge-top-center {
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%); }

.badge-bottom-left {
  left: 0;
  bottom: 0;
  transform: translate(-25%, 25%); }

.badge-bottom-right {
  right: 0;
  bottom: 0;
  transform: translate(25%, 25%); }

.badge-bottom-center {
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 50%); }

.badge-border-basic {
  border: 5px solid #fff; }

.badge-border-basic-inverse {
  border: 5px solid #212529; }

.badge-border-primary {
  border: 5px solid #0176ff; }

.badge-border-secondary {
  border: 5px solid #edeef6; }

.badge-border-tertiary {
  border: 5px solid #aab0b7; }

.badge-border-success {
  border: 5px solid #42ba96; }

.badge-border-info {
  border: 5px solid #17a2b8; }

.badge-border-warning {
  border: 5px solid #fcf22b; }

.badge-border-danger {
  border: 5px solid #dc3545; }

.badge-border-light {
  border: 5px solid #f5f6fa; }

.badge-border-dark {
  border: 5px solid #242939; }

.badge-border-white {
  border: 5px solid #fff; }

.ongoingSection .badge-border-light {
    border: 0px solid #f5f6fa; }

.incomingSection .badge-border-light {
      border: 0px solid #f5f6fa; }

.badge-dot {
  font-size: 0;
  vertical-align: middle;
  padding: 0;
  border-radius: 50%;
  line-height: 0.375rem;
  height: 0.375rem;
  min-height: 0.375rem;
  width: 0.375rem;
  min-width: 0.375rem; }
  .badge-dot:empty {
    display: inline-block; }

.badge-sm {
  padding: 0.3625rem 0.45rem;
  font-size: 62.667%; }

.icon-shape {
  position: relative;
  display: inline-block;
  border-radius: 50%;
  font-size: 1.125rem;
  min-width: 2.75rem;
  height: 2.75rem;
  min-height: 2.75rem;
  width: 2.75rem;
  min-width: 2.75rem; }
  .icon-shape > i,
  .icon-shape > span {
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%; }

.bg-basic .icon-shape {
  background: #f2f2f2;
  color: #212529; }

.bg-basic-inverse .icon-shape {
  background: #16181b;
  color: #fff; }

.bg-primary .icon-shape {
  background: #006ae7;
  color: #fff; }

.bg-secondary .icon-shape {
  background: #dcdfed;
  color: #212529; }

.bg-tertiary .icon-shape {
  background: #9ca3ab;
  color: #fff; }

.bg-success .icon-shape {
  background: #3ba787;
  color: #fff; }

.bg-info .icon-shape {
  background: #148ea1;
  color: #fff; }

.bg-warning .icon-shape {
  background: #fcf012;
  color: #212529; }

.bg-danger .icon-shape {
  background: #d32535;
  color: #fff; }

.bg-light .icon-shape {
  background: #e4e7f2;
  color: #212529; }

.bg-dark .icon-shape {
  background: #1a1e29;
  color: #fff; }

.bg-white .icon-shape {
  background: #f2f2f2;
  color: #212529; }

.icon-sm {
  font-size: 1.0625rem !important; }

.icon-md {
  font-size: 1.1875rem !important; }

.icon-lg {
  font-size: 1.4375rem !important; }

.card-title {
  margin-bottom: 0.75rem; }

.card-body {
  padding: 0.5rem 0.25rem; }

.accordion > .card:not(:first-of-type):not(:last-of-type) {
  border-radius: 0.375rem; }

.accordion > .card:first-of-type {
  border-bottom: 1px solid rgba(245, 246, 250, 0.85);
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem; }

.accordion > .card:last-of-type {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem; }

@media (min-width: 992px) {
  .card-active-listener::before {
    background: #0176ff;
    position: absolute;
    content: "";
    height: 0px;
    width: 2px;
    top: 50%;
    left: -1px;
    transform: translateY(-50%);
    transition: all 0.2s ease-in-out;
    border-top-right-radius: 1px;
    border-bottom-right-radius: 1px; }
    .active > .card-active-listener::before {
      height: 20px; } }

.nav-tabs {
  background: #edeef6;
  border-radius: 0.375rem;
  padding: 0.34375rem; }
  .nav-tabs .nav-link {
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem; }
    .nav-tabs .nav-link:not(.active) {
      color: inherit; }
      .nav-tabs .nav-link:not(.active):hover {
        color: #212529; }
    .nav-tabs .nav-link.disabled {
      pointer-events: none;
      opacity: .5; }

.modified-accordion [data-toggle="collapse"] {
  cursor: default; }

.dropdown-menu {
  box-shadow: 0 0 1.875rem rgba(0, 0, 0, 0.05);
  padding: 0.625rem; }

.dropdown-item {
  border-radius: 0.375rem; }

.list-group-no-border-first .list-group-item:first-child {
  border-top: 0; }

.popover {
  box-shadow: 0 0 1.875rem rgba(0, 0, 0, 0.05); }

.popover-header {
  border-bottom: 1px solid rgba(245, 246, 250, 0.85); }

.close {
  opacity: .3; }
  .close:not(:disabled):not(.disabled):hover, .close:not(:disabled):not(.disabled):focus {
    opacity: .5; }

.close:focus,
.close:hover {
  outline: 0; }

.message {
  display: flex;
  align-items: flex-end; user-select: text; }
  .message + .message {
    margin-top: 1rem; }
  .message .avatar,
  .message .avatar > img {
    height: 2rem;
    min-height: 2rem;
    width: 2rem;
    min-width: 2rem; }
  .message .message-row + .message-row {
    margin-top: 1rem; }

.message-body {
  flex: 1; }





/* .message-content {
  border-radius: 0.625rem 0.625rem 0.625rem 2px;
  display: inline-block;
  padding: 1.125rem;    border: 1px solid #333; background: #fff; } */

  .message-content-new {
    border-radius: 0.625rem 0.625rem 0.625rem 2px;
    display: inline-block;
    padding: 1.125rem; font-size: 14px; text-align: left;white-space: pre-line; word-break: break-word; -webkit-word-break: break-word; -moz-word-break: break-word; user-select: text;}

    .message .message-content-new > a{
      color: #07f;
      text-decoration: underline;
    }

    .message-right .message-content-new > a{
      color: #fff;
      text-decoration: underline;
    }

  .message-right .message-row .message-content-new {
      border-radius: .625rem .625rem 2px .625rem;
  }

.message-right .avatar {
  order: 2; }

.message-right .message-body {
  order: 1; }

.message-right .message-row .message-content {
  border-radius: 0.625rem 0.625rem 2px 0.625rem; }



// .install-chat{
//     position: absolute;
//     width: 100%;
//     left: 50%;
//     top: 50%;
//     transform: translate(-50%, -50%);
//     -webkit-transform: translate(-50%, -50%);
//     -moz-transform: translate(-50%, -50%);
//     -ms-transform: translate(-50%, -50%);
//     -o-transform: translate(-50%, -50%);
// }

.chat-screen-details p a:hover{
  text-decoration: none;
  color: #4092fa;
}


@media (min-width: 992px) and (max-width: 1440px) {
  .message-content {
    max-width: 80%; }   
    .sidebar-is-open .message-content {
      max-width: 100%; } }

    
    #dropZoneSection{
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #ffffffe8;
        z-index: 9;
        border: 2px dashed #c7c7c7;
    }

    #dropZoneSection .dropFileDiv{
      position: absolute;
      width: 100%;
      height: 100%;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
  }

@media (min-width: 1200px) {
  .message .avatar,
  .message .avatar > img {
    height: 2.75rem;
    min-height: 2.75rem;
    width: 2.75rem;
    min-width: 2.75rem; }
  .message-divider {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .message-content {
    max-width: 80%; } }

.dropzone-image-preview {
  display: none; }

.dz-image-preview .dropzone-file-preview {
  display: none; }

.dz-image-preview .dropzone-image-preview {
  display: block; }

.chat-content + .chat-files {
  border-top: 1px solid rgba(245, 246, 250, 0.85);
  margin-bottom: -1px; }

.dropzone-previews-js:empty {
  display: none; }

  .chat-header{
    background: #fff;
    overflow: visible;
  }

  .chat-header h6.text-truncate{
    overflow: visible;
  }

.header-text-width{
  max-width: 275px;
}

/*
@font-face {
  font-family: 'Feather';
  src: url("../../assets/fonts/feather/feather.ttf") format("truetype"), url("../../assets/fonts/feather/feather.woff") format("woff"), url("../../assets/fonts/feather/feather.svg#feather") format("svg");
  font-weight: normal;
  font-style: normal; }
  */

[class*="fe-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Feather' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.fe-activity:before {
  content: "\e900"; }

.fe-airplay:before {
  content: "\e901"; }

.fe-alert-circle:before {
  content: "\e902"; }

.fe-alert-octagon:before {
  content: "\e903"; }

.fe-alert-triangle:before {
  content: "\e904"; }

.fe-align-center:before {
  content: "\e905"; }

.fe-align-justify:before {
  content: "\e906"; }

.fe-align-left:before {
  content: "\e907"; }

.fe-align-right:before {
  content: "\e908"; }

.fe-anchor:before {
  content: "\e909"; }

.fe-aperture:before {
  content: "\e90a"; }

.fe-archive:before {
  content: "\e90b"; }

.fe-arrow-down:before {
  content: "\e90c"; }

.fe-arrow-down-circle:before {
  content: "\e90d"; }

.fe-arrow-down-left:before {
  content: "\e90e"; }

.fe-arrow-down-right:before {
  content: "\e90f"; }

.fe-arrow-left:before {
  content: "\e910"; }

.fe-arrow-left-circle:before {
  content: "\e911"; }

.fe-arrow-right:before {
  content: "\e912"; }

.fe-arrow-right-circle:before {
  content: "\e913"; }

.fe-arrow-up:before {
  content: "\e914"; }

.fe-arrow-up-circle:before {
  content: "\e915"; }

.fe-arrow-up-left:before {
  content: "\e916"; }

.fe-arrow-up-right:before {
  content: "\e917"; }

.fe-at-sign:before {
  content: "\e918"; }

.fe-award:before {
  content: "\e919"; }

.fe-bar-chart:before {
  content: "\e91a"; }

.fe-bar-chart-2:before {
  content: "\e91b"; }

.fe-battery:before {
  content: "\e91c"; }

.fe-battery-charging:before {
  content: "\e91d"; }

.fe-bell:before {
  content: "\e91e"; }

.fe-bell-off:before {
  content: "\e91f"; }

.fe-bluetooth:before {
  content: "\e920"; }

.fe-bold:before {
  content: "\e921"; }

.fe-book:before {
  content: "\e922"; }

.fe-book-open:before {
  content: "\e923"; }

.fe-bookmark:before {
  content: "\e924"; }

.fe-box:before {
  content: "\e925"; }

.fe-briefcase:before {
  content: "\e926"; }

.fe-calendar:before {
  content: "\e927"; }

.fe-camera:before {
  content: "\e928"; }

.fe-camera-off:before {
  content: "\e929"; }

.fe-cast:before {
  content: "\e92a"; }

.fe-check:before {
  content: "\e92b"; }

.fe-check-circle:before {
  content: "\e92c"; }

.fe-check-square:before {
  content: "\e92d"; }

.fe-chevron-down:before {
  content: "\e92e"; }

.fe-chevron-left:before {
  content: "\e92f"; }

.fe-chevron-right:before {
  content: "\e930"; }

.fe-chevron-up:before {
  content: "\e931"; }

.fe-chevrons-down:before {
  content: "\e932"; }

.fe-chevrons-left:before {
  content: "\e933"; }

.fe-chevrons-right:before {
  content: "\e934"; }

.fe-chevrons-up:before {
  content: "\e935"; }

.fe-chrome:before {
  content: "\e936"; }

.fe-circle:before {
  content: "\e937"; }

.fe-clipboard:before {
  content: "\e938"; }

.fe-clock:before {
  content: "\e939"; }

.fe-cloud:before {
  content: "\e93a"; }

.fe-cloud-drizzle:before {
  content: "\e93b"; }

.fe-cloud-lightning:before {
  content: "\e93c"; }

.fe-cloud-off:before {
  content: "\e93d"; }

.fe-cloud-rain:before {
  content: "\e93e"; }

.fe-cloud-snow:before {
  content: "\e93f"; }

.fe-code:before {
  content: "\e940"; }

.fe-codepen:before {
  content: "\e941"; }

.fe-command:before {
  content: "\e942"; }

.fe-compass:before {
  content: "\e943"; }

.fe-copy:before {
  content: "\e944"; }

.fe-corner-down-left:before {
  content: "\e945"; }

.fe-corner-down-right:before {
  content: "\e946"; }

.fe-corner-left-down:before {
  content: "\e947"; }

.fe-corner-left-up:before {
  content: "\e948"; }

.fe-corner-right-down:before {
  content: "\e949"; }

.fe-corner-right-up:before {
  content: "\e94a"; }

.fe-corner-up-left:before {
  content: "\e94b"; }

.fe-corner-up-right:before {
  content: "\e94c"; }

.fe-cpu:before {
  content: "\e94d"; }

.fe-credit-card:before {
  content: "\e94e"; }

.fe-crop:before {
  content: "\e94f"; }

.fe-crosshair:before {
  content: "\e950"; }

.fe-database:before {
  content: "\e951"; }

.fe-delete:before {
  content: "\e952"; }

.fe-disc:before {
  content: "\e953"; }

.fe-dollar-sign:before {
  content: "\e954"; }

.fe-download:before {
  content: "\e955"; }

.fe-download-cloud:before {
  content: "\e956"; }

.fe-droplet:before {
  content: "\e957"; }

.fe-edit:before {
  content: "\e958"; }

.fe-edit-2:before {
  content: "\e959"; }

.fe-edit-3:before {
  content: "\e95a"; }

.fe-external-link:before {
  content: "\e95b"; }

.fe-eye:before {
  content: "\e95c"; }

.fe-eye-off:before {
  content: "\e95d"; }

.fe-facebook:before {
  content: "\e95e"; }

.fe-fast-forward:before {
  content: "\e95f"; }

.fe-feather:before {
  content: "\e960"; }

.fe-file:before {
  content: "\e961"; }

.fe-file-minus:before {
  content: "\e962"; }

.fe-file-plus:before {
  content: "\e963"; }

.fe-file-text:before {
  content: "\e964"; }

.fe-film:before {
  content: "\e965"; }

.fe-filter:before {
  content: "\e966"; }

.fe-flag:before {
  content: "\e967"; }

.fe-folder:before {
  content: "\e968"; }

.fe-folder-minus:before {
  content: "\e969"; }

.fe-folder-plus:before {
  content: "\e96a"; }

.fe-gift:before {
  content: "\e96b"; }

.fe-git-branch:before {
  content: "\e96c"; }

.fe-git-commit:before {
  content: "\e96d"; }

.fe-git-merge:before {
  content: "\e96e"; }

.fe-git-pull-request:before {
  content: "\e96f"; }

.fe-github:before {
  content: "\e970"; }

.fe-gitlab:before {
  content: "\e971"; }

.fe-globe:before {
  content: "\e972"; }

.fe-grid:before {
  content: "\e973"; }

.fe-hard-drive:before {
  content: "\e974"; }

.fe-hash:before {
  content: "\e975"; }

.fe-headphones:before {
  content: "\e976"; }

.fe-heart:before {
  content: "\e977"; }

.fe-help-circle:before {
  content: "\e978"; }

.fe-home:before {
  content: "\e979"; }

.fe-image:before {
  content: "\e97a"; }

.fe-inbox:before {
  content: "\e97b"; }

.fe-info:before {
  content: "\e97c"; }

.fe-instagram:before {
  content: "\e97d"; }

.fe-italic:before {
  content: "\e97e"; }

.fe-layers:before {
  content: "\e97f"; }

.fe-layout:before {
  content: "\e980"; }

.fe-life-buoy:before {
  content: "\e981"; }

.fe-link:before {
  content: "\e982"; }

.fe-link-2:before {
  content: "\e983"; }

.fe-linkedin:before {
  content: "\e984"; }

.fe-list:before {
  content: "\e985"; }

.fe-loader:before {
  content: "\e986"; }

.fe-lock:before {
  content: "\e987"; }

.fe-log-in:before {
  content: "\e988"; }

.fe-log-out:before {
  content: "\e989"; }

.fe-mail:before {
  content: "\e98a"; }

.fe-map:before {
  content: "\e98b"; }

.fe-map-pin:before {
  content: "\e98c"; }

.fe-maximize:before {
  content: "\e98d"; }

.fe-maximize-2:before {
  content: "\e98e"; }

.fe-menu:before {
  content: "\e98f"; }

.fe-message-circle:before {
  content: "\e990"; }

.fe-message-square:before {
  content: "\e991"; }

.fe-mic:before {
  content: "\e992"; }

.fe-mic-off:before {
  content: "\e993"; }

.fe-minimize:before {
  content: "\e994"; }

.fe-minimize-2:before {
  content: "\e995"; }

.fe-minus:before {
  content: "\e996"; }

.fe-minus-circle:before {
  content: "\e997"; }

.fe-minus-square:before {
  content: "\e998"; }

.fe-monitor:before {
  content: "\e999"; }

.fe-moon:before {
  content: "\e99a"; }

.fe-more-horizontal:before {
  content: "\e99b"; }

.fe-more-vertical:before {
  content: "\e99c"; }

.fe-move:before {
  content: "\e99d"; }

.fe-music:before {
  content: "\e99e"; }

.fe-navigation:before {
  content: "\e99f"; }

.fe-navigation-2:before {
  content: "\e9a0"; }

.fe-octagon:before {
  content: "\e9a1"; }

.fe-package:before {
  content: "\e9a2"; }

.fe-paperclip:before {
  content: "\e9a3"; }

.fe-pause:before {
  content: "\e9a4"; }

.fe-pause-circle:before {
  content: "\e9a5"; }

.fe-percent:before {
  content: "\e9a6"; }

.fe-phone:before {
  content: "\e9a7"; }

.fe-phone-call:before {
  content: "\e9a8"; }

.fe-phone-forwarded:before {
  content: "\e9a9"; }

.fe-phone-incoming:before {
  content: "\e9aa"; }

.fe-phone-missed:before {
  content: "\e9ab"; }

.fe-phone-off:before {
  content: "\e9ac"; }

.fe-phone-outgoing:before {
  content: "\e9ad"; }

.fe-pie-chart:before {
  content: "\e9ae"; }

.fe-play:before {
  content: "\e9af"; }

.fe-play-circle:before {
  content: "\e9b0"; }

.fe-plus:before {
  content: "\e9b1"; }

.fe-plus-circle:before {
  content: "\e9b2"; }

.fe-plus-square:before {
  content: "\e9b3"; }

.fe-pocket:before {
  content: "\e9b4"; }

.fe-power:before {
  content: "\e9b5"; }

.fe-printer:before {
  content: "\e9b6"; }

.fe-radio:before {
  content: "\e9b7"; }

.fe-refresh-ccw:before {
  content: "\e9b8"; }

.fe-refresh-cw:before {
  content: "\e9b9"; }

.fe-repeat:before {
  content: "\e9ba"; }

.fe-rewind:before {
  content: "\e9bb"; }

.fe-rotate-ccw:before {
  content: "\e9bc"; }

.fe-rotate-cw:before {
  content: "\e9bd"; }

.fe-rss:before {
  content: "\e9be"; }

.fe-save:before {
  content: "\e9bf"; }

.fe-scissors:before {
  content: "\e9c0"; }

.fe-search:before {
  content: "\e9c1"; }

.fe-send:before {
  content: "\e9c2"; }

.fe-server:before {
  content: "\e9c3"; }

.fe-settings:before {
  content: "\e9c4"; }

.fe-share:before {
  content: "\e9c5"; }

.fe-share-2:before {
  content: "\e9c6"; }

.fe-shield:before {
  content: "\e9c7"; }

.fe-shield-off:before {
  content: "\e9c8"; }

.fe-shopping-bag:before {
  content: "\e9c9"; }

.fe-shopping-cart:before {
  content: "\e9ca"; }

.fe-shuffle:before {
  content: "\e9cb"; }

.fe-sidebar:before {
  content: "\e9cc"; }

.fe-skip-back:before {
  content: "\e9cd"; }

.fe-skip-forward:before {
  content: "\e9ce"; }

.fe-slack:before {
  content: "\e9cf"; }

.fe-slash:before {
  content: "\e9d0"; }

.fe-sliders:before {
  content: "\e9d1"; }

.fe-smartphone:before {
  content: "\e9d2"; }

.fe-speaker:before {
  content: "\e9d3"; }

.fe-square:before {
  content: "\e9d4"; }

.fe-star:before {
  content: "\e9d5"; }

.fe-stop-circle:before {
  content: "\e9d6"; }

.fe-sun:before {
  content: "\e9d7"; }

.fe-sunrise:before {
  content: "\e9d8"; }

.fe-sunset:before {
  content: "\e9d9"; }

.fe-tablet:before {
  content: "\e9da"; }

.fe-tag:before {
  content: "\e9db"; }

.fe-target:before {
  content: "\e9dc"; }

.fe-terminal:before {
  content: "\e9dd"; }

.fe-thermometer:before {
  content: "\e9de"; }

.fe-thumbs-down:before {
  content: "\e9df"; }

.fe-thumbs-up:before {
  content: "\e9e0"; }

.fe-toggle-left:before {
  content: "\e9e1"; }

.fe-toggle-right:before {
  content: "\e9e2"; }

.fe-trash:before {
  content: "\e9e3"; }

.fe-trash-2:before {
  content: "\e9e4"; }

.fe-trending-down:before {
  content: "\e9e5"; }

.fe-trending-up:before {
  content: "\e9e6"; }

.fe-triangle:before {
  content: "\e9e7"; }

.fe-truck:before {
  content: "\e9e8"; }

.fe-tv:before {
  content: "\e9e9"; }

.fe-twitter:before {
  content: "\e9ea"; }

.fe-type:before {
  content: "\e9eb"; }

.fe-umbrella:before {
  content: "\e9ec"; }

.fe-underline:before {
  content: "\e9ed"; }

.fe-unlock:before {
  content: "\e9ee"; }

.fe-upload:before {
  content: "\e9ef"; }

.fe-upload-cloud:before {
  content: "\e9f0"; }

.fe-user:before {
  content: "\e9f1"; }

.fe-user-check:before {
  content: "\e9f2"; }

.fe-user-minus:before {
  content: "\e9f3"; }

.fe-user-plus:before {
  content: "\e9f4"; }

.fe-user-x:before {
  content: "\e9f5"; }

.fe-users:before {
  content: "\e9f6"; }

.fe-video:before {
  content: "\e9f7"; }

.fe-video-off:before {
  content: "\e9f8"; }

.fe-voicemail:before {
  content: "\e9f9"; }

.fe-volume:before {
  content: "\e9fa"; }

.fe-volume-1:before {
  content: "\e9fb"; }

.fe-volume-2:before {
  content: "\e9fc"; }

.fe-volume-x:before {
  content: "\e9fd"; }

.fe-watch:before {
  content: "\e9fe"; }

.fe-wifi:before {
  content: "\e9ff"; }

.fe-wifi-off:before {
  content: "\ea00"; }

.fe-wind:before {
  content: "\ea01"; }

.fe-x:before {
  content: "\ea02"; }

.fe-x-circle:before {
  content: "\ea03"; }

.fe-x-square:before {
  content: "\ea04"; }

.fe-youtube:before {
  content: "\ea05"; }

.fe-zap:before {
  content: "\ea06"; }

.fe-zap-off:before {
  content: "\ea07"; }

.fe-zoom-in:before {
  content: "\ea08"; }

.fe-zoom-out:before {
  content: "\ea09"; }

.emoji-picker {
  background: #fff;
  border: 1px solid #fff;
  border-radius: 0.375rem;
  box-shadow: 0 0 1.875rem rgba(0, 0, 0, 0.05);
  width: 320px;
  opacity: 0;
  transition: opacity 0.3s;
  margin: 0 0.5em 1rem;
  overflow: hidden; }

.emoji-picker.visible {
  opacity: 1; }

.emoji-picker__content {
  height: 20rem;
  overflow: hidden;
  position: relative; }

.emoji-picker__preview {
  height: 3.5rem;
  padding: 0.75rem;
  border-top: 1px solid rgba(245, 246, 250, 0.85);
  display: flex;
  flex-direction: row;
  align-items: center; }

.emoji-picker__preview-emoji {
  font-size: 2rem;
  margin-right: 0.25em;
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "EmojiOne Color", "Android Emoji"; }

.emoji-picker__preview-name {
  font-size: 0.85em;
  overflow-wrap: break-word;
  word-break: break-all; }

.emoji-picker__tabs-container {
  border-bottom: 1px solid rgba(245, 246, 250, 0.85); }

.emoji-picker__tabs {
  margin: 0;
  padding: 0;
  display: flex;
  padding: 0 0.75rem; }

.emoji-picker__tab {
  font-size: 1.5rem;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-grow: 1;
  text-align: center;
  border-radius: 3px;
  transition: background 0.25s;
  position: relative; }
  .emoji-picker__tab::after {
    content: "";
    width: 100%;
    background: transparent;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0; }
  .emoji-picker__tab > svg {
    padding: 0.5rem;
    width: 100%;
    height: 34px; }

.emoji-picker__tab:hover::after,
.emoji-picker__tab.active::after {
  background: #0176ff; }

.emoji-picker__tab-body {
  margin-top: 0.5em;
  transform: translateX(25rem);
  transition: transform 0.25s;
  position: absolute;
  overflow-y: scroll;
  height: calc(100% - 42px);
  width: 100%; }
  .emoji-picker__tab-body {
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: auto;
    overflow-x: hidden; }
  .emoji-picker__tab-body::-webkit-scrollbar {
    display: none; }

.emoji-picker__tab-body h2 {
  font-size: 0.85rem;
  margin: 0;
  text-align: left;
  padding: 0.75rem;
  border-bottom: 1px solid rgba(245, 246, 250, 0.85); }

.emoji-picker__tab-body.active {
  display: block;
  transform: translateX(0); }

.emoji-picker__emojis {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  width: auto;
  width: 100%;
  margin: auto;
  padding: 0.75rem; }

.emoji-picker__emojis.search-results {
  height: 21rem; }

.emoji-picker__emoji {
  background: transparent;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.5rem;
  width: 11.11%;
  padding: 0;
  margin: 0;
  outline: none;
  font-family: "Segoe UI Emoji", "Segoe UI Symbol", "Segoe UI", "Apple Color Emoji", "Twemoji Mozilla", "Noto Color Emoji", "EmojiOne Color", "Android Emoji"; }

.emoji-picker__emoji:focus, .emoji-picker__emoji:hover {
  background: #f5f6fa; }

.emoji-picker__search-container {
  padding: 0.75rem;
  position: relative;
  height: auto !important;
  display: flex;
  border-bottom: 1px solid rgba(245, 246, 250, 0.85); }

.emoji-picker__search-icon {
  display: none; }

.emoji-picker__search-not-found {
  text-align: center;
  margin-top: 2em; }

.emoji-picker__search-not-found-icon {
  font-size: 3em; }
  .emoji-picker__search-not-found-icon > svg {
    width: 40px;
    height: 40px; }

.emoji-picker__search-not-found h2 {
  margin: 0.5em 0;
  font-size: 1em; }

.emoji-picker__variant-overlay {
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 23rem;
  width: 100%;
  height: 27.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; }

.emoji-picker__variant-popup {
  background: #fff;
  border-radius: 0.375rem;
  margin: 0.5em;
  padding: 0.5em;
  text-align: center; }

.emoji-picker__variant-popup-close-button {
  cursor: pointer;
  background: transparent;
  border: none;
  position: absolute;
  right: 1em;
  padding: 0;
  top: calc(50% - 0.5em);
  height: 1em;
  width: 1em; }
  .emoji-picker__variant-popup-close-button > svg {
    width: 9px;
    display: block;
    color: #212529; }

img[data-action="zoom"] {
  cursor: zoom-in; }

.zoom-img,
.zoom-img-wrap {
  position: relative;
  z-index: 1050;
  transition: all 300ms; }

img.zoom-img {
  cursor: zoom-out; }

.zoom-overlay {
  cursor: zoom-out;
  z-index: 1049;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: "alpha(opacity=0)";
  opacity: 0;
  transition: opacity 300ms; }

.zoom-overlay-open .zoom-overlay {
  filter: "alpha(opacity=100)";
  opacity: 1; }

.opacity-0 {
  opacity: 0 !important; }

.opacity-5 {
  opacity: 0.05 !important; }

.opacity-10 {
  opacity: 0.1 !important; }

.opacity-15 {
  opacity: 0.15 !important; }

.opacity-20 {
  opacity: 0.2 !important; }

.opacity-25 {
  opacity: 0.25 !important; }

.opacity-30 {
  opacity: 0.3 !important; }

.opacity-35 {
  opacity: 0.35 !important; }

.opacity-40 {
  opacity: 0.4 !important; }

.opacity-45 {
  opacity: 0.45 !important; }

.opacity-50 {
  opacity: 0.5 !important; }

.opacity-55 {
  opacity: 0.55 !important; }

.opacity-60 {
  opacity: 0.6 !important; }

.opacity-65 {
  opacity: 0.65 !important; }

.opacity-70 {
  opacity: 0.7 !important; }

.opacity-75 {
  opacity: 0.75 !important; }

.opacity-80 {
  opacity: 0.8 !important; }

.opacity-85 {
  opacity: 0.85 !important; }

.opacity-90 {
  opacity: 0.9 !important; }

.opacity-95 {
  opacity: 0.95 !important; }

.opacity-100 {
  opacity: 1 !important; }

.fill-basic {
  fill: #fff !important; }

.fill-basic-inverse {
  fill: #212529 !important; }

.fill-primary {
  fill: #0176ff !important; }

.fill-secondary {
  fill: #edeef6 !important; }

.fill-tertiary {
  fill: #aab0b7 !important; }

.fill-success {
  fill: #42ba96 !important; }

.fill-info {
  fill: #17a2b8 !important; }

.fill-warning {
  fill: #fcf22b !important; }

.fill-danger {
  fill: #dc3545 !important; }

.fill-light {
  fill: #f5f6fa !important; }

.fill-dark {
  fill: #242939 !important; }

.fill-white {
  fill: #fff !important; }









  /*-----anash write css-----*/

button.btn-cus{
  margin: 0 !important;
  box-shadow: inherit !important;

}
button.btn-cus:hover{
  margin: 0 !important;
  box-shadow: inherit !important;
}
button.btn-cus:focus{
  margin: 0 !important;
  box-shadow: inherit !important;
}
button.btn-cus:active{
  margin: 0 !important;
  box-shadow: inherit !important;
}
button.btn-cus:focus-within{
  margin: 0 !important;
  box-shadow: inherit !important;
}
button.btn-cus:focus-visible{
  margin: 0 !important;
  box-shadow: inherit !important;
}

.custom-active-chat-user{
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;
}

.custom-active-chat-user .chat-ID-feilds{
  list-style: none;
  display: flex;
  align-content: center;
  justify-content: center;
  width: 100%;
  padding: 6px 10px;
  font-size: 11px;
  border: 1px solid #7f808c;
  color: #7f808c;
  border-radius: 2px;
  position: relative;
}

.custom-active-chat-user .chat-ID-feilds.active, .custom-active-chat-user .chat-ID-feilds:hover{
    background: #7f808c;
    color: #fff;
    cursor: pointer;
}

.custom-active-chat-user .badge-top-right {
  top: -15px;
  right: 0;
  height: 1.15rem;
  width: 1.15rem;
  min-width: 1.15rem;
  border: 0;
  transform: inherit;
  -webkit-transform: inherit;
  -moz-transform: inherit;
  -ms-transform: inherit;
  -o-transform: inherit;
}

.user-showings{
  position: absolute;
  right: 34px;
  width: 100px;
  top: 0;
}

.user-showings li {
  width: 30px;
  height: 30px;
  display: inline-block;
  list-style: none;
  background: #333;
  line-height: 30px;
  text-align: center;
  font-size: 10px;
  border-radius: 100px;
  color: #fff;
  margin-left: -17px;
  cursor: pointer;
}

.user-showings li img{ width: 100%; height: 100%; border-radius:100% ; -webkit-border-radius:100% ; -moz-border-radius:100% ; -ms-border-radius:100% ; -o-border-radius:100% ; }

.user-hover-show{
  background: #fff;
  width: 150px;
  position: absolute;
  border-radius: 10px;
  box-shadow: 0px 1px 3px 1px rgba(128, 128, 128, 0.19);
  text-align: center;
  padding: 10px 10px;
  visibility: hidden;
  z-index: 3;
  left: 0;
  right: 0;
}

.user-showings li:hover .user-hover-show{
  visibility: visible;
}

.served-agents {
  right: 0;
  left:auto;
}

.chat-sidebar ul.nav li.nav-item .nav-link{
  width: 30px;
  height: 30px;
  background: #3E4360;
  padding: 6px 6px;
  border-radius: 100%;
  line-height: 15px;
  text-align: center;
  cursor: pointer;
}

.selctVisitoreRight{
  border: 1px solid #E5DEFF;
  padding: 5px 15px;
  font-size: 14px;
  text-align: left;
  border-radius: 4px;
  display: inline-block;
  clear: both;
  cursor: pointer;
  background: #fff;
  font-weight: normal;
}

.font-ServedVistor{
  color: #4CAF50;
  font-size: 15px;
  font-weight: bold;
}

.font-ActiveVistor{
  color:#4092FB;
  font-size: 15px;
  font-weight: bold;
}

.visitoresDropdown span{
  cursor: pointer;
  font-size: 14px;
  color: #333;
  font-weight: normal;
}

.visitoresDropdown span strong{
  font-size: 16px;
}

.visRightHeadeOnly{
  background: #fbfaff;
  position: relative;
  z-index: 2;
}

.maps-all > li{
  list-style: none;
  display: inline-block;
  background-color: #ddd;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  margin-left: 2px;
  position: relative;
}

.maps-all > li.flag{
  border: 1px solid #ddd;
}

#chat1-members .maps-all > li .titleShowHover{
  width: auto;
}

#chat1-members .maps-all > li .titleShowHover h6{
  white-space: nowrap;
}

#right_normal_ul li:last-child .maps-all > li .titleShowHover, #right_premium_ul li:last-child .maps-all > li .titleShowHover{
  right: 25px;
  top: -4px;
  bottom: auto;
}

// .maps-all > li > .titleShowHover{
//   width: auto;
// }

.maps-all > li:hover .titleShowHover{
  display: block;
}

.maps-all{
  margin: 0;
  padding: 0;
  line-height: 0;
}

.maps-all .flag{
  background-size: 18px 18px;
  background-position: 0px 0px;
  border: 1px solid rgba(51, 51, 51, 0.14);
}

.name-chat-header{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.listing-right-panel-information{
  border-bottom: 1px solid rgba(51, 51, 51, 0.15);
  cursor: pointer;
}

.listing-right-panel-information p.small.mb-0.text-screen1.name-chat-header{
  font-family: 'HelveticaNeueLight';
  font-size: 12px;
}

.left-arrow {
  left:  0;
  border-radius: 4px 0px 0px 4px;
  -webkit-border-radius: 4px 0px 0px 4px;
  -moz-border-radius: 4px 0px 0px 4px;
  -ms-border-radius: 4px 0px 0px 4px;
  -o-border-radius: 4px 0px 0px 4px;
}

.right-arrow {
  right:  0;
  border-radius: 0px 4px 4px 0px;
  -webkit-border-radius: 0px 4px 4px 0px;
  -moz-border-radius: 0px 4px 4px 0px;
  -ms-border-radius: 0px 4px 4px 0px;
  -o-border-radius: 0px 4px 4px 0px;
}

.arrow-user-id{
  background: #9092A9;
  width: 25px;
  height: 31px;
  text-align: center;
  padding: 3px 0px;
  position: absolute;
  cursor: pointer;
  z-index: 1;
}


.visitor-phone-icon{
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: center;
}


/*--------start flag list design-----*/

.visitor-flags{
  background-repeat: no-repeat;
  background-size: 45px 45px;
  background-position: 0px 0px;
}

.all-flas{
  background-repeat: no-repeat;
  background-size: 18px 18px;
  background-position: 0px 0px;
}

.us{
  background-image: url('../images/flag/united-states.png');
  background-size: 100% 100%;
}
.window{
  background-image: url('../images/flag/window.png');
  background-size: 100% 100%;
}

.mac{
  background-image: url('../images/flag/mac.png');
  background-size: 100% 100%;
}
.brave{
  background-image: url('../images/flag/brave.png');
  background-size: 100% 100%;
}
.chrome{
  background-image: url('../images/flag/chrome.png');
  background-size: 100% 100%;
}
.chrome-webview{
  background-image: url('../images/flag/chrome.png');
  background-size: 100% 100%;
}


/*----------end flag list design---------*/

/*--------------------Added By AG Start -------------------------*/
/*---------------------- operating system style start -----------------------------*/
//Default Icon -----------------------------------//
.os-default{
  background: url('../os/os-default.svg');
  background-size: 27px 24px !important;
  background-position: -4px -2px !important;
}
.browser-default{
  background: url('../browser/browser-default.svg');
  background-size: 27px 24px !important;
  background-position: -4px -2px !important;
}

.flag-icon-country-default{
  //country-default.svg
  background: url('../flags/country-default.svg');
  background-size: 15px 24px !important;
  background-position: 3px -2px !important;
}
.flag-icon-country-not-detected{
  //country-default.svg
  background: url('../flags/country-default.svg');
  background-size: 15px 24px !important;
  background-position: 3px -2px !important;
}
.flag-icon-round-country-not-detected{
  //country-default.svg
  background: url('../flags/country-default.svg');
  background-size: 15px 24px !important;
  background-position: 3px -2px !important;
}

// Operating System Icon -------------------------//
.android{
  background: url('../os/android.svg');
  background-size: 32px 24px !important;
  background-position: -6px -2px !important;
}
.linux{
  background: url('../os/linux.svg');
  background-size: 32px 24px !important;
  background-position: -6px -1px !important;
}
.mac-os{
  background: url('../os/mac-os.svg');
  background-size: 30px 26px !important;
  background-position: -5px -3px !important;
}
.ios{
  background: url('../os/mac-os.svg');
  background-size: 30px 26px !important;
  background-position: -5px -3px !important;
}
.ubuntu{
  background: url('../os/ubuntu.svg');
  background-size: 17px 24px !important;
  background-position: 0px -2px !important;
}
.chromium-os{
  background: url('../os/chromium-os.svg');
  background-size: 22px 20px !important;
  background-position: -1px 0px !important;
}
.windows{
  background: url('../os/windows.svg');
  background-size: 27px 24px !important;
  background-position: -4px -2px !important;
}

.fedora{
  background: url('../os/fedora.svg');
  background-size: 30px 24px !important;
  background-position: -4px -2px !important;
}

.mac-intel{
  background: url('../os/mac-os.svg');
  background-size: 30px 26px !important;
  background-position: -5px -3px !important;
} 




/*---------------------- operating system style End -----------------------------*/

/*---------------------- Browser system style Start -----------------------------*/
.chrome{
  background: url('../browser/chrome.svg');
  background-size: 38px 26px !important;
  background-position: -9px -3px !important;
}
.ie{
  background: url('../browser/ie.svg');
  background-size: 27px 24px !important;
  background-position: -4px -2px !important;
}
.firefox{
  background: url('../browser/firefox.svg');
  background-size: 40px 25px !important;
  background-position: -10px -2px !important;
}
.safari{
  background: url('../browser/safari.svg');
  background-size: 40px 27px !important;
  background-position: -10px -3px !important;
}
.mobile-safari{
  background: url('../browser/safari.svg');
  background-size: 40px 27px !important;
  background-position: -10px -3px !important;
}
.uc-browser{
  background: url('../browser/uc-browser.svg');
  background-size: 27px 24px !important;
  background-position: -4px -2px !important;
}
.opera{
  background: url('../browser/opera.svg');
  background-size: 30px 25px !important;
  background-position: -5px -2px !important;
}
.facebook{
  background: url('../browser/facebook.svg');
  background-size: 29px 23px !important;
  background-position: -6px -3px !important;
}
.samsung-browser{
  background: url('../browser/samsung.svg');
  background-size: 28px 25px !important;
  background-position: -4px -2px !important;
}

.edge{
  background: url('../browser/edge.svg');
  background-size: 40px 40px !important;
  background-position: -10px -10px !important;
}
.chromium-browser{
  background: url('../browser/chromium-browser.svg');
  background-size: 44px 44px !important;
  background-position: -12px -12px !important;
}
.miui-browser{
  background: url('../browser/miui-browser.svg');
  background-size: 65px 62px !important;
  background-position: -22px -21px !important;
}

.yandex{    
  background: url('../browser/yandex.svg');
  background-size: 40px 40px !important;
  background-position: -10px -10px !important;
}  


.gsa{
  background: url('../browser/gsa.svg');
  background-size: 20px 20px !important;
  background-position: 0px 0px !important;
} 

.chrome-headless{
  background: url('../browser/chrome.svg');
  background-size: 38px 26px !important;
  background-position: -9px -3px !important;
}

.android-browser{
  background: url('../browser/android-browser.svg');
  background-size: 38px 26px !important;
  background-position: -9px -3px !important;
}
.maxton{
  background: url('../browser/maxton.svg');
  background-size: 28px 20px !important;
  background-position: -4px 0px !important;
}
.silk{
  background: url('../browser/silk.svg');
  background-size: 34px 28px !important;
  background-position: -7px -4px !important;
}
.opera-touch{
  background: url('../browser/opera-touch.svg');
  background-size: 36px 28px !important;
    background-position: -8px -4px !important;
}
.webkit{
  background: url('../browser/webkit.svg');
  background-size: 36px 32px !important;
  background-position: -8px -6px !important;
}

.vivaldi{
  background: url('../browser/vivaldi.svg');
  background-size: 28px 28px !important;
  background-position: -4px -4px !important;
}

/*---------------------- Browser system style End -----------------------------*/


.ongoingSection .flag-icon-round-country-not-detected, .incomingSection .flag-icon-round-country-not-detected{
  background-size: 45px 45px !important;
  background-repeat: no-repeat;
  background-position: 0px 0px !important;
}

.premiumuser::before{
  content: '';
  background-image: url('../img/premium.svg');
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 16px;
  display: inline-block;
  margin-right: 5px;
  background-repeat: no-repeat;
  max-width: 15px;
  min-width: 15px;
  position: relative;
  top: 3px;
}
 

/* suggestions css start */
.suggestions {
  border: 1px solid rgba(51, 51, 51, 0.09);
  border-top-width: 0;
  list-style: none;
  margin-top: 0;
  max-height: 143px;
  overflow-y: auto;
  padding-left: 0;
  position: absolute;
  left: 0;
  bottom: 80px;
  width: 100%;
  background: #fff;
  box-shadow: 0 -5px 6px 0 rgba(32, 33, 36, 0.05);
  border-radius: 15px 15px 0px 0px;
  -webkit-border-radius: 15px 15px 0px 0px;
  -moz-border-radius: 15px 15px 0px 0px;
  -ms-border-radius: 15px 15px 0px 0px;
  -o-border-radius: 15px 15px 0px 0px;
}

.suggestions > li{
  list-style: none;
  font-size: 14px;
  padding: 8px 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-top: 1px solid rgba(51, 51, 51, 0.09);
  cursor: pointer;
}

.suggestions > li:hover{
  background: #2b3049;
  color: #fff;
}

.suggestions > li.suggestion-active{
  background: #2b3049;
  color: #fff;
}

/* suggestions css End */

/*--------------------Added By AG End -------------------------*/

.visitore-filter-tag > li{
  font-size: 12px;
  border: 1px solid #8f92a9;
  padding: 3px 8px;
  border-radius: 100px;
  margin-right: 2px;
  line-height: 22px;
  height: 29px;
}
  
.visitore-filter-tag > li span.name-filt-title{
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-flex;
}

.cursor-pointer{
  cursor: pointer;
}

.visitore-filter-tag li:nth-child(3){
  background: #4092FB;
  border: 0;
  color: #fff;
  padding: 0;
  width: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 8px;
  cursor: pointer;
}

.visitore-filter-tag li:nth-child(3) > span{
  display: none;
}

.header-flag-safari .titleShowHover{
  width: auto;
}

.visitore-list{
  position: absolute;
  width: 150px;
  background: #fff;
  padding: 10px 10px;
  box-shadow: 0px 2px 4px rgba(51, 51, 51, 0.17);
  text-align: left;
  left: 0;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
  visibility: hidden;
}

.visitore-filter-tag li:last-child:hover .visitore-list{
  visibility: visible;
  z-index: 9;
  left: auto;
  right: 0;
}

#chat-1-members.chat-sidebar{
  visibility: visible !important;
  max-width: 100%;
  min-width: 370px;
  width: 370px;
}


#chat-1-user-profile.chat-sidebar{
  visibility: visible !important;
  max-width: 100%;
  min-width: 370px;
  width: 370px;
}

#chat-1-filter.chat-sidebar{
  visibility: visible !important;
  max-width: 100%;
  min-width: 370px;
  width: 370px;
}

#chat-visitore-nav.chat-sidebar{
  visibility: visible !important;
  max-width: 60px;
  min-width: 60px;
  width: 100%;
  z-index: 2;
}

#endchatwindow.chat-sidebar{
  visibility: visible !important;
  max-width: 100%;
  min-width: 370px;
  width: 370px;
}  

.right-panel-visitore ul.nav li a{
  width: 100%;
}

.list-none > li{
  list-style: none;
}

.visitore-countrey{
  box-shadow: 0px 1px 4px 2px rgba(128, 128, 128, 0.16);
  border-radius: 0px 0px 10px 10px;
}

.country-names{
  background: #1C223A;
  color: #fff;
  border-radius: 10px 10px 0px 0px;
  -webkit-border-radius: 10px 10px 0px 0px;
  -moz-border-radius: 10px 10px 0px 0px;
  -ms-border-radius: 10px 10px 0px 0px;
  -o-border-radius: 10px 10px 0px 0px;
}

.country-names h6{
  color: #fff;
}

.navbar-light .navbar-nav a.nav-link{
  width: 100%;
  display: inline-block;
  text-align: center;
  background: transparent;
  position: relative !important; 
  padding: 0px 5px !important;
  height: 70px !important;
  overflow: visible;
  line-height: 35px;
}
.navbar-light .navbar-nav .profile-icon a.nav-link {height: 45px !important;}

.navbar-light .navbar-nav a.nav-link span{
  display: inline-block;
}

.navbar-light .navbar-nav a.nav-link span svg{
  width: 20px;
}
.navbar-light .navbar-nav a.nav-link .integration-icon svg {
    width: 25px;
}

.navbar-light .navbar-nav a.nav-link span.after-icon{
  display: none;
}

.navbar-light .navbar-nav a.nav-link.activeNavigation span.after-icon {
  display: block;
}

.navbar-light .navbar-nav a.nav-link.activeNavigation span.before-icon {
  display: none;
}

.navbar-light .navbar-nav .nav-link.active{
  background: #2C3049;
}

.activeNavigation > span::after{
  content: url('../images/topShape.png');
  position: absolute;
  right: 0;
  bottom: -29px;
}


.activeNavigation > span::before{
  content: url('../images/bottomShape.png');
  position: absolute;
  right: 0;
  top: -23px;
}

.activeNavigation {
  background: #2c3049 !important;
}

.btn-visitore{
  padding: 4px 14px;
  font-size: 13px;
  border-radius: 4px;
  cursor: pointer;
}

.btn-blacktheme{
  background: #3E4360;
  color: #fff;
  border: 1px solid #3E4360;
}

.btn-empty{
  background: #fff;
  color: #3E4360;
  border: 1px solid #3E4360;
}

.filter-right-panel .left-filter-nav{
  width: 120px;
  background: #E9E5F7;
  height: calc(100vh - 63px);
}

.filter-right-panel .right-filter-nav{
  width: calc(100% - 120px);
}

.filter-right-panel{
  background: #FBFAFF;
  width: 100%;
  height: 100%;
}

.filter-right-panel .left-filter-nav > ul > li > a{
  color: #25265E;
  padding: 10px 10px;
  font-size: 14px;
  display: block;
  cursor: pointer;
}

.filter-right-panel .left-filter-nav > ul > li > span{
  color: #25265E;
  padding: 10px 10px;
  font-size: 14px;
  display: block;
  cursor: pointer;
}

.filter-right-panel .left-filter-nav > ul > li > a:hover, .filter-right-panel .left-filter-nav > ul > li > span:hover{
  text-decoration: none;
}

.filter-right-panel .left-filter-nav > ul > li > a.active, .filter-right-panel .left-filter-nav > ul > li > span.active{
  background: #FBFAFF;
}

.right-filter-nav li.active .country-names-listing{
  background: #0077ff;
  color: #fff;
}

.right-filter-nav li.active .country-names-listing p.text-screen1{
  color: #fff !important;
}

.ong-inc-chat-ended{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  // z-index: 5; commented by dharmesh for onboarding guide
  z-index: 3;
  display: none;
}

.layout-not-liveChat .liveChatHeadrMobil {
  display: none;
}

.layout .liveChatHeadrMobil {
  display: none;
  position: fixed;
  background: #fff;
  z-index: 9999;
  width: 100%;
  height: 50px;
  align-items: center;
  padding: 0px 10px;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 1px 1px rgba(51, 51, 51, 0.19);
}

.re-connecting{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  background: #fbbc06;
  z-index: 9;
  padding: 15px 10px;
  box-shadow: 0px 2px 4px 0px rgba(128, 128, 128, 0.38);
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notifBlock .notif-block-dis{
  width: calc(100% - 25px);
  float: left;
}

.re-connecting.notifBlock{
  z-index: 5;
}


.ong-inc-chat{
  width: 50%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.mobile-leftRightPanelDisplay{
  display: none;
}

.liveChat-question-answer > li{
  list-style: none;
  padding: 10px 10px;
}

.liveChat-question-answer > li > h5 > strong {
  width: 60px;
  max-width: 60px;
  min-width: 60px;
}

.liveChat-question-answer > li > p > strong {
  width: 60px;
  max-width: 60px;
  min-width: 60px;
}


/*-------statr file attched design css-------*/

.file-attched-complete-drop{
  background: #fff;
  width: 130px;
  height: 110px;
  padding: 5px 5px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  margin: 5px 5px;
}

.file-attched-complete-drop .iconNameDropFile{
  margin-bottom: auto;
}

.file-attched-complete-drop .fileSendAttchedName {
  display: block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.file-attched-complete-drop .fileSendAttchedName .fileAttchedOnlyName{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  font-weight: bold;
  font-size: 13px;
}

.file-attched-complete-drop .fileSendAttchedSize{
  margin-top: auto;
  font-size: 11px;
}

.file-attched-complete-drop a{
  position: absolute;
  top: 5px;
  right: 5px;
}

.delete-drag-file{
  background: #A6A6A6;
  width: 19px;
  height: 19px;
  line-height: 18px;
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
}

.delete-drag-file svg{
  width: 10px;
}


.newfileUploadersTiles{
  background: #F1F1F4;
  border-radius: 20px 20px 0px 0px;
  max-height: 370px;
  overflow-y: auto;
  overflow-x: hidden;
}

.newfileUploadersTiles .newInnerFileUploadersTiles{
  border-bottom: 2px solid #000;
}

.attachedAddButton{
  background: #8B8D91;
  width: 60px;
  height: 110px;
  padding: 10px 15px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  margin: 5px 5px;
  cursor: pointer;
}

.attachedAddButton img{
  width: 100%;
  height: 100%;
}

/*
.file-type-bg{
  background: #f1f1f4; 
}
*/
      

.media .msgfilesNew a{
  display: inline-block;  
  width: 180px;
  height: 150px;
  background: #f1f1f4;
  border-radius: 6px;
  padding: 10px;
  color: #fff;
  cursor: pointer;
  font-size: 11px;
  position: relative;
}

.msgfilesNew a .filesNameView{
  display: block;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: #333;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}


.msgfilesNew a .fileSizeView{
  color: rgb(138, 141, 145);
  font-size: 11px;
  text-align: left;
  display: block;
}

.msgfilesNew a .whichFile{
  color: rgb(138, 141, 145);
  display: block;
  text-align: left;
  position: relative;
  margin: 20px 0px 10px 0px;
}



.msgfilesNew a .whichFile::before{
  content: url('../img/icon/blank-file.png');
  margin-right: 10px;
  display: inline-block;
}

.msgfilesNew a .fileDownload{
  display: block;
  color: #333;
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  position: absolute;
  bottom: 0;
  left: 0;  
  right: 0;
  padding: 7px 0px;
  border-top: 1px solid #b4b4b4;
  margin: 0px 10px;
}

.msgfilesNew a .whichFile .whichFileName{
  font-size: 12px;
  position: relative;
  top: -7px;
}


.msgimageNew {
  max-width: 150px;
  max-height: 140px;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: 1px solid rgb(228, 228, 228);
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  background: transparent;
  justify-content: center;
  padding: 5px;
  margin-left: auto;
}

.msgimageNew img {
  width: 100%;
  height: 80px;
  object-fit: contain;
}

.videoFrame{
  width: 150px !important;
  height: 120px !important;
  cursor: pointer;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: left;
  margin-right: auto;
  margin-left: inherit;
}

 .message-right .videoFrame{
  width: 150px !important;
  height: 120px !important;
  cursor: pointer;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: right;
  margin-left: auto;
  margin-right: inherit;
} 
.videoFrame.twitter-frame {
  height: auto !important;
  background: transparent;
}



.videoFrame > video:focus{
  outline: 0;
}

.videoFrameNew {
/*  width: auto !important;
  height: auto !important;*/
  width: 200px;
  height: 200px;
  background: #000000;
  border-radius: 6px;

}

.videoFrameNew > video {
  display: inline-block;
  width: 200px;
  height: 200px;
}


/* Added by ANuj Gupta Start */
.msgimageVisitor {
  max-width: 150px;
  max-height: 140px;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  border: 1px solid rgb(228, 228, 228);
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  display: flex;
  align-items: center;
  background: transparent;
  justify-content: center;
  padding: 5px;
}

.msgimageVisitor img{
  width: 100%;
  height: 80px;
  object-fit: contain;
}

/* Added by ANuj Gupta End */

.message.message-right div.mt-0{
  text-align: right;
}

.ongoingSection, .incomingSection{
  display: inline-block;
  width: 100%;
}

/*-------end file attched design css-------*/

.startChatNew, .end-chat-section{
  background: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding: 7px;
  text-align: center;
  align-items: center;
  justify-content: center;
  box-shadow: 0px -1px 6px rgba(128, 128, 128, 0.09);
}

.typingDivAni {
  font-size: 12px;
  /*position: absolute;*/
  bottom: 62px;
  margin-top: 7px;
}
#message-input .typingDivAni {
  max-width: 122px;
  // position: absolute;
  // bottom: 68px;
}
#message-input .typingDivAni.typing-div {
  position: absolute;
  top: 0;
  margin-top: 3px;
}
#message-input .typingDivAni .dropdown.historey-serch-drp .dropdown-toggle {
  display: block;
}

.typingDivAni img{
  width: 20px;
}

/*------- start emoji css ------------*/

.newemojis{
  // bottom: 80px;
  bottom: 145px;;
  left: 0;
}

.newemojis .emoji-mart{
  width: 100% !important;
}

.newemojis .emoji-mart-scroll {
  height: 150px !important;
}

.newemojis .emoji-mart-preview {
  height: 30px !important;
}

.newemojis .emoji-mart-emoji > span{
  width: 20px !important;
  height: 20px !important;
}

.newemojis .emoji-mart-preview-data {
  left: 48px !important;
  height: 12px !important;
  overflow: hidden !important;
}

.newemojis .emoji-mart-title-label {
  font-size: 14px !important;
}

.newemojis .emoji-mart-preview-name {
  font-size: 11px !important;
}

.newemojis .emoji-mart-preview-shortname {
  font-size: 10px !important;
}

/*------- end emoji css ------------*/

/* .chat-footer .input-group #txtSms.textAreaChat{
  background: #ececec !important;
  border-radius: 20px;
  margin: 0px;
  font-size: 15px !important;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
} */

.chat-footer .input-group #msg2.textAreaChat, #txtSms.textAreaChat, #txtWhatsapp.textAreaChat, #txtMessanger.textAreaChat, #txtTwitterDM.textAreaChat{
  background: #fff !important;  //#ececec !important;
  border-radius: 20px;
  margin: 0px;
  font-size: 15px !important;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border: 1px solid #ccc !important;
  color: #000;
}
// added design for div area instead of textArea for msg chat to use Smart Compose feature
.chat-footer .input-group #msg.textAreaChat2{
  background: #fff !important;  //#ececec !important;
  border-radius: 20px;
  margin: 0px;
  font-size: 15px !important;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  border: 1px solid #ccc !important;
  color: #000;
  overflow: auto !important; 
  // overflow-wrap: break-word;
  word-break: break-word;  
  // -webkit-word-break: break-word;
  overflow-wrap: break-word;
  height: auto;
  max-height: 300px !important;
}

button#emojiopen, button#emojiclose{
  background-color: transparent !important;
}
button#emojiopen:focus, button#emojiopen:active, button#emojiclose:focus, button#emojiclose:active{
  background-color: transparent !important;
  font-size: 15px !important;
  outline: 0 !important;
}

button#chat-upload-btn-1{
  background-color: transparent !important;
}

button#chat-upload-btn-1:focus, button#chat-upload-btn-1:active{
  background-color: transparent !important;
  font-size: 15px !important;
  outline: 0 !important;
}

.input-group-append button.btn-cus{
  background-color: transparent !important;
}

.input-group-append button.btn-cus:hover{
  background-color: transparent !important;
}
.input-group-append button.btn-cus:focus{
  background-color: transparent !important;
}
.input-group-append button.btn-cus:active{
  background-color: transparent !important;
}

.date-agent{
  text-align: center;
  font-size: 11px;
}

.newconfirmParaSection{
  width: 100%;
}

.newendChatBtn{
  background: #4092FB;
  border: 1px solid #4092FB;
  padding: 5px 20px;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
}


.newcancelEndChatBtn{
  background: #ffffff;
  border: 1px solid #000000;
  padding: 5px 20px;
  border-radius: 4px; 
  font-size: 14px;
  color: #000;
}

.newendChatBtn:hover{
  background: #2c3049;
  border: 1px solid #2c3049;
  padding: 5px 20px;
  border-radius: 4px;  
  font-size: 14px;
  color: #fff;      
}

.newcancelEndChatBtn:hover{
  background: #2c3049;
  border: 1px solid #2c3049;
  padding: 5px 20px;
  border-radius: 4px;
  font-size: 14px;
  color: #fff;
}

/* Added By AG Start */

#stickynotes.chat-sidebar{
  visibility: visible !important;
  max-width: 100%;
  min-width: 370px;
  width: 370px;
} 

#visitorpaths.chat-sidebar{
  visibility: visible !important;
  max-width: 100%;
  min-width: 370px;
  width: 370px;
}

#add_stickynotes.chat-sidebar{
  visibility: visible !important;
  max-width: 100%;
  min-width: 370px;
  width: 370px;
}

#transferagent.chat-sidebar{
  visibility: visible !important;
  max-width: 100%;
  min-width: 370px;
  width: 370px;
} 

#chat1-members.chat-sidebar{
  visibility: visible !important;
  max-width: 100%;
  min-width: 370px;
  width: 370px;
}
.plan-upgrade #chat1-members.chat-sidebar, 
.plan-upgrade #chat-visitore-nav.chat-sidebar,
.plan-upgrade #filterByList.chat-sidebar,
.plan-upgrade #filterByOptions.chat-sidebar {
  z-index: 99;
}

#addagentdiv.chat-sidebar{
  visibility: visible !important;
  max-width: 100%;
  min-width: 370px;
  width: 370px;
}

#leavechatwindow.chat-sidebar{
  visibility: visible !important;
  max-width: 100%;
  min-width: 370px;
  width: 370px;
} 

#filterByOptions.chat-sidebar{
  visibility: visible !important;
  max-width: 100%;
  min-width: 370px;
  width: 370px;
} 

#filterByList.chat-sidebar{
  visibility: visible !important;
  max-width: 100%;
  min-width: 370px;
  width: 370px;
} 

#orderByOptionsList.chat-sidebar{
  visibility: visible !important;
  max-width: 100%;
  min-width: 370px;
  width: 370px;
}

#onlineagents.chat-sidebar{
  visibility: visible !important;
  max-width: 100%;
  min-width: 370px;
  width: 370px;
  z-index: 9999;
}

#calldialer.chat-sidebar{
  visibility: visible !important;
  max-width: 100%;
  min-width: 370px;
  width: 370px;
  z-index: 9999;
}

#chatHistorySee.chat-sidebar{
  visibility: visible !important;
  max-width: 370px;
  min-width: 40%;
  width: 100%;
  position: absolute;
  z-index: 99;
  right: 0;
  top: 0;
  left: auto;
  display: none;
}

#visitarPathNews.chat-sidebar{
  visibility: visible !important;
  max-width: 370px;
  min-width: 40%;
  width: 100%;
  position: absolute;
  z-index: 99;
  right: 0;
  top: 0;
  left: auto;
}

.collapse {
  display: none !important;
}

.collapse.in {
  display: block !important;
}

#smsform.chat-sidebar{
  visibility: visible !important;
  max-width: 100%;
  min-width: 370px;
  width: 370px;
}

#whatsapptemplates.chat-sidebar{
  visibility: visible !important;
  max-width: 100%;
  min-width: 370px;
  width: 370px;
}

#ticketing.chat-sidebar{
  visibility: visible !important;
  max-width: 100%;
  min-width: 370px;
  width: 370px;
}

/* Added By AG End */

.addagentdivCss{
  left: 0;
  min-width: 100% !important;
  width: 100% !important;
  display: block !important;
}


.VRP #chat1-members.chat-sidebar{  
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  left: 0;
  z-index: 9;
  display: block !important;
}

.VRP2 #onlineagents.chat-sidebar{  
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  left: 0;
  z-index: 9;
  display: block !important;
}

.VRP3 #calldialer.chat-sidebar{  
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  left: 0;
  z-index: 9;
  display: block !important;
}

#endchatwindow.chat-sidebar,
#leavechatwindow.chat-sidebar,
#visitorpaths.chat-sidebar,
#stickynotes.chat-sidebar,
#add_stickynotes.chat-sidebar,
#addagentdiv.chat-sidebar,
#transferagent.chat-sidebar,
#chat1-members.chat-sidebar,
#filterByOptions.chat-sidebar,
#chatHistorySee.chat-sidebar,
#whatsapptemplates.chat-sidebar,
#ticketing.chat-sidebar,
#filterByList.chat-sidebar {left: 0 ;}


.visShowNew #chat1-members.chat-sidebar, 
.ConfirmShowNew #endchatwindow.chat-sidebar, 
.NoteShowNew #stickynotes.chat-sidebar, 
.addAgentShowNew #addagentdiv.chat-sidebar, 
.TransferShowNew #transferagent.chat-sidebar, 
.translateShowNew #chat1-members.chat-sidebar, 
.visEmailTraShowNew #chat1-members.chat-sidebar, 
.visPathShowNew #visitorpaths.chat-sidebar{  
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  left: 0;
  z-index: 9;
  display: block !important;
  position: absolute;
  z-index: 99;
}
/*--------------------09-may-2020 start css-------------------*/

.mobile-display{
  display: none;
}

.user-select-check{
  text-align: center;
  line-height: 42px;
}

.notes-bage{
  background: #2b3049;
  border-radius: 4px;
}

.notes-footer p{
  color: #a5a5a5;
  font-family: 'HelveticaNeueBold';
}

.notes-message p{
  font-family: 'HelveticaNeueLight';
}

.chat-edit-email{
  font-size: 12px;
  padding: 0px 6px;
  height: 20px;
  width: 100px;
  font-family: 'HelveticaNeueLight';
  display: inherit;
}

.chat-edit-email:focus{
  font-size: 12px;
  font-family: 'HelveticaNeueLight';
}

.header-path{
  font-family: 'HelveticaNeueLight';
  color:#000;
}

.header-path-small{
  width: 250px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.chat-edit-name{
  font-size: 15px;
  padding: 0px 6px;
  height: 24px;
  width: 100px;
}

.end-chat-shadow{
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 132px;
  width: calc(100% - 45px);
  height: calc(100% - 215px);
  background: rgba(230, 229, 229, 0.59);
  z-index: 2;
}

.upDownNotify{
  padding-left: 0;
  position: absolute;
  right: 10px;
  bottom: 100px;
  width: 40px;
  height: 40px;
  background: #e0e0e0;
  border-radius: 100%;
  text-align: center;
  line-height: 40px;
  border: 1px solid #cacaca;
  cursor: pointer;
}

.on-in-chats{
  position: absolute;
  top:-3px;
}

.on-in-chats .input-group-append button:hover{
  background-color: #fff !important;
}





.newvisitPathListing ul {
  display: inline-block;
  width: 100%;
  margin: 0;
}


.newvisitPathListing ul li{
  list-style: none;
  font-size: 14px;
  color: #959595;
  padding: 2px 0px;
  position: relative;
  padding-left: 20px;
  word-break: break-all;
}


.newvisitPathListing ul li::before{
  content: '';
  width: 10px;
  height: 10px;
  border: 1px solid #989898;
  display: inline-block;
  left: 0;
  top: 7px;
  position: absolute;
  border-radius: 100%;
}

.dropdown-menu {
  box-shadow: 0 0 1.75rem rgba(0, 0, 0, 0.16);
  padding: .625rem;
}
.newPreVisHeight{
  height: calc(100vh - 318px);
}

.logout-class{
display:none;
 position: absolute;
    left: 60px;
    bottom: 10px;
    background: #ffffff;
    width: 220px;
    border-radius: 6px;
    font-size: 12px;
    z-index: 1000;
}

.logout-class::before{
  content: '';
  position: absolute;
  left: -20px;
  bottom: 8px;
  border: 10px;
  border-style: solid;
  border-color: transparent #ffffff transparent transparent;
}

.logout-class ul li{
list-style:none;

}

.logout-class ul li span{
  width: 100%;
  display: inline-block;
  text-align: left;
  background: transparent;
  position: relative !important;
  height: auto !important;
  overflow: visible;
  line-height: 15px;
  color: #333; 
  padding: 10px 10px !important;
  border-bottom: 1px solid rgba(51, 51, 51, 0.17);
}

.logout-class ul li:last-child span{
  border-bottom: 0px solid rgba(51, 51, 51, 0.17);
}


.newaddToAgentListName p span.onlineAgentCount{
  position: absolute;
  right: 0px;
}

.srvedUserShow{
  position: relative;
}

.newPremiumHeight{
  // max-height: calc(50vh - 125px);
  height: calc(50vh - 125px);
}

.newNormalVissHeight{
  // height: calc(100vh - 191px);
  height: calc(100vh - 211px);
}


.dataManagement-section .border-bottom{
  border-bottom: 1px solid #dee2e6 !important;
}


.dataManagement-section .border-bottom:last-child{
  border-bottom: 1px solid transparent !important;
}

.revoke-profile-text{
  font-family: 'HelveticaNeueLight';
  font-weight: bold;
}

.hoverDoenalod{
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.77);
  padding: 10px;
  border-radius: 6px;
  display: none;
  z-index: 1;
}

.msg-img-hover:hover .hoverDoenalod{
  display: block;
}

.hoverDoenalod .filesNameView{
  display: block;
  text-align: left;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.hoverDoenalod .fileSizeView{
  color: #d4d4d4;
  font-size: 10px;
  text-align: left;
  display: block;
}

.hoverDoenalod .whichFile{
  color: #8a8d91;
  display: block;
  text-align: left;
  position: relative;
  margin: 10px 0px 0px 0px;
}

.hoverDoenalod .whichFile .doenlaod-img-icon{
  background: #fff;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  position: relative;
  z-index: 99;
  cursor: pointer;
}

.whtsapptemplates {
  background-color: #3e4460;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 5px;
}

.whtsapptemplates h5 { 
  color : #fff;
  font-size: 14px;
  text-transform: uppercase; 
}

.newconfirmBtbn li{
  list-style: none;
}
.MuiAutocomplete-listbox {
  max-height: 30vh !important;
}

.message-content.joinZoom .message-content-zoom {
  font-size: 9px;
  margin-bottom: 5px;
}
  
.message-content.joinZoom .message-content-new {
  padding: 8px 15px;
  border-radius: 8px;
  border-bottom-right-radius: 0;
}
.message-content.joinZoom .message-content-new a {text-decoration: none;}
.message-content.joinZoom .message-content-new a img {
  width: 25px;
}
.bg-triggers { background-color: #e38511 !important; }

.chatOffHeadingReviewTitle{
  border-bottom: 1px solid #dee2e6 !important;
} 

.handOffFeilds{
  padding: 20px;
  background-color: #F1F6F8;
  margin-bottom: 20px;
  width: 80%;
}

.handOffFeilds .nameEnterHandsOfFeilds input[type="text"]{
  background: #fff;
  color: #636363;
  font-size: 14px;
  width: 200px;
  border: 1px solid #E8E8E8;
}

.handOffFeilds .insertHandofink input[type="text"]{
  background: #fff;
  color: #636363;
  font-size: 14px;
  width: 100%;
  border: 1px solid #BABABA;
border-radius: 5px;
}

.visitorClose {
  position: absolute;
  right: -3px;
  transform: translateY(48%);
  cursor: pointer;
  z-index: 9999;
  display: none;
}


.visitorClose.visitorCloseOngoing {
  transform: translateY(48%);
  right: 7px;
}



.card.active, .card:hover .visitorClose{
  display: block;
}

.avatar.avatar-away::before {
    border: 1px solid #fdd770 !important;
    background: #fdd770 !important;
}

.disabled {
  pointer-events:none; //This makes it not clickable
  opacity:0.6;         //This grays it out to look disabled
}